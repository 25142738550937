import store from "@/store";
import Cookies from "js-cookie";
// 未登录可以访问的页面
const whiteList = [
  "/",
  "/login",
  "/register",
  "/login/mobile",
  "/loginSecurity",
];
const notLoginList = [
  "/login",
  "/ucenter/assets",
  "/ucenter/security-setting",
  "/ucenter/situation",
  "/ucenter/finanManagement",
  "/ucenter/spread-center",
  "/ucenter/coupon-center",
  "/ucenter/safe-setting",
];
const countryList = [
  "/en",
  "/ja",
  "/ko",
  "/fr",
  "/es",
  "/ru",
  "/de",
  "/pt",
  "/hi",
  "/vi",
  "/tl",
  "/th",
  "/tr",
  "/it",
  "/id",
  "/ar",
  "/zh_CN",
  "/zh_HK",
];
function extractContent(url) {
  const secondSlashIndex = url.indexOf("/", url.indexOf("/") + 1);
  if (secondSlashIndex !== -1) {
    return url.substring(0, secondSlashIndex);
  } else {
    return url;
  }
}
export default function (router) {
  router.beforeEach((to, from, next) => {
    const currentPath = extractContent(window.location.pathname);
    // console.log(router.options.routes);
    const lang = to.params.lang;
    // console.log("to", to);
    // console.log("lang", lang);
    // console.log("currentPath", currentPath);
    const isInPaths = router.options.routes.some(
      item =>
        item.path == currentPath ||
        item.path == `${currentPath}/:lang?` ||
        countryList.includes(currentPath)
    );
    // console.log("to.path", to.path);
    // console.log("isInPaths", isInPaths);
    if (!isInPaths) {
      // if (to.meta.content) {
      let head = document.getElementsByTagName("head");
      let meta = document.createElement("meta");
      meta.content = "404";
      meta.name = "prerender-status-code";
      head[0].appendChild(meta);
      // }
    } else {
      // console.log("remove");
      const metaTags = document.getElementsByTagName("meta");

      // 遍历 meta 标签
      for (let i = 0; i < metaTags.length; i++) {
        const metaTag = metaTags[i];

        // 检查 meta 标签的属性和值是否匹配
        if (
          metaTag.getAttribute("name") === "prerender-status-code" &&
          metaTag.getAttribute("content") === "404"
        ) {
          // 删除匹配的 meta 标签
          metaTag.parentNode.removeChild(metaTag);
          break; // 如果只想删除第一个匹配的标签，可以使用 break 结束循环
        }
      }
    }
    // 白名单不做登录验证

    if (whiteList.includes(to.path)) {
      next();
    } else {
      // 校验是否有token
      let temmToken = Cookies.get("topcredit_exchange_t");
      if (temmToken) {
        store.dispatch("user/getUserInfo");
      } else {
        if (notLoginList.includes(to.path)) {
          next({
            path: "/login",
          });
        } else {
          next();
          // console.log(1);
          // console.log(to);
          // const lang = to.params.lang;
          // console.log(lang, "lang");
          // next("/");
          // if (!countryList.includes(lang)) {
          //   next({ path: "/" });
          // } else {
          //   next();
          // }
        }
      }
      next();
    }
  });
}
