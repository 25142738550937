<template>
  <el-dialog
    :visible.sync="v8show"
    :before-close="handleClose"
    :show-close="true"
    :lock-scroll="true"
    :close-on-click-modal="true"
    :modal-append-to-body="true"
    custom-class="v8kyc"
    center
    width="480px"
  >
    <p class="t">{{ translateTitle("请于APP上完成KYC认证") }}</p>
    <p class="c">
      {{
        translateTitle(
          "你当前帐户已达到相对应认证要求，请完成对应认证后继续进行业务操作。"
        )
      }}
    </p>
    <div class="er">
      <span>
        <img
          src="../../assets/img/bv/header-icon/android-two-code.png"
          class="apk"
        />
      </span>
      <span>
        <img src="../../assets/img/bv/header-icon/ios-two-code.png" class="apk"
      /></span>
    </div>
    <div class="d">
      <div class="b">
        <a :href="android_url_new" target="_blank">
          <img
            src="../../assets/img/bv/header-icon/andriod-kyc.png"
            class="apk"
          />
        </a>
        <a :href="appstore_url_new" target="_blank">
          <!-- <img src="../../assets/img/bv/header-icon/as-day.png" class="as" /> -->
          <img src="../../assets/img/bv/header-icon/ios-kyc.png" class="as" />
        </a>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import commonMixin from "@/components/common/commonMixin.vue";
import menuControlMixin from "@/components/common/menuControlMixin.vue";
import routeSplicing from "@/assets/js/routeSplicing";
import store from "../../store/index";
export default {
  mixins: [commonMixin, menuControlMixin],
  components: {
    // QrcodeVue,
  },
  data() {
    return {
      store,
      android_url_new: "https://download.8v.com",
      appstore_url_new: "https://get.8v.com",
    };
  },
  created() {},
  mounted() {},

  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      v8show: "user/v8show",
      language: "user/language",
      environment: "user/environment",
      darkMode: "user/darkMode",
    }),
  },
  methods: {
    routeSplicing,
    ...mapActions({
      changeLanguage: "user/changeLanguage",
      changeCurrency: "user/changeCurrency",
      changeDarkMode: "user/changeDarkMode",
    }),
    handleClose(done) {
      store.dispatch("user/v8closed");
    },
  },
};
</script>
<style lang="scss">
.v8kyc {
  .t {
    font-size: 24px;
    font-weight: 600;
    margin: 0 auto;
    color: #182c3c;
    text-align: center;
  }
  .c {
    font-size: 16px;
    font-weight: 300;
    color: #7f7f7f;
    width: 390px;
    margin: 15px auto;
  }
  .er {
    display: flex;
    justify-content: space-between;
    width: 390px;
    margin: 0 auto;
    span {
      width: 168px;
      //   border: solid 1px;
    }
  }
  .d {
    width: 390px;
    margin: 0 auto;
    .b {
      display: flex;
      justify-content: space-between;
      img {
        width: 170px;
      }
    }
  }
}
</style>
