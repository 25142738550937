<template>
  <div class="aboutus-8v-page">
    <div class="page-banner">
      <img
        src="@/assets/img/aboutus-8v/banner_bg.png"
        class="bgvideo"
        alt="aboutus banner 8v.com"
      />
      <div class="logo-slogan">
        <img
          src="@/assets/img/aboutus-8v/logo_aboutus.png"
          class="lg"
          alt="aboutus 8v.com"
        />
        <h1 class="mt">
          {{
            language == "zh_CN" || language == "zh_HK"
              ? translateTitle("全球首家元宇宙生态数字资产交易所")
              : "Your Gateway To The Metaverse"
          }}
        </h1>
        <h2 class="st">{{ translateTitle("The Gateway to Future") }}</h2>
        <div class="rectangle">
          <div class="more-button" @click="doShowIntroduction">
            <span>{{ translateTitle("more") }}</span
            ><i
              v-show="!isShowIntroduction"
              class="iconfont iconsize16-1 down"
            ></i
            ><i
              v-show="isShowIntroduction"
              class="iconfont iconsize16-1 up"
            ></i>
          </div>
        </div>
      </div>
    </div>
    <div class="rectangle-content" v-show="isShowIntroduction">
      <p class="introduction" v-if="language != 'zh_CN' && language != 'zh_HK'">
        <span class="site-name">{{ "8V.com" }}</span
        >{{
          translateTitle(
            "是全球领先的区块链技术服务商，成⽴于2021年，总部位于英国。我们致⼒于⾼效搭建沉浸感、社交性和实时性的元宇宙⽣态模块，已拥有数字资产交易系统、钱包等成熟的产品线。"
          )
        }}
        <br />
        <br />
        {{
          translateTitle(
            "团队成员均来⾃国际顶级⾦融机构、计算机应⽤等领域，汇聚亚洲，欧洲和美洲的⾦融专业⼈才和区块链技术专业⼈才。"
          )
        }}
        <span class="site-name" style="display: inline">{{ "8V.com" }}</span
        >{{
          translateTitle(
            "致力打造世界第一个围绕着元宇宙生态圈的数字资产交易所，并将以节能减碳为目标，助力虚拟货币对环境的永续发展。"
          )
        }}
      </p>
      <p class="introduction" v-if="language == 'zh_CN'">
        {{
          translateTitle(
            "8V交易所成立于2021年，是全球领先的区块链技术供应商，致力于为全球用户提供专业、智能、便捷以及创新的线上交易服務。8V平台拥有数字资产交易系统及钱包等成熟产品线，构建了技术服务-流量-商业化的产业循环。 "
          )
        }}
        <br />
        {{
          translateTitle(
            "8V交易所由一群在交易所、媒体、区块链方面具有丰富经验的资深团队所组成，秉持着“以用户为中心”的理念，致力于为全球200多个国家的用户提供可靠安全、高隐私、便捷、高效的金融信息和全面性的虚拟金融交易服务。"
          )
        }}
      </p>
      <p class="introduction" v-if="language == 'zh_HK'">
        {{
          translateTitle(
            "8V交易所成立於2021年，是全球領先的區塊鏈技術供應商，緻力於爲全球用戶提供專業、智能、便捷以及創新的線上交易服務。8V平颱擁有數字資産交易繫統及錢包等成熟産品線，構建了技術服務-流量-商業化的産業循環。 "
          )
        }}
        <br />
        {{
          translateTitle(
            "8V交易所由一群在交易所、媒體、區塊鏈方麵具有豐富經驗的資深團隊所組成，秉持着“以用戶爲中心”的理唸，緻力於爲全球200多個國家的用戶提供可靠安全、高隱私、便捷、高效的金融信息和全麵性的虛擬金融交易服務。"
          )
        }}
      </p>
    </div>

    <div class="ecosystem">
      <span class="title-ecosystem">{{ translateTitle("元宇宙生态圈") }}</span>
      <div class="img-ecosystem">
        <div class="tc" :style="{ display: eco_active == 1 ? '' : 'none' }">
          <span class="t">{{ translateTitle("数字货币") }}</span>
          <p class="c">
            {{ translateTitle("元宇宙经济基础，支持所有链上元宇宙相关币种") }}
          </p>
        </div>
        <div class="tc" :style="{ display: eco_active == 2 ? '' : 'none' }">
          <span class="t">{{ translateTitle("DEFI") }}</span>
          <p class="c">
            {{ translateTitle("支持区块链Defi架构应用，倍增理财投资广度") }}
          </p>
        </div>
        <div class="tc" :style="{ display: eco_active == 3 ? '' : 'none' }">
          <span class="t">{{ translateTitle("投资") }}</span>
          <p class="c">
            {{ translateTitle("支持元宇宙生态项目投资，致力打造友善生态") }}
          </p>
        </div>
        <div class="tc" :style="{ display: eco_active == 4 ? '' : 'none' }">
          <span class="t">{{ translateTitle("社交") }}</span>
          <p class="c">
            {{ translateTitle("逐步连结元宇宙社群，创造元宇宙社交投资") }}
          </p>
        </div>
        <div class="tc" :style="{ display: eco_active == 5 ? '' : 'none' }">
          <span class="t">{{ translateTitle("GameFi") }}</span>
          <p class="c">
            {{ translateTitle("元宇宙生态应用重点，全面支持游戏经济") }}
          </p>
        </div>
        <div class="tc" :style="{ display: eco_active == 6 ? '' : 'none' }">
          <span class="t">{{ translateTitle("NFT") }}</span>
          <p class="c">
            {{ translateTitle("元宇宙打造元件，未来支持NFT交易市场") }}
          </p>
        </div>

        <div
          class="first"
          :class="eco_active == 1 ? 'active' : ''"
          @mouseenter="chooseEco(1)"
        >
          <span class="text">{{ translateTitle("数字货币") }}</span>
        </div>
        <div
          class="second"
          :class="eco_active == 2 ? 'active' : ''"
          @mouseenter="chooseEco(2)"
        >
          <span class="text">{{ translateTitle("DEFI") }}</span>
        </div>
        <div
          class="third"
          :class="eco_active == 3 ? 'active' : ''"
          @mouseenter="chooseEco(3)"
        >
          <span class="text">{{ translateTitle("投资") }}</span>
        </div>
        <div
          class="fourth"
          :class="eco_active == 4 ? 'active' : ''"
          @mouseenter="chooseEco(4)"
        >
          <span class="text">{{ translateTitle("社交") }}</span>
        </div>
        <div
          class="fifth"
          :class="eco_active == 5 ? 'active' : ''"
          @mouseenter="chooseEco(5)"
        >
          <span class="text">{{ translateTitle("GameFi") }}</span>
        </div>
        <div
          class="sixth"
          :class="eco_active == 6 ? 'active' : ''"
          @mouseenter="chooseEco(6)"
        >
          <span class="text">{{ translateTitle("NFT") }}</span>
        </div>
      </div>
    </div>

    <div class="site-data">
      <div class="data-content">
        <div class="data-item">
          <span class="data">200x</span>
          <span class="title">{{ translateTitle("杠杆") }}</span>
        </div>
        <div class="data-item">
          <span class="data">500M+</span>
          <span class="title">{{ translateTitle("交易/每秒") }}</span>
        </div>
        <div class="data-item">
          <span class="data">24/7</span>
          <span class="title">{{ translateTitle("帮助中心") }}</span>
        </div>
      </div>
    </div>

    <div class="advantages">
      <div class="title-cn-english">
        <span class="title-cn">{{ translateTitle("我们的优势") }}</span>
        <span class="title-cn-sub">{{ "Our Advantages" }}</span>
      </div>
      <div class="advantages-items">
        <div class="advantages-item-both">
          <div class="advantages-item-one">
            <img class="advantages-item-img" :src="advantages1" />
            <!-- <p class="advantages-item-p" style="width:364px;"> -->
            <p class="advantages-item-p" style="max-width: 364px">
              {{ translateTitle("全球首家元宇宙生态交易所") }}
            </p>
          </div>
          <div class="advantages-item-two">
            <img class="advantages-item-img" :src="advantages4" />
            <!-- <p class="advantages-item-p" style="width:252px;"> -->
            <p class="advantages-item-p" style="max-width: 364px">
              {{ translateTitle("交易深度最高，稳定防插针") }}
            </p>
          </div>
        </div>

        <div class="space"></div>

        <div class="advantages-item-both">
          <div class="advantages-item-one">
            <img class="advantages-item-img" :src="advantages2" />
            <!-- <p class="advantages-item-p" style="width:280px;"> -->
            <p class="advantages-item-p" style="max-width: 364px">
              {{ translateTitle("拥有最全的元宇宙概念币种") }}
            </p>
          </div>
          <div class="advantages-item-two">
            <img class="advantages-item-img" :src="advantages5" />
            <!-- <p class="advantages-item-p" style="width:280px;"> -->
            <p class="advantages-item-p" style="max-width: 364px">
              {{ translateTitle("先进的混币技术，链上匿名") }}
            </p>
          </div>
        </div>

        <div class="space"></div>
        <div class="advantages-item-both">
          <div class="advantages-item-one">
            <img
              class="advantages-item-img"
              :src="advantages3"
              alt="aboutus 8v.com"
            />
            <!-- <p class="advantages-item-p" style="width:370px;"> -->
            <p class="advantages-item-p" style="max-width: 364px">
              {{ translateTitle("套利理财产品收益全网最高") }}
            </p>
          </div>
          <div class="advantages-item-two">
            <img
              class="advantages-item-img"
              :src="advantages6"
              alt="aboutus 8v.com"
            />
            <!-- <p class="advantages-item-p" style="width:280px;"> -->
            <p class="advantages-item-p" style="max-width: 364px">
              {{ translateTitle("冷热钱包双机制，资产安全") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="team">
      <div class="team-title">{{ translateTitle("我们的团队") }}</div>
      <div class="team-title-sub">{{ "Our Team" }}</div>
      <div class="team-items">
        <div class="ti-itme-out">
          <div class="ti-item tii-1">
            <!-- <div class="item-img"> -->
            <div class="item-more ">
              <div
                class="more"
                @click="
                  () => {
                    currItem = items[1];
                    dialogVisible = true;
                  }
                "
              >
                {{ translateTitle("more") }}
              </div>
            </div>
            <!-- </div> -->
          </div>
          <span class="item-name">Matt</span>
          <span class="item-job">Chief Architect</span>
        </div>
        <div class="ti-itme-out">
          <div class="ti-item tii-2">
            <div class="item-more ">
              <div
                class="more"
                @click="
                  () => {
                    currItem = items[2];
                    dialogVisible = true;
                  }
                "
              >
                {{ translateTitle("MORE") }}
              </div>
            </div>
          </div>
          <span class="item-name">Jasper</span>
          <span class="item-job">Team Advocate</span>
        </div>
        <div class="ti-itme-out">
          <div class="ti-item tii-3">
            <div class="item-more ">
              <div
                class="more"
                @click="
                  () => {
                    currItem = items[3];
                    dialogVisible = true;
                  }
                "
              >
                {{ translateTitle("MORE") }}
              </div>
            </div>
          </div>
          <span class="item-name">Jack</span>
          <span class="item-job">Platform Alchemist</span>
        </div>
        <div class="ti-itme-out">
          <div class="ti-item tii-4">
            <div class="item-more ">
              <div
                class="more"
                @click="
                  () => {
                    currItem = items[4];
                    dialogVisible = true;
                  }
                "
              >
                {{ translateTitle("MORE") }}
              </div>
            </div>
          </div>
          <span class="item-name">Ann</span>
          <span class="item-job">Marketing Wiz</span>
        </div>
        <div class="ti-itme-out">
          <div class="ti-item tii-5">
            <div class="item-more ">
              <div
                class="more"
                @click="
                  () => {
                    currItem = items[5];
                    dialogVisible = true;
                  }
                "
              >
                {{ translateTitle("MORE") }}
              </div>
            </div>
          </div>
          <span class="item-name">David</span>
          <span class="item-job">OPS Ninja</span>
        </div>
      </div>
    </div>

    <div class="vision">
      <div class="vision-content">
        <span class="title-small">{{ translateTitle("品牌愿景") }}</span>
        <span class="title-big">{{ translateTitle("建立元宇宙生态") }}</span>
        <p class="slogan-one" style="width: 85%">
          {{
            translateTitle(
              `我们致力串流国际性高端人才和资源，专注于顶尖设计、互联互通开发技术，提供最齐全的元宇宙币种、最低交易手续费以及最高交易深度，助力打造引领全球实现真正的元宇宙虚拟世界生态系统。`
            )
          }}
        </p>
        <div class="more-button" @click="doShowSlogan">
          <span>{{ translateTitle("more") }}</span
          ><i v-show="!isShowSlogan" class="iconfont iconsize16-1 down"></i
          ><i v-show="isShowSlogan" class="iconfont iconsize16-1 up"></i>
        </div>
        <p class="slogan-two" style="width: 95%" v-if="isShowSlogan">
          {{
            translateTitle(
              "我们也将同时帮助用户打造方便的理财管理，推出On-Demand/定期借贷等贷款服务，以及协助您资产增值的服务，像是投资教学或与投资界网红和KOL互动等。 8V.com用户在未来将可建立自己的元宇宙社交网络，同时可畅游多种“增加财富”的智能游戏，进一步提升资产。"
            )
          }}
        </p>
        <p class="slogan-three" v-if="isShowSlogan">
          {{ translateTitle("持续引领加密货币交易牛市!") }}
        </p>
      </div>
    </div>

    <div class="centric">
      <div class="centric-content">
        <span class="title-small">{{ translateTitle("企业使命") }}</span>
        <span class="title-big">{{ translateTitle("用户为中心") }}</span>
        <p class="slogan-one" style="width: 63%">
          {{
            translateTitle(
              `8V.com将以“用户为中心”的理念，致力于为全球散户和机构客户提供专业、智能、便捷和创新的在线交易，并结合游戏与社交让打造最优质的虚拟娱乐世界。`
            )
          }}
        </p>
        <div class="more-button" @click="doShowCentric">
          <span>{{ translateTitle("more") }}</span
          ><i v-show="!isShowCentric" class="iconfont iconsize16-1 down"></i
          ><i v-show="isShowCentric" class="iconfont iconsize16-1 up"></i>
        </div>
        <p class="slogan-two" style="width: 95%" v-if="isShowCentric">
          {{
            translateTitle(
              "我们坚持安全、高效、公平和透明的交易环境，同时注入绿色概念，以环保创造永续发展为核心，每个交易捐献1美分给慈善机构植树，聚焦环境保护，擘造高优质的「绿色金融」为地球尽一份心力。"
            )
          }}
        </p>
      </div>
    </div>

    <div class="customer">
      <a href="mailto:support@8vex.com" class="customer-item">
        <img :src="bottom1n" class="item-image" alt="aboutus 8v.com" />
        <span class="work-title" v-if="language == 'en'">{{ "SERVICE" }}</span>
        <span class="work-title" v-else>{{ translateTitle("客服") }}</span>
        <a href="mailto:support@8vex.com" class="work-subTitle">{{
          translateTitle("support@8vex.com")
        }}</a>
      </a>
      <div class="space"></div>
      <a href="mailto:business@8V.com" class="customer-item">
        <img :src="bottom2n" class="item-image" alt="aboutus 8v.com" />
        <span class="work-title">{{
          translateTitle("商务").toUpperCase()
        }}</span>
        <a href="mailto:business@8V.com" class="work-subTitle">{{
          translateTitle("business@8V.com")
        }}</a>
      </a>
      <div class="space"></div>
      <a href="mailto:marketing@8v.com" class="customer-item">
        <img :src="bottom3n" class="item-image" alt="aboutus 8v.com" />
        <span class="work-title">{{
          translateTitle("市场").toUpperCase()
        }}</span>
        <a href="mailto:marketing@8v.com" class="work-subTitle">{{
          translateTitle("marketing@8v.com")
        }}</a>
      </a>
    </div>
    <div class="contract" v-if="language == 'zh_CN' || language == 'zh_HK'">
      <a href="https://www.facebook.com/8V.Asia" target="_blank" class="it">
        <img
          src="../../assets/img/aboutus-8v/contract/fb.png"
          alt="aboutus 8v.com"
          class="im"
        />
        <span class="text">Facebook</span>
        <i class="iconfont icongengduo-copy yellow"></i>
      </a>
      <a href="https://www.instagram.com/8v.asia/" target="_blank" class="it">
        <img
          src="../../assets/img/aboutus-8v/contract/Ins.png"
          alt="aboutus 8v.com"
          class="im"
        />
        <span class="text">Instagram</span>
        <i class="iconfont icongengduo-copy yellow"></i>
      </a>
      <a href="https://t.me/official_8v_com" target="_blank" class="it">
        <img
          src="../../assets/img/aboutus-8v/contract/tg.png"
          alt="aboutus 8v.com"
          class="im"
        />
        <span class="text">Telegram</span>
        <i class="iconfont icongengduo-copy yellow"></i>
      </a>
      <a href="https://t.me/official_8v_group" target="_blank" class="it">
        <img
          src="../../assets/img/aboutus-8v/contract/tg.png"
          alt="aboutus 8v.com"
          class="im"
        />
        <span class="text">Telegram{{ translateTitle("交流群") }}</span>
        <i class="iconfont icongengduo-copy yellow"></i>
      </a>
      <a
        href="https://www.youtube.com/channel/UCiB2p7qbFcGMII1_1YKUOYQ"
        target="_blank"
        class="it"
      >
        <img
          src="../../assets/img/aboutus-8v/contract/yt.png"
          alt="aboutus 8v.com"
          class="im"
        />
        <span class="text">Youtube</span>
        <i class="iconfont icongengduo-copy yellow"></i>
      </a>
    </div>
    <div class="contract" v-else>
      <a href="https://www.facebook.com/8V.Global/" target="_blank" class="it">
        <img
          src="../../assets/img/aboutus-8v/contract/fb.png"
          alt="aboutus 8v.com"
          class="im"
        />
        <span class="text">Facebook</span>
        <i class="iconfont icongengduo-copy yellow"></i>
      </a>
      <a href="https://www.instagram.com/8v.global/" target="_blank" class="it">
        <img
          src="../../assets/img/aboutus-8v/contract/Ins.png"
          alt="aboutus 8v.com"
          class="im"
        />
        <span class="text">Instagram</span>
        <i class="iconfont icongengduo-copy yellow"></i>
      </a>
      <a href="https://medium.com/@8v.global" target="_blank" class="it">
        <img
          src="../../assets/img/aboutus-8v/contract/me.png"
          alt="aboutus 8v.com"
          class="im"
        />
        <span class="text">Medium</span>
        <i class="iconfont icongengduo-copy yellow"></i>
      </a>
      <a href="https://discord.gg/yQusCKf3tN" target="_blank" class="it">
        <img
          src="../../assets/img/aboutus-8v/contract/di.png"
          alt="aboutus 8v.com"
          class="im"
        />
        <span class="text">Discord</span>
        <i class="iconfont icongengduo-copy yellow"></i>
      </a>
      <a href="https://twitter.com/8vGlobal" target="_blank" class="it">
        <img
          src="../../assets/img/aboutus-8v/contract/tw.png"
          alt="aboutus 8v.com"
          class="im"
        />
        <span class="text">Twitter</span>
        <i class="iconfont icongengduo-copy yellow"></i>
      </a>
      <a
        href="https://www.reddit.com/user/8V_Global"
        target="_blank"
        class="it"
      >
        <img
          src="../../assets/img/aboutus-8v/contract/re.png"
          alt="aboutus 8v.com"
          class="im"
        />
        <span class="text">Reddit</span>
        <i class="iconfont icongengduo-copy yellow"></i>
      </a>
      <a href="https://t.me/global8v" target="_blank" class="it">
        <img
          src="../../assets/img/aboutus-8v/contract/tg.png"
          alt="aboutus 8v.com"
          class="im"
        />
        <span class="text">Telegram</span>
        <i class="iconfont icongengduo-copy yellow"></i>
      </a>
    </div>
    <el-dialog
      title=""
      :show-close="false"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <div
        class="dialog-close"
        @click="
          () => {
            dialogVisible = false;
          }
        "
      ></div>
      <img class="dl-img" :src="currItem.img" alt="aboutus 8v.com" />
      <span class="dl-name">{{ currItem.name }}</span>
      <span class="dl-job">{{ currItem.job }}</span>
      <p class="dl-text">
        {{
          language == "zh_CN" || language == "zh_HK"
            ? currItem["zh_CN"]
            : currItem["en"]
        }}
      </p>
      <a class="dl-namecard" :href="currItem.link" target="_blank">
        <img
          class="nc-image"
          src="~@/assets/img/aboutus-8v/link.png"
          alt="aboutus 8v.com"
        />
        <span class="nc-text">{{ translateTitle("NameCard") }}</span>
      </a>
    </el-dialog>
  </div>
</template>
<script>
import { translateTitle } from "@/assets/js/i18n";
import advantages from "@/assets/img/aboutus-8v/advantages.png";
import advantages1 from "@/assets/img/aboutus-8v/advantages1.png";
import advantages2 from "@/assets/img/aboutus-8v/advantages2.png";
import advantages3 from "@/assets/img/aboutus-8v/advantages3.png";
import advantages4 from "@/assets/img/aboutus-8v/advantages4.png";
import advantages5 from "@/assets/img/aboutus-8v/advantages5.png";
import advantages6 from "@/assets/img/aboutus-8v/advantages6.png";
import banner from "@/assets/img/aboutus-8v/banner.png";
import ben from "@/assets/img/aboutus-8v/ben.png";
import bottomLogo from "@/assets/img/aboutus-8v/bottom-logo.png";
// import bottom1 from "@/assets/img/aboutus-8v/bottom1.png";
// import bottom2 from "@/assets/img/aboutus-8v/bottom2.png";
// import bottom3 from "@/assets/img/aboutus-8v/bottom3.png";
// import bottom4 from "@/assets/img/aboutus-8v/bottom4.png";
import bottom1n from "@/assets/img/aboutus-8v/bottom1-n.png";
import bottom2n from "@/assets/img/aboutus-8v/bottom2-n.png";
import bottom3n from "@/assets/img/aboutus-8v/bottom3-n.png";
import customer from "@/assets/img/aboutus-8v/customer.png";
import logoSlogan from "@/assets/img/aboutus-8v/logo-slogan.png";
import nick from "@/assets/img/aboutus-8v/nick.png";
import roland from "@/assets/img/aboutus-8v/roland.png";
import userCentric from "@/assets/img/aboutus-8v/user-centric.png";
import vision from "@/assets/img/aboutus-8v/vision.png";
import { mapActions, mapGetters } from "vuex";

import { VUE_APP_ID, VUE_APP_APP } from "@/assets/js/stationSetting";
export default {
  components: {},
  data() {
    return {
      advantages1,
      advantages2,
      advantages3,
      advantages4,
      advantages5,
      advantages6,
      ben,
      nick,
      roland,
      // bottom1,
      // bottom2,
      // bottom3,
      // bottom4,
      bottom1n,
      bottom2n,
      bottom3n,
      isShowIntroduction: false,
      isShowSlogan: false,
      isShowCentric: false,
      isShowItem: 0,
      eco_active: 1,
      currItem: {},
      items: {
        1: {
          name: "Matt",
          job: "Chief Architect",
          zh_CN:
            "Matt擁有在英國深耕15年的產品與技術架構經驗，並參與了超過50個項目的上線與發佈．在金融及遊戲領域，曾參與匯豐、Tesco Bank、CE、SuperCell的技術架構與產品設計。在加密貨幣及區塊鏈領域，曾任CoinDesk移動端技術負責人並是Qtum量子鏈項目參與者。Matt作為8V交易所技術與安全架構負責人，為平台打下了堅實的基礎。",
          en:
            "With 15 years of experience in technical architecture, Matt has brought his wizardry to some of the greatest online projects in the UK. From HSBC to Tesco Bank, and even contributing to the likes of SuperCell, he is a master of his craft. Heading up CoinDesk's mobile terminal technology and playing a key role in the enigmatic Qtum quantum chain project, Matt now oversees the technology and security of 8V Exchange. His mastery of the arcane and digital realm is unmatched, making him the ideal guardian of this powerful exchange.",
          link: "https://blinq.me/SuD5OxwID6XN",
          img: require("@/assets/img/aboutus-8v/otf1.png"),
        },
        2: {
          name: "Jasper",
          job: "Team Advocate",
          zh_CN:
            "擁有超過20年以上從Web2到Web3的完整相關經歷，Jasper是遊戲、軟體SaaS與網際網路產業的老兵，深刻了解如何以敏捷管理跨部門團隊快速滿足多元用戶需求，也從零開始一手打造了8V交易所的完整平台架構與用戶體驗。面對加密貨幣的波動週期，Jasper將率領團隊以穩健的策略，重構產品與服務的體質，迎向另一個加密春天。",
          en:
            "With over two decades of experience in the gaming, e-sports, and tech industries, Jasper is a seasoned veteran of the online world. As the architect of 8V Exchange's software infrastructure and user experience, Jasper leads the team with unwavering determination towards creating the ultimate user-friendly platform. With a steadfast focus on optimizing product features and offering top-notch service, Jasper navigates the ever-changing landscape of the crypto world with ease, always leading the team to success.",
          link: "https://blinq.me/yuJaRaYqu9T0",
          img: require("@/assets/img/aboutus-8v/otf2.png"),
        },
        3: {
          name: "Jack",
          job: "Platform Alchemist",
          zh_CN:
            "Jack在金融科技、資產管理、電子支付、代幣生成活動(TGE)以及去中心化技術(DLT)領域擁有超過13年的產品開發經驗。作為8V交易所的創新策略顧問，Jack主導平台的產品發展策略，與技術合作開發並測試平台新功能，在區塊鏈架構的基礎上，以「用戶為中心」，細細打磨層層把關，淬鍊出最人性化的交易所。",
          en:
            "Jack is a seasoned alchemist of the 8V Exchange, using his mastery of the arcane arts to develop and test new spells for the platform. With over 13 years of experience in the art of fintech, asset management, and the creation of enchanted tokens, Jack is a valuable member of the 8V team. As the advisor of innovation strategy, Jack works closely with the wizards of technology to brew the most potent elixirs and tonics for the users of the exchange. Jack's ultimate goal is to create a user-friendly realm of magic through a never-ending pursuit of product innovation.",
          link: "https://blinq.me/nRvGdFmS3eew",
          img: require("@/assets/img/aboutus-8v/otf3.png"),
        },
        4: {
          name: "Ann",
          job: "Marketing Wiz",
          zh_CN:
            "Ann擁有超過5年的品牌行銷、公共關係及專案管理經驗，並於2019年取得CMI策略管理及領導認證。Ann統籌8V交易所的品牌策略及行銷專案，致力於透過社群媒體等多元數位渠道推廣8V交易所，並與設計及運營等進行跨部門協作，以「創意+創新」為核心，打造出獨樹一幟的品牌風格，提升8V品牌附加價值．",
          en:
            "Ann, the Marketing Mage, wields her expertise in branding, PR, and project management to cast powerful spells and promote the image of 8V Exchange. Armed with a CMI Diploma in Strategic Management and Leadership, she collaborates with different departments to spread 8V's brand through multiple digital channels. Her belief in creativity and innovation makes her a fearsome force in enhancing 8V's brand value, leaving a lasting impression in the minds of all who behold it.",
          link: "https://blinq.me/gMuzImkFl6Bh",
          img: require("@/assets/img/aboutus-8v/otf4.png"),
        },
        5: {
          name: "David",
          job: "OPS Ninja",
          zh_CN:
            "David擁有多元化的工程背景和超過10年的區塊鏈經驗。 他領導8V交易所的運營部門，利用其在前端開發、營銷運營、項目啟動以及搜索引擎優化方面的專業知識來提高效率並取得成果。 此外，David精通多國語言並擁有政治學學位，在技術及人文領域都取得傲人成就，賦予了他在運營交易所時別具一格的優勢。",
          en:
            "David is a versatile adventurer with a diverse background in engineering and over 10 years of experience in blockchain. He leads the operations at 8V Exchange, using his expertise in front-end development, marketing ops, project launch and operations, and search engine optimization to drive efficiency and achieve results. David's multilingualism and degree in Political Science make him a valuable ally, who, with his mastery of operations, strikes fear into the hearts of enemies and earns the respect of allies.",
          link: "https://blinq.me/aJ65Pg8xdzkZ",
          img: require("@/assets/img/aboutus-8v/otf5.png"),
        },
      },
      dialogVisible: false,
    };
  },
  async created() {},
  computed: {
    ...mapGetters({
      language: "user/language",
    }),
  },
  methods: {
    translateTitle,
    doShowIntroduction() {
      this.isShowIntroduction = !this.isShowIntroduction;
    },
    // doShowItem(num) {
    //   if (num === this.isShowItem) {
    //     this.isShowItem = 0;
    //   } else {
    //     this.isShowItem = num;
    //   }
    // },
    doShowSlogan() {
      this.isShowSlogan = !this.isShowSlogan;
    },
    doShowCentric() {
      this.isShowCentric = !this.isShowCentric;
    },
    chooseEco(num) {
      this.eco_active = num;
    },
  },
};
</script>
<style lang="scss">
.aboutus-8v-page {
  .el-dialog {
    border-radius: 20px;
    background-color: #e0e0e0;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      word-break: normal;
      .dialog-close {
        cursor: pointer;
        position: absolute;
        top: 21.9px;
        right: 24.7px;
        width: 50.7px;
        height: 50.7px;
        background: url("~@/assets/img/aboutus-8v/close-icon.png") 0 0 no-repeat;
        background-size: 100% 100%;
      }
      .dl-img {
        margin-top: 24px;
        width: 251px;
        height: 280px;
      }
      .dl-name {
        margin-top: 15px;
        font-size: 21.3px;
        font-weight: 600;
        line-height: 0.71;
        color: #112132;
      }
      .dl-job {
        margin-top: 10px;
        font-size: 14.4px;
        font-weight: 500;
        line-height: 1.29;
        letter-spacing: 2.06px;
        color: #697786;
      }
      .dl-text {
        margin-top: 15.3px;
        width: 654px;
        font-size: 16px;
        line-height: 2.25;
        color: #112132;
      }
      .dl-namecard {
        cursor: pointer;
        margin-top: 25px;
        border-radius: 24.7px;
        background-color: #f7b239;
        padding: 11px 23.3px;
        display: flex;
        align-items: center;
        justify-content: center;
        .nc-image {
          width: 19.2px;
          height: 19.2px;
        }
        .nc-text {
          font-size: 15.8px;
          font-weight: 500;
          letter-spacing: 1.21px;
          color: #112132;
        }
      }
    }
  }
  .page-banner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    width: 100%;
    height: auto;
    background-size: 100% 100%;
    .bgvideo {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
    .logo-slogan {
      width: 100%;
      background-size: 100% 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .lg {
        width: auto;
        height: 113px;
      }
      .mt {
        text-align: center;
        margin-top: 20px;
        font-size: 34px;
        font-weight: 600;
        letter-spacing: 0.89px;
        color: #f7b239;
      }
      .st {
        text-align: center;
        margin-top: 20px;
        font-size: 21px;
        font-weight: 500;
        letter-spacing: 5px;
        color: #fff;
      }
      .rectangle {
        margin-top: 30px;
        width: 100%;
        // height: 192px;
        // background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000);
        display: flex;
        justify-content: center;
        align-items: flex-end;
        // margin-bottom: 100px;
        .more-button {
          user-select: none;
          cursor: pointer;
          // margin-bottom: 75px;
          width: 120px;
          height: 37px;
          padding: 0 20px 6px 23px;
          border-radius: 18.5px;
          border: solid 1px #fff;
          background-color: #e9ad47;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          font-weight: 400;
          color: #fff;
          .down {
            margin-left: 10px;
            margin-top: 5px;
          }
          .up {
            transform: rotate(180deg);
            margin-left: 10px;
            margin-top: 5px;
          }
        }
      }
    }
    // .rectangle {
    //   position: absolute;
    //   width: 100%;
    //   // height: 192px;
    //   // background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000);
    //   display: flex;
    //   justify-content: center;
    //   align-items: flex-end;
    //   margin-bottom: 100px;
    //   .more-button {
    //     user-select: none;
    //     cursor: pointer;
    //     // margin-bottom: 75px;
    //     width: 120px;
    //     height: 37px;
    //     padding: 0 20px 6px 23px;
    //     border-radius: 18.5px;
    //     border: solid 1px #fff;
    //     background-color: #e9ad47;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     font-size: 22px;
    //     font-weight: 400;
    //     color: #fff;
    //     .down {
    //       margin-left: 10px;
    //       margin-top: 5px;
    //     }
    //     .up {
    //       transform: rotate(180deg);
    //       margin-left: 10px;
    //       margin-top: 5px;
    //     }
    //   }
    // }
  }
  .rectangle-content {
    position: relative;
    z-index: 10;
    width: 100%;
    background-color: #000;
    display: flex;
    justify-content: center;
    .introduction {
      font-family: PingFangTC;
      width: 76%;
      padding: 30px;
      font-size: 22px;
      line-height: 2.14;
      letter-spacing: 2.16px;
      color: #a3a3a3;
      .site-name {
        color: #fff;
        margin-right: 10px;
      }
    }
  }
  .ecosystem {
    width: 100%;
    height: 770px;
    background-color: #112132;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title-ecosystem {
      margin-top: 27px;
      font-size: 26px;
      font-weight: 600;
      color: #f7b239;
    }
    .img-ecosystem {
      flex-shrink: 0;
      margin-top: -25px;
      transform: scale(0.78);
      width: 1282.5px;
      height: 769.2px;
      position: relative;
      background: url("../../assets/img/aboutus-8v/ecosystem-bg.png") center
        no-repeat;
      background-size: 86% 86%;
      .text {
        display: block;
        width: 100px;
        margin-top: 115px;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 2px;
        color: #f7b239;
        margin-left: 32.5px;
      }
      .first {
        cursor: pointer;
        width: 165px;
        height: 165px;
        position: absolute;
        top: 0%;
        left: 25%;
        text-align: center;
        background: url("../../assets/img/aboutus-8v/crypto_dis.png") 0 0
          no-repeat;
        background-size: 100% 100%;
        &.active {
          background: url("../../assets/img/aboutus-8v/crypto_on.png") 0 0
            no-repeat;
          background-size: 100% 100%;
          .text {
            color: #fff;
          }
        }
      }
      .second {
        cursor: pointer;
        width: 165px;
        height: 165px;
        position: absolute;
        top: 0%;
        right: 25%;
        text-align: center;
        background: url("../../assets/img/aboutus-8v/Defi_dis.png") 0 0
          no-repeat;
        background-size: 100% 100%;
        &.active {
          background: url("../../assets/img/aboutus-8v/Defi_on.png") 0 0
            no-repeat;
          background-size: 100% 100%;
          .text {
            color: #fff;
          }
        }
      }
      .third {
        cursor: pointer;
        width: 165px;
        height: 165px;
        position: absolute;
        top: 37%;
        right: 0.8%;
        text-align: center;
        background: url("../../assets/img/aboutus-8v/adv_dis.png") 0 0 no-repeat;
        background-size: 100% 100%;
        &.active {
          background: url("../../assets/img/aboutus-8v/adv_on.png") 0 0
            no-repeat;
          background-size: 100% 100%;
          .text {
            color: #fff;
          }
        }
      }
      .fourth {
        cursor: pointer;
        width: 165px;
        height: 165px;
        position: absolute;
        bottom: -3%;
        right: 25%;
        text-align: center;
        background: url("../../assets/img/aboutus-8v/social_dis.png") 0 0
          no-repeat;
        background-size: 100% 100%;
        &.active {
          background: url("../../assets/img/aboutus-8v/social_on.png") 0 0
            no-repeat;
          background-size: 100% 100%;
          .text {
            color: #fff;
          }
        }
      }
      .fifth {
        cursor: pointer;
        width: 165px;
        height: 165px;
        position: absolute;
        bottom: -3%;
        left: 25%;
        text-align: center;
        background: url("../../assets/img/aboutus-8v/game_dis.png") 0 0
          no-repeat;
        background-size: 100% 100%;
        &.active {
          background: url("../../assets/img/aboutus-8v/game_on.png") 0 0
            no-repeat;
          background-size: 100% 100%;
          .text {
            color: #fff;
          }
        }
      }
      .sixth {
        cursor: pointer;
        width: 165px;
        height: 165px;
        position: absolute;
        top: 37%;
        left: 0.8%;
        text-align: center;
        background: url("../../assets/img/aboutus-8v/NFT_dis.png") 0 0 no-repeat;
        background-size: 100% 100%;
        &.active {
          background: url("../../assets/img/aboutus-8v/NFT_on.png") 0 0
            no-repeat;
          background-size: 100% 100%;
          .text {
            color: #fff;
          }
        }
      }
      .tc {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .t {
          display: inline-block;
          padding: 5px 30px;
          border-radius: 35px;
          color: #112132;
          background-color: #f7b239;
          font-size: 19px;
          font-weight: 500;
        }
        .c {
          margin-top: 20px;
          width: 270px;
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 2.33px;
          text-align: center;
          color: #f7b239;
        }
      }
    }
  }
  .site-data {
    width: 100%;
    height: 168px;
    background-color: #000;
    padding: 0 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    .data-content {
      transform: scale(0.65);
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .data-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .data {
          margin-bottom: 5px;
          font-size: 57px;
          font-weight: 600;
          color: #fff;
        }
        .title {
          font-size: 32px;
          font-weight: 600;
          color: #bababa;
        }
      }
    }
  }
  .advantages {
    width: 100%;
    background: #121e2c url("../../assets/img/aboutus-8v/advantages_new.png") 0
      0 no-repeat;
    background-size: 977px 707px;
    background-position: bottom right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title-cn-english {
      margin-top: 51px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title-cn {
        font-size: 28px;
        font-weight: 600;
        color: #fff;
      }
      .title-cn-sub {
        font-size: 20px;
        font-weight: 600;
        color: #fff;
      }
      .title-english {
        font-size: 28px;
        font-weight: 500;
        color: #fff;
      }
    }
    .title-team-cn-english {
      margin-top: auto;
      margin-bottom: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title-cn {
        font-size: 50px;
        font-weight: 600;
        color: #fff;
      }
      .title-english {
        font-size: 43px;
        font-weight: 500;
        color: #fff;
      }
    }
    .advantages-items {
      margin-top: -20px;
      transform: scale(0.7);
      width: 100%;
      padding: 0 15.6%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .space {
        height: 100px;
        width: 280px;
      }
      .advantages-item-both {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .advantages-item-one {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .advantages-item-img {
            width: 102px;
            height: 102px;
            margin-bottom: 55px;
          }
          .advantages-item-p {
            height: 150px;
            font-size: 28px;
            font-weight: 500;
            color: #fff;
            text-align: center;
          }
        }
        .advantages-item-two {
          margin-top: 174px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .advantages-item-img {
            width: 102px;
            height: 102px;
            margin-bottom: 55px;
          }
          .advantages-item-p {
            font-size: 28px;
            font-weight: 500;
            color: #fff;
            text-align: center;
          }
        }
      }
    }
  }
  .team {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #e0e0e0;
    .team-title {
      width: 100%;
      margin-top: 41px;
      font-size: 28px;
      font-weight: 600;
      color: #112132;
      text-align: center;
    }
    .team-title-sub {
      width: 100%;
      font-size: 22px;
      font-weight: 600;
      color: #354052;
      text-align: center;
    }
    .team-items {
      width: 100%;
      margin-top: 62px;
      margin-bottom: 82px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .ti-itme-out {
        margin: 0 22.5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .tii-1 {
          background: url("~@/assets/img/aboutus-8v/ot1.png") center center
            no-repeat;
          background-size: 100% 100%;
          &:hover {
            .item-more {
              display: flex;
            }
          }
        }
        .tii-2 {
          background: url("~@/assets/img/aboutus-8v/ot2.png") center center
            no-repeat;
          background-size: 100% 100%;
          &:hover {
            .item-more {
              display: flex;
            }
          }
        }
        .tii-3 {
          background: url("~@/assets/img/aboutus-8v/ot3.png") center center
            no-repeat;
          background-size: 100% 100%;
          &:hover {
            .item-more {
              display: flex;
            }
          }
        }
        .tii-4 {
          background: url("~@/assets/img/aboutus-8v/ot4.png") center center
            no-repeat;
          background-size: 100% 100%;
          &:hover {
            .item-more {
              display: flex;
            }
          }
        }
        .tii-5 {
          background: url("~@/assets/img/aboutus-8v/ot5.png") center center
            no-repeat;
          background-size: 100% 100%;
          &:hover {
            .item-more {
              display: flex;
            }
          }
        }
        .ti-item {
          width: 171px;
          height: 210px;
          display: flex;
          align-items: flex-end;
          border-radius: 10px;
          .item-more {
            display: none;
            width: 100%;
            height: 58px;
            background-color: rgba(38, 38, 38, 0.53);
            align-items: center;
            justify-content: center;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            .more {
              cursor: pointer;
              width: 91px;
              height: 24px;
              border-radius: 20px;
              background-color: #f7b239;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 14px;
              font-weight: 500;
              color: #fff;
            }
          }
        }
        .item-name {
          margin-top: 12px;
          font-size: 18.5px;
          letter-spacing: 0.84px;
          color: #112132;
        }
        .item-job {
          margin-top: 3px;
          font-size: 14.9px;
          letter-spacing: 0.79px;
          color: #697786;
        }
      }
    }
  }
  .name-introduction {
    width: 100%;
    height: 530px;
    background-color: #121e2c;
    padding: 0 14%;
    .name-work {
      width: 100%;
      font-size: 38px;
      font-weight: 600;
      color: #fff;
      display: flex;
      justify-content: space-between;
      .name {
      }
      .work {
      }
    }
    .work-content {
      margin-top: 48px;
      font-size: 24px;
      color: #a3a3a3;
    }
  }
  .vision {
    width: 100%;
    height: 749px;
    background: url("../../assets/img/aboutus-8v/vision.png") center center
      no-repeat;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .vision-content {
      margin-right: 14%;
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .title-small {
        font-size: 36px;
        font-weight: 600;
        color: #fff;
      }
      .title-big {
        font-size: 46px;
        font-weight: 600;
        color: #f7b239;
      }
      .slogan-one {
        margin-top: 40px;
        font-size: 20px;
        font-weight: 600;
        color: #a3a3a3;
      }
      .slogan-two {
        margin-top: 37px;
        font-size: 20px;
        font-weight: 600;
        color: #a3a3a3;
      }
      .slogan-three {
        font-size: 20px;
        font-weight: 600;
        color: #a3a3a3;
      }
      .more-button {
        margin-top: 63px;
        user-select: none;
        cursor: pointer;
        width: 142px;
        height: 37px;
        padding: 0 20px 6px 23px;
        border-radius: 18.5px;
        border: solid 1px #f7b239;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        font-weight: 500;
        color: #f7b239;
        .down {
          margin-left: 10px;
          margin-top: 5px;
        }
        .up {
          transform: rotate(180deg);
          margin-left: 10px;
          margin-top: 5px;
        }
      }
    }
  }

  .centric {
    width: 100%;
    height: 738px;
    background: #121e2c url("../../assets/img/aboutus-8v/user-centric.png")
      center center no-repeat;
    background-size: cover;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .centric-content {
      margin-left: 11%;
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .title-small {
        font-size: 36px;
        font-weight: 600;
        color: #fff;
      }
      .title-big {
        font-size: 46px;
        font-weight: 600;
        color: #f7b239;
      }
      .slogan-one {
        margin-top: 40px;
        font-size: 20px;
        font-weight: 600;
        color: #a3a3a3;
      }
      .slogan-two {
        margin-top: 37px;
        font-size: 20px;
        font-weight: 600;
        color: #a3a3a3;
      }
      .slogan-three {
        font-size: 20px;
        font-weight: 600;
        color: #a3a3a3;
      }
      .more-button {
        margin-top: 63px;
        user-select: none;
        cursor: pointer;
        width: 142px;
        height: 37px;
        padding: 0 20px 6px 23px;
        border-radius: 18.5px;
        border: solid 1px #f7b239;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        font-weight: 500;
        color: #f7b239;
        .down {
          margin-left: 10px;
          margin-top: 5px;
        }
        .up {
          transform: rotate(180deg);
          margin-left: 10px;
          margin-top: 5px;
        }
      }
    }
  }

  .customer {
    width: 100%;
    height: 370px;
    background-color: #112132;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 106px 0 67px;
    .customer-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .item-image {
        width: 150px;
        height: 150px;
      }
      .work-title {
        margin-top: 24px;
        font-size: 30px;
        font-weight: 600;
        color: #f7b239;
      }
      .work-subTitle {
        margin-top: 2px;
        font-size: 17px;
        font-weight: 600;
        color: #fff;
      }
    }
    .space {
      width: 180px;
      height: 100px;
    }
  }
  .contract {
    width: 100%;
    padding: 0 300px 20px;
    background-color: #112132;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .it {
      margin-left: 30px;
      margin-bottom: 30px;
      padding: 8px;
      border-radius: 42.5px;
      border: solid 1.3px #fdb23c;
      background-color: #182c3c;
      display: flex;
      align-items: center;
      .im {
        width: 40px;
        height: 40px;
      }
      .text {
        margin-left: 15px;
        font-size: 15px;
        letter-spacing: 1px;
        color: #fff;
      }
      .yellow {
        margin-left: 70px;
        margin-right: 5px;
        font-size: 25px;
        font-weight: 600;
        color: #fdb23c;
      }
    }
  }
  .s-o {
    width: 100%;
    background-color: #121e2c;

    .split-bottom {
      width: 70%;
      height: 1px;
      background-color: #979797;
      margin: 0 auto;
    }
  }
}
</style>
