// const { VUE_APP_ENV } = process.env;
const Base64 = require("js-base64").Base64;
const uuid = require("uuid");

let device_id = null;
if (localStorage.getItem("web-device_id")) {
  device_id = localStorage.getItem("web-device_id");
} else {
  let tempUuid = uuid.v1();
  localStorage.setItem("web-device_id", tempUuid);
  device_id = tempUuid;
}

let VUE_APP_UCENTER = "";
let VUE_APP_SOTC = "";
let VUE_APP_APP = "";
let VUE_APP_ID = "";
let VUE_APP_KEY = "";
let VUE_APP_ENV = "";
let VUE_UNION_STATION = "";
let VUE_CHANNEL_ID = "";
let WEB_OPEN_COINM = 1;

let web_app_info = [];
let web_appuc = "";
let web_sotc = "";
let web_app = "";
let web_id = "";
let web_channel_id = "";
let open_coinm = "";

// let centerHost = "";
let configHost = "";

function apiGetEnv() {
  let tempConfigHost = window.location.host;

  if (tempConfigHost.includes("localhost")) {
    configHost = "https://exchange.hxexchge.com"; //开发环境
    // configHost = "https://exchange.justonlytest.com"; // 测试环境
    // configHost = "https://app.8v.com"; // online环境
  } else {
    configHost = window.location.origin;
  }

  let data = {};
  let status = 0;
  window.$.ajax({
    type: "POST", //请求方式
    async: false, // fasle表示同步请求，true表示异步请求
    contentType: "application/json;charset=UTF-8", //请求的媒体类型
    url: `${configHost}/Site/config`, //请求地址
    data: { device_id: device_id }, //数据，json字符串
    success: function (result) {
      //请求成功
      data = result.data;
      status = result.status;
      if (result?.data?.environment == "online") {
        console.log = function () {};
      }
    },
    error: function (e) {
      //请求失败，包含具体的错误信息
    },
  });
  return { status, data };
}

//缓存config,防止多次请求
function getSiteConfigInfo() {
  //先查缓存
  let result = localStorage.getItem("siteconfigInfo");
  let LastTime = localStorage.getItem("siteconfigLastTime");
  let nowTime = Date.parse(new Date()) / 1000;
  let siteconfigInfo;

  // if (
  //   result &&
  //   result != "undefined" &&
  //   result != "{}" &&
  //   result != "[object Object]" &&
  //   nowTime - LastTime > 10 * 60
  // ) {
  //   let timestamp = Date.parse(new Date()) / 1000;
  //   localStorage.setItem("siteconfigLastTime", timestamp);
  //   console.log("更新SiteConfig");
  // } else if (
  //   result &&
  //   result != "undefined" &&
  //   result != "{}" &&
  //   result != "[object Object]"
  // ) {
  //   siteconfigInfo = JSON.parse(result);
  //   return { status: 200, data: siteconfigInfo };
  // }

  //如果没有缓存
  const res = apiGetEnv();
  const { data } = res;
  if (!data) {
    return {};
  }

  siteconfigInfo = data;
  localStorage.setItem("siteconfigInfo", JSON.stringify(siteconfigInfo));
  let timestamp = Date.parse(new Date()) / 1000;
  localStorage.setItem("siteconfigLastTime", timestamp);
  return { status: 200, data: siteconfigInfo };
}
getEnv();

export function getEnv() {
  const { status, data } = getSiteConfigInfo();
  if (status == 200) {
    localStorage.setItem("web-app-info", JSON.stringify(data.app_info));
    localStorage.setItem("web-env", data.environment);
    localStorage.setItem("web-app", data.app);
    localStorage.setItem("web-appuc", data.appuc);
    sessionStorage.setItem("solgan", JSON.stringify(data.language));
    sessionStorage.setItem("clause", JSON.stringify(data.clause));
    sessionStorage.setItem("others", JSON.stringify(data.others));
    sessionStorage.setItem("qrcode", JSON.stringify(data.qrcode));
    sessionStorage.setItem("about", JSON.stringify(data.about));
    sessionStorage.setItem("customer_service_url", data.customer_service_url);
    sessionStorage.setItem("customer_type", data.customer_type);
    sessionStorage.setItem("contact_us", JSON.stringify(data.contact_us));
    sessionStorage.setItem(
      "zendesk_notice",
      JSON.stringify(data.zendesk_notice)
    );
    sessionStorage.setItem(
      "qrcode_download",
      JSON.stringify(data.qrcode_download)
    );
    sessionStorage.setItem(
      "download_app_info",
      JSON.stringify(data.download_app_info)
    );
    sessionStorage.setItem(
      "zendesk_notice",
      JSON.stringify(data.zendesk_notice)
    );
    sessionStorage.setItem("captcha_conf", JSON.stringify(data.captcha_conf)); //captcha_conf.status 1开启 2关闭 3维护
    WEB_OPEN_COINM = data.menu.open_coinm;
    web_app_info = data.app_info;
    web_app_info = data.app_info;
    web_appuc = data.appuc;
    web_appuc = data.appuc;
    web_sotc = data.sotc;
    web_app = data.app;
    web_channel_id = data.channel_id;
  }
}

let stationValue = JSON.parse(localStorage.getItem("stationValue"));

let tempInfo = web_app_info[0].slice(2) + web_app_info[1];

let webAppIdKeyInfo = JSON.parse(Base64.decode(tempInfo));
VUE_APP_ENV = localStorage.getItem("web-env");

// localStorage.setItem("web-id", webAppIdKeyInfo.app_id);
if (VUE_APP_ENV == "test" || VUE_APP_ENV == undefined) {
  if (stationValue) {
    VUE_APP_UCENTER = stationValue.appuc_domain_url;
    VUE_CHANNEL_ID = stationValue.channel_id;
    VUE_APP_SOTC = stationValue.appsotc_domain_url;
    VUE_APP_APP = stationValue.app_domain_url;
    VUE_APP_ID = stationValue.app_id;
    VUE_APP_KEY = stationValue.app_key;
  } else {
    localStorage.setItem("web-id", webAppIdKeyInfo.app_id);
    VUE_APP_UCENTER = web_appuc;
    VUE_CHANNEL_ID = web_channel_id;
    VUE_APP_SOTC = web_sotc;
    VUE_APP_APP = web_app;
    VUE_APP_ID = webAppIdKeyInfo.app_id;
    VUE_APP_KEY = webAppIdKeyInfo.app_key;
  }
} else {
  localStorage.setItem("web-id", webAppIdKeyInfo.app_id);
  VUE_APP_UCENTER = web_appuc;
  VUE_APP_SOTC = web_sotc;
  VUE_APP_APP = web_app;
  VUE_CHANNEL_ID = web_channel_id;
  VUE_APP_ID = webAppIdKeyInfo.app_id;
  VUE_APP_KEY = webAppIdKeyInfo.app_key;
}

VUE_UNION_STATION = "8V";

export {
  VUE_APP_UCENTER,
  VUE_APP_SOTC,
  VUE_CHANNEL_ID,
  VUE_APP_APP,
  VUE_APP_ID,
  VUE_APP_KEY,
  VUE_APP_ENV,
  VUE_UNION_STATION,
  WEB_OPEN_COINM,
  device_id,
};
