<template>
  <div class="maskExCareers">
    <div class="banner">
      <span>{{ translateTitle("Democratize Finance with Crypto") }}</span>
    </div>
    <div class="conent">
      <div class="title">{{ translateTitle("Open positions") }}</div>
      <!-- {{ optionsList }} -->
      <div class="select">
        <span v-loading="mainBoolean" class="loadingContent">
          <el-select
            v-model="financeAdmin"
            filterable
            clearable
            @change="eidtTitleName"
            :placeholder="translateTitle('所有部门')"
          >
            <el-option value="" :label="translateTitle('所有部门')">
            </el-option>
            <el-option
              v-for="item in optionsList"
              :key="item.job_id + '1'"
              :value="item.department_id"
              :label="item.department_name_lang"
            >
            </el-option>
          </el-select>
        </span>
        <span v-loading="mainBoolean" class="loadingContent">
          <el-select
            v-model="allLocations"
            class="maright"
            filterable
            clearable
            @change="changeCountry"
            :placeholder="translateTitle('所有地区')"
          >
            <el-option value="" :label="translateTitle('所有地区')">
            </el-option>
            <el-option
              v-for="item in LocationsList"
              :key="item.country_id + '2'"
              :value="item.country_id"
              :label="item.country_name_lang"
            >
            </el-option>
          </el-select>
        </span>
        <span class="loadingContent">
          <el-input
            @change="findDrug"
            clearable
            @input="findDrug"
            v-model="searchJobName"
            :placeholder="translateTitle('搜索职位')"
          ></el-input>
        </span>
      </div>
      <div class="list" v-loading="loading">
        <div class="listTitle">{{ TitleName }} ({{ total_list.length }})</div>
        <ul>
          <div v-if="recruitIntroduceList && recruitIntroduceList.length">
            <div
              class="block"
              v-for="data in recruitIntroduceList"
              :key="data.job_id + '3'"
            >
              <p class="department">
                {{ data.department_name_lang }}({{ data.child.length }})
              </p>
              <li
                v-for="item in data.child"
                :key="item.job_id + item.update_time"
              >
                <p @click="toPath(item)" class="heard">
                  {{ translateTitle(item.job_name_lang) }}
                </p>

                <div @click="toPath(item)" class="heard">
                  {{ translateTitle(item.country_name_lang) }}
                  /
                  {{ translateTitle(item.department_group_name_lang) }}
                </div>
                <a @click="toPath(item)">{{ translateTitle("Apply") }}</a>
              </li>
            </div>
          </div>
          <div v-else class="txtConter">{{ translateTitle("暂无数据") }}</div>
        </ul>
      </div>
      <div class="pageSteyle">
        <p
          v-if="all_department_list.length > recruitIntroduceList.length"
          @click="recruitIntroduceList = all_department_list"
        >
          展示全部
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
import routeSplicing from "@/assets/js/routeSplicing";
import { translateTitle } from "@/assets/js/i18n";
import { mapGetters } from "vuex";
import {
  apiRecruitGetCountryList,
  apiRecruitGetJobList,
  apiRecruitGetIntroduceList,
} from "@/model/recruitment/recruitment";
import { addAddress } from "../../../model/assets";
export default {
  computed: {
    ...mapGetters({
      // userInfo: "user/userInfo",
      language: "user/language",
    }),
  },
  mixins: [commonMixin],
  data() {
    return {
      total_list: [],
      searchJobName: "",
      selectSymbol: null,
      allJobList: [],
      financeAdmin: "",
      allLocations: "",
      pageInfo: {
        // pageTotal: null,
        pageTotal: null,
        page_size: 5,
        page: 1,
      },
      LocationsList: [],
      all_department_list: [],
      mainBoolean: false,
      optionsList: [],
      timeout: null,
      recruitIntroduceList: [],
      TitleName: "All",
      loading: false,
    };
  },
  async mounted() {
    await this.apiGetJobCountryListHttp();
    await this.eidtTitleName("");
  },
  methods: {
    translateTitle,
    routeSplicing,
    findDrug(value) {
      this.debounce(this.apiRecruitGetIntroduceListHttp, 500);
    },
    onCurrentPageChange(nowPage) {
      // 分页

      this.apiRecruitGetIntroduceListHttp();
    },
    changeCountry() {
      // select 回调函数 重新请求列表
      this.apiRecruitGetIntroduceListHttp();
    },
    async eidtTitleName(value) {
      //找到当前选择的工作名称 工作类型
      if (
        this.financeAdmin == "" &&
        this.allLocations == "" &&
        this.searchJobName == ""
      ) {
        this.TitleName = this.translateTitle("All");
      } else {
        this.TitleName = this.translateTitle("匹配");
      }
      await this.apiRecruitGetIntroduceListHttp();
    },
    debounce(fn, wait) {
      //fangdou
      if (this.timeout != null) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        fn();
      }, wait);
    },
    toPath(item) {
      this.$router.push({
        path: routeSplicing(this.language, "company/careersDetalis"),
        query: {
          title: item.job_name_lang,
          job_type_lang: item.job_type_lang,
          country_name_lang: item.country_name_lang,
          introduce_id: item.introduce_id,
          country_id: item.country_id,
          job_id: item.job_id,
        },
      });
    },

    async apiGetJobCountryListHttp() {
      // 同时发连个请求 所有的工作 所有的 国家
      this.mainBoolean = true;
      await Promise.all([apiRecruitGetJobList(), apiRecruitGetCountryList()])
        .then(list => {
          {
            const { status, data } = list[0];
            if (status == 200) {
              this.allJobList = data;
              let department_list = [];
              data?.forEach(item => {
                if (!department_list.includes(item.department_id)) {
                  department_list.push(item.department_id);
                }
              });
              // console.log(department_list)
              department_list?.forEach(item => {
                const c = data.find(k => k.department_id == item);
                this.optionsList.push(c);
              });
            }
          }
          {
            const { status, data } = list[1];
            status == 200 ? (this.LocationsList = data) : null;
          }
          this.mainBoolean = false;
        })
        .catch(error => {
          console.log(error);
        });
      this.mainBoolean = false;
    },
    async apiRecruitGetIntroduceListHttp() {
      // 条件搜索
      this.recruitIntroduceList = [];
      const requestParam = {
        department_id: this.financeAdmin,
        country_id: this.allLocations,
        job_name: this.searchJobName,
      };
      this.loading = true;
      const { status, data } = await apiRecruitGetIntroduceList(requestParam);
      if (status == 200) {
        this.total_list = data;
        let newArr = data;
        let department_id_list = [];
        let department_list = [];
        newArr?.forEach(item => {
          if (!department_id_list.includes(item.department_id)) {
            department_id_list.push(item.department_id);
            department_list.push(item);
          }
          this.allJobList?.forEach(job => {
            if (job.job_id == item.job_id) {
              item.job_type_lang =
                job && job["job_type_lang"] ? job.job_type_lang : "暂无";
              item.job_name_lang =
                job && job["job_name_lang"] ? job.job_name_lang : "暂无";
            }
          });
          this.LocationsList.forEach(nwid => {
            if (nwid.country_id == item.country_id) {
              item.country_name_lang =
                nwid && nwid["country_name_lang"]
                  ? nwid.country_name_lang
                  : "暂无";
            }
          });
        });
        department_list.forEach(item => {
          item.child = [];
          newArr.forEach(cld => {
            if (item.department_id == cld.department_id) {
              item.child.push(cld);
            }
          });
        });
        this.all_department_list = department_list;
        this.loading = false;
        if (department_list.length >= 3) {
          this.recruitIntroduceList = department_list.slice(0, 3);
        } else {
          this.recruitIntroduceList = department_list;
        }
      }
    },
  },
};
</script>
<style lang="scss">
.maskExCareers {
  background: #fff;
  .loadingContent {
    display: inline-block;
    margin-right: 20px;
    width: 193px;
    height: 40px;
  }
  .txtConter {
    text-align: center;
    padding: 50px;
  }
  .banner {
    width: 100%;
    height: 400px;
    background: url("./../../../../src/assets/img/maskEx/careers.webp")
      no-repeat;
    display: flex;
    align-items: center;
    background-size: cover;
    justify-content: center;
    font-size: 42px;
    font-weight: bold;
    text-align: left;
    color: #fff;
  }
  .conent {
    width: 800px;
    // background: #fff;
    margin: 0 auto;
    .title {
      padding: 46px 0 35px 0;
      font-size: 36px;
      font-weight: bold;
      text-align: left;
      color: #15192a;
    }
    .select {
      margin-bottom: 56px;
      // .maright {
      //   padding-left: 30px;
      // }
      .el-input.is-focus .el-input__inner {
        border-color: #15beb4 !important;
      }
      input:focus {
        border-color: #15beb4 !important;
      }
    }
    .list {
      .heard {
        cursor: pointer;
      }
      // background: #15192a;
      .listTitle {
        font-size: 24px;
        font-weight: bold;
        text-align: left;
        color: #15192a;
        padding-bottom: 24px;
      }
      ul {
        margin-bottom: 100px;
        .block {
          margin-bottom: 40px;
        }
        .department {
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
          border-bottom: rgba(0, 0, 0, 0.1) solid 1px;
          padding: 20px 0 23px 0;
        }
        li {
          margin: 45px 0;
          position: relative;
          p {
            // font-family: DIN;
            font-size: 18px;
            font-weight: 500;
            text-align: left;
            line-height: 18px;
            margin-bottom: 10px;
            color: #15192a;
          }
          div {
            font-size: 16px;
            text-align: left;
            line-height: 16px;
            margin-bottom: 14px;
            color: #666;
          }
          a {
            padding: 10px 20px;
            border-radius: 3px;
            background-color: #15beb4;
            font-size: 16px;
            font-weight: 500;
            text-align: left;
            color: #fff;
            // float: right;
            position: absolute;
            cursor: pointer;
            right: 0;
            top: 10px;
            display: inline-block;
          }
        }
      }
    }
  }
  .pageSteyle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
  }
}
</style>
