<template>
  <div></div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { apiGetStaticTableInfo } from "@/model/settings";
import { VUE_UNION_STATION } from "@/assets/js/stationSetting";
export default {
  data() {
    return {
      seoList: [
        {
          id: "English",
          value: "en",
          valueFull: "en_US",
          seoMsg: {
            index: {
              title:
                "8V Exchange | Trade Bitcoin & Metaverse Currency Securely",
              meta_name: {
                description:
                  "Trade BTC ETH DOGE USDT & over 200 more Digital Currencies | Spot - Margin 300X - OTC - Derivatives - Staking | Join the Crypto Exchange with a Metaverse Zone",
                keywords:
                  "8V Exchange, Crypto Exchange, Metaverse Cryptocurrency Exchange, Digital Currency Exchange",
                "twitter:title":
                  "8V Exchange : Gateway to The Crypto Metaverse",
                "twitter:description":
                  "Trade hundreds of coins on the crypto exchange built for the Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Gateway to The Crypto Metaverse",
                "og:title": "8V Exchange : Gateway to The Crypto Metaverse",
                "og:description":
                  "Trade hundreds of coins on the crypto exchange built for the Metaverse",
                "og:image:alt": "8V Exchange : Gateway to The Crypto Metaverse",
              },
            },
            metaverse: {
              title: "8V Metaverse | Metaverse Crypto Market Tracker",
              meta_name: {
                description:
                  "Enter the Metaverse Zone | Metaverse Crypto Market Tracker | Stay Up to Date With the Latest Metaverse Digital Currency News and Prices",
                keywords:
                  "8V Exchange, Metaverse Page, Metaverse Market, Metaverse Exchange",
                "twitter:title": "8V Metaverse Zone : Metaverse Crypto Tracker",
                "twitter:description":
                  "Trade hundreds of coins on the crypto exchange built for the Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Gateway to The Crypto Metaverse",
                "og:title": "8V Metaverse Zone : Metaverse Crypto Tracker",
                "og:description":
                  "Trade hundreds of coins on the crypto exchange built for the Metaverse",
                "og:image:alt": "8V Exchange : Gateway to The Crypto Metaverse",
              },
            },
            finances: {
              title: "8V Earn | Earn Crypto with High APY% Staking",
              meta_name: {
                description:
                  "Stake BTC ETH DOGE USDT & Many More Digital Currencies | Up to 22.5% Annual Yield | Staking DeFi and Cloud Mining Available",
                keywords:
                  "8V Earn, Crypto Staking, Metaverse Crypto Exchange, Digital Currency Staking",
                "twitter:title": "8V Earn : High APY% Staking on 8V Exchange",
                "twitter:description":
                  "Stake USDT Bitcoin and ETH on the crypto exchange built for the Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Gateway to The Crypto Metaverse",
                "og:title": "8V Earn : High APY% Staking on 8V Exchange",
                "og:description":
                  "Stake USDT Bitcoin and ETH on the crypto exchange built for the Metaverse",
                "og:image:alt": "8V Exchange : Gateway to The Crypto Metaverse",
              },
            },
            aboutus: {
              title: "8V Exchange - Leading the Crypto Revolution | About Us",
              meta_name: {
                description:
                  "Pioneering the crypto landscape since Bitcoin's inception, 8V Exchange leads with innovation. As we shape the crypto frontier, our curated selection of digital tokens and products undergoes strict scrutiny, ensuring our users invest with utmost confidence.",
                keywords:
                  "8V Exchange, about us page, Metaverse Cryptocurrency Exchange, Digital Currency Exchange",
                "twitter:title": "8V Exchange : About Us",
                "twitter:description":
                  "8V is leading the race to defining the metaverse, we offer an ever-growing selection of digital tokens and financial products so our users can invest with peace of mind",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Gateway to The Crypto Metaverse",
                "og:title": "8V Exchange : About Us",
                "og:description":
                  "8V is leading the race to defining the metaverse, we offer an ever-growing selection of digital tokens and financial products so our users can invest with peace of mind",
                "og:image:alt": "8V Exchange : Gateway to The Crypto Metaverse",
              },
            },
            "service-agreement": {
              title: "8V Exchange | Service Agreement",
              meta_name: {
                description: "Terms and Conditions",
                keywords:
                  "8V Exchange, service agreement, terms and conditions, Digital Currency Exchange",
                "twitter:title": "8V Exchange : Service Agreement",
                "twitter:description": "Terms and Conditions",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Gateway to The Crypto Metaverse",
                "og:title": "8V Exchange : Service Agreement",
                "og:description": "Terms and Conditions",
                "og:image:alt": "8V Exchange : Gateway to The Crypto Metaverse",
              },
            },
            register: {
              title: "Join 8V Exchange | Sign Up & Start Trading Today!",
              meta_name: {
                description:
                  "Join 8V Exchange and dive into a world of crypto trading. Trade BTC, ETH, DOGE, USDT, and 200+ digital currencies. Experience Spot, 300X Margin, OTC, Derivatives and Staking. Sign up now!",
                keywords: "8V Exchange, register, Sign up, enter email address",
                "twitter:title": "8V Exchange : Sign Up",
                "twitter:description":
                  "Trade hundreds of coins on the crypto exchange built for the Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Gateway to The Crypto Metaverse",
                "og:title": "8V Exchange : Sign Up",
                "og:description":
                  "Trade hundreds of coins on the crypto exchange built for the Metaverse",
                "og:image:alt": "8V Exchange : Gateway to The Crypto Metaverse",
              },
            },
            login: {
              title: "8V Exchange | Log In",
              meta_name: {
                description:
                  "Trade BTC ETH DOGE USDT & over 200 more Digital Currencies | Spot - Margin 300X - OTC - Derivatives - Staking | Join the Crypto Exchange with a Metaverse Zone",
                keywords: "8V Exchange, Log in, enter email, enter password",
                "twitter:title": "8V Exchange : Log In",
                "twitter:description":
                  "Trade hundreds of coins on the crypto exchange built for the Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Gateway to The Crypto Metaverse",
                "og:title": "8V Exchange : Log In",
                "og:description":
                  "Trade hundreds of coins on the crypto exchange built for the Metaverse",
                "og:image:alt": "8V Exchange : Gateway to The Crypto Metaverse",
              },
            },
            forgetPwd: {
              title: "8V Exchange | Reset Log In Password",
              meta_name: {
                description:
                  "After Resetting the Log In Password, Withdrawals are Prohibited for 24 Hours",
                keywords:
                  "8V Exchange, Reset Password, Forgot Password, Digital Currency Exchange",
                "twitter:title": "8V Exchange : Reset Log In Password",
                "twitter:description":
                  "After Resetting the Log In Password, Withdrawals are Prohibited for 24 Hours",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Gateway to The Crypto Metaverse",
                "og:title": "8V Exchange : Reset Log In Password",
                "og:description":
                  "After Resetting the Log In Password, Withdrawals are Prohibited for 24 Hours",
                "og:image:alt": "8V Exchange : Gateway to The Crypto Metaverse",
              },
            },
            "privacy-policy": {
              title: "8V Exchange | Privacy Policy",
              meta_name: {
                description: "8V Exchange Privacy Policy",
                keywords:
                  "8V Exchange, Privacy policy, information security, Digital Currency Exchange",
                "twitter:title": "8V Exchange : Privacy Policy",
                "twitter:description":
                  "Information Security and Privacy Notice",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Gateway to The Crypto Metaverse",
                "og:title": "8V Exchange : Privacy Policy",
                "og:description": "Information Security and Privacy Notice",
                "og:image:alt": "8V Exchange : Gateway to The Crypto Metaverse",
              },
            },
            //coinFullName coinName（大写） 需替换
            "contract-exchange": {
              title:
                "Trade coinFullName Futures on 8V Exchange | coinName/USDT Perpetual Futures",
              meta_name: {
                description:
                  "Trade coinName USDT Futures up to 200X Margin | Join the Crypto Exchange with a Metaverse Zone",
                keywords:
                  "8V Exchange, coinFullName USDT Futures, Crypto Futures, coinName Perpetual Futures",
                "twitter:title":
                  "Trade coinFullName Futures on 8V Exchange : coinName/USDT Perpetual Futures",
                "twitter:description":
                  "Trade coinName USDT Futures up to 200X Margin : Join the Crypto Exchange with a Metaverse Zone",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Gateway to The Crypto Metaverse",
                "og:title":
                  "Trade coinFullName Futures on 8V Exchange : coinName/USDT Perpetual Futures",
                "og:description":
                  "Trade coinName USDT Futures up to 200X Margin : Join the Crypto Exchange with a Metaverse Zone",
                "og:image:alt": "8V Exchange : Gateway to The Crypto Metaverse",
              },
            },
            //coinFullName coinName（大写） 需替换
            delivery: {
              title:
                "Trade coinFullName Futures on 8V Exchange | coinName Margined Futures",
              meta_name: {
                description:
                  "Trade coinName Margined Futures up to 100X Margin | Join the Crypto Exchange with a Metaverse Zone",
                keywords:
                  "8V Exchange, coinName  USDT Futures, Crypto Futures,  coinFullName Perpetual Futures",
                "twitter:title":
                  "Trade coinFullName Futures on 8V Exchange : coinName Margined Futures",
                "twitter:description":
                  "Trade coinName Margined Futures up to 100X Margin | Join the Crypto Exchange with a Metaverse Zone",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Gateway to The Crypto Metaverse",
                "og:title":
                  "Trade coinFullName Futures on 8V Exchange : coinName Margined Futures",
                "og:description":
                  "Trade coinName Margined Futures up to 100X Margin | Join the Crypto Exchange with a Metaverse Zone",
                "og:image:alt": "8V Exchange : Gateway to The Crypto Metaverse",
              },
            },
            //coinFullName coinName （大写） 需替换
            exchange: {
              title:
                "Trade coinFullName on 8V Exchange | coinName Spot Market Chart",
              meta_name: {
                description:
                  "Buy coinName In Seconds | Join the Crypto Exchange with a Metaverse Zone",
                keywords:
                  "8V Exchange, coinFullName spot, coinName chart, digital currency price",
                "twitter:title":
                  "Trade coinFullName on 8V Exchange | coinName Spot Market Chart",
                "twitter:description":
                  "Buy coinName In Seconds | Join the Crypto Exchange with a Metaverse Zone",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Gateway to The Crypto Metaverse",
                "og:title":
                  "Trade coinFullName on 8V Exchange | coinName Spot Market Chart",
                "og:description":
                  "Buy coinName In Seconds | Join the Crypto Exchange with a Metaverse Zone",
                "og:image:alt": "8V Exchange : Gateway to The Crypto Metaverse",
              },
            },
          },
        },
        {
          name: "日本語",
          value: "ja",
          valueFull: "ja_JP",
          seoMsg: {
            index: {
              title:
                "8V Exchange | ビットコインおよびメタバース通貨の安全な取引",
              meta_name: {
                description:
                  "BTC、ETH、DOGE、USDT、その他200種類以上のデジタル通貨の取引が可能 | スポット - 証拠金 300倍 - 店頭市場 - デリバティブ - ステーキング | メタバースゾーンで暗号資産交換所に参加しよう",
                keywords:
                  "8V Exchange, 仮想通貨取引所, メタバース暗号通貨取引所, デジタル通貨交換所",
                "twitter:title": "8V Exchange : 暗号資産メタバースへの玄関口",
                "twitter:description":
                  "メタバース用に構築された暗号資産交換所で何百ものコインを取引しよう",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : 暗号資産メタバースへの玄関口",
                "og:title": "8V Exchange : 暗号資産メタバースへの玄関口",
                "og:description":
                  "メタバース用に構築された暗号資産交換所で何百ものコインを取引しよう",
                "og:image:alt": "8V Exchange : 暗号資産メタバースへの玄関口",
              },
            },
            metaverse: {
              title: "8V Metaverse | メタバース暗号資産マーケット・トラッカー",
              meta_name: {
                description:
                  "メタバースゾーンへようこそ | メタバース暗号資産マーケット・トラッカー | メタバースデジタル通貨の最新ニュースと価格を常に把握しよう",
                keywords:
                  "8V Exchange, メタバースページ, メタバース市場, メタバース交換所",
                "twitter:title":
                  "8V Metaverse ゾーン：メタバース暗号資産トラッカー",
                "twitter:description":
                  "メタバース用に構築された暗号資産交換所で何百ものコインを取引しよう",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : 暗号資産メタバースへの玄関口",
                "og:title": "8V Metaverse ゾーン：メタバース暗号資産トラッカー",
                "og:description":
                  "メタバース用に構築された暗号資産交換所で何百ものコインを取引しよう",
                "og:image:alt": "8V Exchange : 暗号資産メタバースへの玄関口",
              },
            },
            finances: {
              title: "8V Earn | 高年間収益率のステーキングで暗号資産を稼ごう",
              meta_name: {
                description:
                  "BTC、ETH、DOGE、USDT、その他多くのデジタル通貨を保有しよう | 最大22.5%の年間利回り | ステーキングDeFiとクラウドマイニングが利用可能",
                keywords:
                  "8V Earn, 暗号資産ステーキング, メタバース暗号資産取引所,  デジタル通貨ステーキング",
                "twitter:title":
                  "8V Earn：8V Exchangeでの高年間収益率のステーキング",
                "twitter:description":
                  "メタバース用に構築された暗号資産交換所でUSDT BitcoinやETHを保有しよう",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : 暗号資産メタバースへの玄関口",
                "og:title":
                  "8V Earn：8V Exchangeでの高年間収益率のステーキング",
                "og:description":
                  "メタバース用に構築された暗号資産交換所でUSDT BitcoinやETHを保有しよう",
                "og:image:alt": "8V Exchange : 暗号資産メタバースへの玄関口",
              },
            },
            aboutus: {
              title: "8V Exchange | 会社概要",
              meta_name: {
                description:
                  "8Vはメタバースを定義する競争をリードしています。当社は、お客様が安心して投資できるように、増え続けるデジタルトークンと金融商品の選択肢を提供します",
                keywords:
                  "8V Exchange, 会社概要ページ, メタバース暗号通貨取引所, デジタル通貨交換所",
                "twitter:title": "8V Exchange : 会社概要",
                "twitter:description":
                  "8Vはメタバースを定義する競争をリードしています。当社は、お客様が安心して投資できるように、増え続けるデジタルトークンと金融商品の選択肢を提供します",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : 暗号資産メタバースへの玄関口",
                "og:title": "8V Exchange : 会社概要",
                "og:description":
                  "8Vはメタバースを定義する競争をリードしています。当社は、お客様が安心して投資できるように、増え続けるデジタルトークンと金融商品の選択肢を提供します",
                "og:image:alt": "8V Exchange : 暗号資産メタバースへの玄関口",
              },
            },
            "service-agreement": {
              title: "8V Exchange | サービス契約書",
              meta_name: {
                description: "利用規約",
                keywords:
                  "8V Exchange, サービス契約書, 利用規約, デジタル通貨交換所",
                "twitter:title": "8V Exchange : サービス契約書",
                "twitter:description": "利用規約",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : 暗号資産メタバースへの玄関口",
                "og:title": "8V Exchange : サービス契約書",
                "og:description": "利用規約",
                "og:image:alt": "8V Exchange : 暗号資産メタバースへの玄関口",
              },
            },
            register: {
              title: "8V Exchange | サインアップ",
              meta_name: {
                description:
                  "BTC、ETH、DOGE、USDT、その他200種類以上のデジタル通貨の取引が可能 | スポット - 証拠金 300倍 - 店頭市場 - デリバティブ - ステーキング | メタバースゾーンで暗号資産交換所に参加しよう",
                keywords:
                  "8V Exchange, 登録, サインアップ, Eメールアドレスの入力",
                "twitter:title": "8V Exchange : サインアップ",
                "twitter:description":
                  "メタバース用に構築された暗号資産交換所で何百ものコインを取引しよう",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : 暗号資産メタバースへの玄関口",
                "og:title": "8V Exchange : サインアップ",
                "og:description":
                  "メタバース用に構築された暗号資産交換所で何百ものコインを取引しよう",
                "og:image:alt": "8V Exchange : 暗号資産メタバースへの玄関口",
              },
            },
            login: {
              title: "8V Exchange | ログイン",
              meta_name: {
                description:
                  "BTC、ETH、DOGE、USDT、その他200種類以上のデジタル通貨の取引が可能 | スポット - 証拠金 300倍 - 店頭市場 - デリバティブ - ステーキング | メタバースゾーンで暗号資産交換所に参加しよう",
                keywords:
                  "8V Exchange, ログイン, Eメールを入力, パスワードを入力",
                "twitter:title": "8V Exchange : ログイン",
                "twitter:description":
                  "メタバース用に構築された暗号資産交換所で何百ものコインを取引しよう",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : 暗号資産メタバースへの玄関口",
                "og:title": "8V Exchange : ログイン",
                "og:description":
                  "メタバース用に構築された暗号資産交換所で何百ものコインを取引しよう",
                "og:image:alt": "8V Exchange : 暗号資産メタバースへの玄関口",
              },
            },
            forgetPwd: {
              title: "8V Exchange | ログインパスワードの再設定",
              meta_name: {
                description:
                  "ログインパスワードの再設定後、24時間は引き出し不可となります",
                keywords:
                  "8V Exchange, パスワードの再設定, パスワードを忘れた場合, デジタル通貨交換所",
                "twitter:title": "8V Exchange : ログインパスワードの再設定",
                "twitter:description":
                  "ログインパスワードの再設定後、24時間は引き出し不可となります",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : 暗号資産メタバースへの玄関口",
                "og:title": "8V Exchange : ログインパスワードの再設定",
                "og:description":
                  "ログインパスワードの再設定後、24時間は引き出し不可となります",
                "og:image:alt": "8V Exchange : 暗号資産メタバースへの玄関口",
              },
            },
            "privacy-policy": {
              title: "8V Exchange | プライバリーポリシー",
              meta_name: {
                description: "8V Exchange プライバリーポリシー",
                keywords:
                  "8V Exchange, プライバリーポリシー 情報セキュリティ, デジタル通貨交換所",
                "twitter:title": "8V Exchange : プライバリーポリシー",
                "twitter:description":
                  "情報セキュリティと個人情報保護に関するお知らせ",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : 暗号資産メタバースへの玄関口",
                "og:title": "8V Exchange : プライバリーポリシー",
                "og:description":
                  "情報セキュリティと個人情報保護に関するお知らせ",
                "og:image:alt": "8V Exchange : 暗号資産メタバースへの玄関口",
              },
            },
            "contract-exchange": {
              title:
                "8V ExchangeでcoinFullNameン先物を取引しよう | coinName/USDT パーペチュアル先物取引",
              meta_name: {
                description:
                  "coinName USDT先物を最大200倍の証拠金で取引しよう | メタバースゾーンのある暗号資産取引所に参加しよう",
                keywords:
                  "8V Exchange, coinFullNameンUSDT先物, 暗号先物, coinNameパーペチュアル先物",
                "twitter:title":
                  "8V ExchangeでcoinFullNameン先物を取引しよう : coinName/USDT パーペチュアル先物取引",
                "twitter:description":
                  "coinName USDT先物を最大200倍の証拠金で取引しよう : メタバースゾーンのある暗号資産取引所に参加しよう",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : 暗号資産メタバースへの玄関口",
                "og:title":
                  "8V ExchangeでcoinFullNameン先物を取引しよう : coinName/USDT パーペチュアル先物取引",
                "og:description":
                  "coinName USDT先物を最大200倍の証拠金で取引しよう : メタバースゾーンのある暗号資産取引所に参加しよう",
                "og:image:alt": "8V Exchange : 暗号資産メタバースへの玄関口",
              },
            },
            delivery: {
              title:
                "8V ExchangeでcoinFullNameン先物を取引しよう | coinName/USDT証拠金先物",
              meta_name: {
                description:
                  "coinName証拠金先物を最大100倍の証拠金で取引しよう | メタバースゾーンのある暗号資産取引所に参加しよう",
                keywords:
                  "8V Exchange, coinName USDT先物, coinFullNameン先物, パーペチュアル先物",
                "twitter:title":
                  "8V ExchangeでcoinFullNameン先物を取引しよう : coinName/USDT証拠金先物",
                "twitter:description":
                  "coinName証拠金先物を最大100倍の証拠金で取引しよう : メタバースゾーンのある暗号資産取引所に参加しよう",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : 暗号資産メタバースへの玄関口",
                "og:title":
                  "8V ExchangeでcoinFullNameン先物を取引しよう : coinName/USDT証拠金先物",
                "og:description":
                  "coinName証拠金先物を最大100倍の証拠金で取引しよう : メタバースゾーンのある暗号資産取引所に参加しよう",
                "og:image:alt": "8V Exchange : 暗号資産メタバースへの玄関口",
              },
            },
            exchange: {
              title:
                "8V ExchangeでcoinFullNameンを取引しよう | coinName スポット市場チャート",
              meta_name: {
                description:
                  "coinNameをすぐ買おう | メタバースゾーンのある暗号資産取引所に参加しよう",
                keywords:
                  "8V Exchange, coinFullNameンスポット物, coinNameチャート, デジタル通貨相場",
                "twitter:title":
                  "8V ExchangeでcoinFullNameンを取引しよう : coinName スポット市場チャート",
                "twitter:description":
                  "coinNameをすぐ買おう : メタバースゾーンのある暗号資産取引所に参加しよう",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : 暗号資産メタバースへの玄関口",
                "og:title":
                  "8V ExchangeでcoinFullNameンを取引しよう : coinName スポット市場チャート",
                "og:description":
                  "coinNameをすぐ買おう : メタバースゾーンのある暗号資産取引所に参加しよう",
                "og:image:alt": "8V Exchange : 暗号資産メタバースへの玄関口",
              },
            },
          },
        },
        {
          name: "한국인",
          value: "ko",
          valueFull: "ko_KR",
          seoMsg: {
            index: {
              title: "8V Exchange | 비트코인 & 메타버스 화폐 안전하게 거래하기",
              meta_name: {
                description:
                  "BTC ETH DOGE USDT & 200가지 이상의 디지털 통화 거래 | 스팟 - 마진 300X - OTC - 파생상품 - 스테이킹 | 메타버스 존이 있는 암호화폐 거래소에 가입하세요",
                keywords:
                  "8V Exchange, 암호화폐 거래소, 메타버스 암호화폐 거래소, 디지털 화폐 거래소",
                "twitter:title":
                  "8V Exchange : 암호화폐 메타버스로 가는 게이트웨이",
                "twitter:description":
                  "메타버스를 위해 구축된 암호화폐 거래소에서 수백 가지 코인을 거래하세요",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : 암호화폐 메타버스로 가는 게이트웨이",
                "og:title": "8V Exchange : 암호화폐 메타버스로 가는 게이트웨이",
                "og:description":
                  "메타버스를 위해 구축된 암호화폐 거래소에서 수백 가지 코인을 거래하세요",
                "og:image:alt":
                  "8V Exchange : 암호화폐 메타버스로 가는 게이트웨이",
              },
            },
            metaverse: {
              title: "8V Metaverse | 메타버스 암호화폐 마켓 트랙커",
              meta_name: {
                description:
                  "메타버스 존에 입장하세요 | 최신 메타버스 디지털 통화 뉴스 및 가격을 확인해보세요",
                keywords:
                  "8V Exchange, 메타버스 페이지, 메타버스 마켓, 메타버스 거래소",
                "twitter:title": "8V 메타버스 존 : 메타버스 암호화폐 트랙커",
                "twitter:description":
                  "메타버스를 위해 구축된 암호화폐 거래소에서 수백 가지 코인을 거래하세요",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : 암호화폐 메타버스로 가는 게이트웨이",
                "og:title": "8V 메타버스 존 : 메타버스 암호화폐 트랙커",
                "og:description":
                  "메타버스를 위해 구축된 암호화폐 거래소에서 수백 가지 코인을 거래하세요",
                "og:image:alt":
                  "8V Exchange : 암호화폐 메타버스로 가는 게이트웨이",
              },
            },
            finances: {
              title: "8V Earn | 높은 APY% 스테이킹으로 암호화폐 벌기",
              meta_name: {
                description:
                  "BTC ETH DOGE USDT & 더 다양한 디지털 화폐를 스테이킹하세요 | 최대 22.5% 연간 수익 | DeFi 스테이킹 및 클라우드 마이닝이 가능합니다",
                keywords:
                  "8V Earn, 암호화폐 스테이킹, 메타버스 암호화폐 거래소, 디지털 화폐 스테이킹",
                "twitter:title": "8V Earn : 8V Exchange에서 높은 APY% 스테이킹",
                "twitter:description":
                  "메타버스를 위해 구축된 암호화폐 거래소에서 USDT 비트코인 및 ETH를 스테이킹하세요",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : 암호화폐 메타버스로 가는 게이트웨이",
                "og:title": "8V Earn : 8V Exchange에서 높은 APY% 스테이킹",
                "og:description":
                  "메타버스를 위해 구축된 암호화폐 거래소에서 USDT 비트코인 및 ETH를 스테이킹하세요",
                "og:image:alt":
                  "8V Exchange : 암호화폐 메타버스로 가는 게이트웨이",
              },
            },
            aboutus: {
              title: "8V Exchange | 회사 소개",
              meta_name: {
                description:
                  "8V는 메타버스를 정의하는 경쟁을 주도하고 있으며, 사용자가 안심하고 투자할 수 있도록 계속 성장하는 디지털 토큰 및 금융 제품을 제공합니다",
                keywords:
                  "8V Exchange, 회사 소개 페이지, 메타버스 암호화폐 거래소, 디지털 화폐 거래소",
                "twitter:title": "8V Exchange : 회사 소개",
                "twitter:description":
                  "8V는 메타버스를 정의하는 경쟁을 주도하고 있으며, 사용자가 안심하고 투자할 수 있도록 계속 성장하는 디지털 토큰 및 금융 제품을 제공합니다",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : 암호화폐 메타버스로 가는 게이트웨이",
                "og:title": "8V Exchange : 회사 소개",
                "og:description":
                  "8V는 메타버스를 정의하는 경쟁을 주도하고 있으며, 사용자가 안심하고 투자할 수 있도록 계속 성장하는 디지털 토큰 및 금융 제품을 제공합니다",
                "og:image:alt":
                  "8V Exchange : 암호화폐 메타버스로 가는 게이트웨이",
              },
            },
            "service-agreement": {
              title: "8V Exchange | 서비스 약관",
              meta_name: {
                description: "사용 약관",
                keywords:
                  "8V Exchange, 서비스 약관, 사용 약관, 디지털 화폐 거래소",
                "twitter:title": "8V Exchange : 서비스 약관",
                "twitter:description": "사용 약관",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : 암호화폐 메타버스로 가는 게이트웨이",
                "og:title": "8V Exchange : 서비스 약관",
                "og:description": "사용 약관",
                "og:image:alt":
                  "8V Exchange : 암호화폐 메타버스로 가는 게이트웨이",
              },
            },
            register: {
              title: "8V Exchange | 가입하기",
              meta_name: {
                description:
                  "BTC ETH DOGE USDT & 200가지 이상의 디지털 통화 거래 | 스팟 - 마진 300X - OTC - 파생상품 - 스테이킹 | 메타버스 존이 있는 암호화폐 거래소에 가입하세요",
                keywords:
                  "8V Exchange, 등록하고, 가입하고, 이메일 주소를 입력하세요",
                "twitter:title": "8V Exchange : 가입하기",
                "twitter:description":
                  "메타버스를 위해 구축된 암호화폐 거래소에서 수백 가지 코인을 거래하세요",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : 암호화폐 메타버스로 가는 게이트웨이",
                "og:title": "8V Exchange : 가입하기",
                "og:description":
                  "메타버스를 위해 구축된 암호화폐 거래소에서 수백 가지 코인을 거래하세요",
                "og:image:alt":
                  "8V Exchange : 암호화폐 메타버스로 가는 게이트웨이",
              },
            },
            login: {
              title: "8V Exchange | 로그인",
              meta_name: {
                description:
                  "BTC ETH DOGE USDT & 200가지 이상의 디지털 통화 거래 | 스팟 - 마진 300X - OTC - 파생상품 - 스테이킹 | 메타버스 존이 있는 암호화폐 거래소에 가입하세요",
                keywords:
                  "8V Exchange, 로그인, 이메일 입력하기, 비밀번호 입력하기",
                "twitter:title": "8V Exchange : 로그인",
                "twitter:description":
                  "메타버스를 위해 구축된 암호화폐 거래소에서 수백 가지 코인을 거래하세요",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : 암호화폐 메타버스로 가는 게이트웨이",
                "og:title": "8V Exchange : 로그인",
                "og:description":
                  "메타버스를 위해 구축된 암호화폐 거래소에서 수백 가지 코인을 거래하세요",
                "og:image:alt":
                  "8V Exchange : 암호화폐 메타버스로 가는 게이트웨이",
              },
            },
            forgetPwd: {
              title: "8V Exchange | 로그인 비밀번호 리셋하기",
              meta_name: {
                description:
                  "로그인 암호를 리셋한 후 24시간 동안 인출이 금지됩니다",
                keywords:
                  "8V Exchange, 비밀번호 리셋하기, 비밀번호 잊어버림, 디지털 화폐 거래소",
                "twitter:title": "8V Exchange : 로그인 비밀번호 리셋하기",
                "twitter:description":
                  "로그인 암호를 리셋한 후 24시간 동안 인출이 금지됩니다",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : 암호화폐 메타버스로 가는 게이트웨이",
                "og:title": "8V Exchange : 로그인 비밀번호 리셋하기",
                "og:description":
                  "로그인 암호를 리셋한 후 24시간 동안 인출이 금지됩니다",
                "og:image:alt":
                  "8V Exchange : 암호화폐 메타버스로 가는 게이트웨이",
              },
            },
            "privacy-policy": {
              title: "8V Exchange | 개인정보 처리방침",
              meta_name: {
                description: "8V Exchange 개인정보 처리방침",
                keywords:
                  "8V Exchange, 개인정보 처리방침, 정보 보안, 디지털 화폐 거래소",
                "twitter:title": "8V Exchange : 개인정보 처리방침",
                "twitter:description": "정보 보안 및 개인정보 처리안내",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : 암호화폐 메타버스로 가는 게이트웨이",
                "og:title": "8V Exchange : 개인정보 처리방침",
                "og:description": "정보 보안 및 개인정보 처리안내",
                "og:image:alt":
                  "8V Exchange : 암호화폐 메타버스로 가는 게이트웨이",
              },
            },
            "contract-exchange": {
              title:
                "8V Exchange에서 coinFullName 선물 거래하기 | coinName/USDT 영구 선물",
              meta_name: {
                description:
                  "최대 200배 마진까지 coinName USDT 선물 거래 | 메타버스 존으로 암호화폐 거래소 가입하기",
                keywords:
                  "8V Exchange, coinFullName USDT 선물, 암호화폐 선물, coinName 영구 선물",
                "twitter:title":
                  "8V Exchange에서 coinFullName 선물 거래하기 ： coinName/USDT 영구 선물",
                "twitter:description":
                  "최대 200배 마진까지 coinName USDT 선물 거래 ： 메타버스 존으로 암호화폐 거래소 가입하기",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : 암호화폐 메타버스로 가는 게이트웨이",
                "og:title":
                  "8V Exchange에서 coinFullName 선물 거래하기 ： coinName/USDT 영구 선물",
                "og:description":
                  "최대 200배 마진까지 coinName USDT 선물 거래 ： 메타버스 존으로 암호화폐 거래소 가입하기",
                "og:image:alt":
                  "8V Exchange : 암호화폐 메타버스로 가는 게이트웨이",
              },
            },
            delivery: {
              title: "에서 coinFullName 선물 거래하기 | coinName 마진 선물",
              meta_name: {
                description:
                  "최대 100배 마진까지 coinName 마진 선물 거래 | 메타버스 존으로 암호화폐 거래소 가입하기",
                keywords:
                  "8V Exchange, coinName USDT 선물, coinFullName 선물, 영구 선물",
                "twitter:title":
                  "에서 coinFullName 선물 거래하기 ： coinName 마진 선물",
                "twitter:description":
                  "최대 100배 마진까지 coinName 마진 선물 거래 ： 메타버스 존으로 암호화폐 거래소 가입하기",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : 암호화폐 메타버스로 가는 게이트웨이",
                "og:title":
                  "에서 coinFullName 선물 거래하기 ： coinName 마진 선물",
                "og:description":
                  "최대 100배 마진까지 coinName 마진 선물 거래 ： 메타버스 존으로 암호화폐 거래소 가입하기",
                "og:image:alt":
                  "8V Exchange : 암호화폐 메타버스로 가는 게이트웨이",
              },
            },
            exchange: {
              title: "에서 coinFullName 거래하기 | coinName 스팟 마켓 차트",
              meta_name: {
                description:
                  "몇 초 만에 coinName 구매 | 메타버스 존으로 암호화 거래소에 가입하세요",
                keywords:
                  "8V Exchange, coinFullName 스팟, coinName 차트, 디지털 화폐 가격",
                "twitter:title":
                  "에서 coinFullName 거래하기 : coinName 스팟 마켓 차트",
                "twitter:description":
                  "몇 초 만에 coinName 구매 : 메타버스 존으로 암호화 거래소에 가입하세요",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : 암호화폐 메타버스로 가는 게이트웨이",
                "og:title":
                  "에서 coinFullName 거래하기 : coinName 스팟 마켓 차트",
                "og:description":
                  "몇 초 만에 coinName 구매 : 메타버스 존으로 암호화 거래소에 가입하세요",
                "og:image:alt":
                  "8V Exchange : 암호화폐 메타버스로 가는 게이트웨이",
              },
            },
          },
        },

        {
          name: "Français",
          value: "fr",
          valueFull: "fr_FR",
          seoMsg: {
            index: {
              title:
                "Bourse 8V | Négociez les monnaies du métavers et les Bitcoins en toute sécurité",
              meta_name: {
                description:
                  "Négociez les BTC, ETH, DOGE, USDT et plus de 200 monnaies numériques | Comptant - Marge 300X - OTC - Produits dérivés - Staking | Rejoignez la bourse crypto avec une zone du métavers",
                keywords:
                  "Bourse 8V, Bourse crypto, bourse de cryptomonnaies du métavers, Bourse de monnaies numériques",
                "twitter:title": "Bourse 8V : Portail vers le métavers crypto",
                "twitter:description":
                  "Négociez des centaines de jetons sur la bourse crypto construite pour le métavers",
              },
              meta_property: {
                "twitter:image:alt":
                  "Bourse 8V : Portail vers le métavers crypto",
                "og:title": "Bourse 8V : Portail vers le métavers crypto",
                "og:description":
                  "Négociez des centaines de jetons sur la bourse crypto construite pour le métavers",
                "og:image:alt": "Bourse 8V : Portail vers le métavers crypto",
              },
            },
            metaverse: {
              title:
                "Métavers 8V | Outil de suivi du marché crypto du métavers",
              meta_name: {
                description:
                  "Entrez dans la zone du métavers | Outil de suivi du marché crypto du métavers | Restez au courant des actualités et des prix des monnaies numériques du métavers",
                keywords:
                  "Bourse 8V, Page du métavers, marché du métavers, bourse du métavers",
                "twitter:title":
                  "Zone du métavers 8V :  Outil de suivi du métavers crypto",
                "twitter:description":
                  "Négociez des centaines de jetons sur la bourse crypto construite pour le métavers",
              },
              meta_property: {
                "twitter:image:alt":
                  "Bourse 8V : Portail vers le métavers crypto",
                "og:title":
                  "Zone du métavers 8V :  Outil de suivi du métavers crypto",
                "og:description":
                  "Négociez des centaines de jetons sur la bourse crypto construite pour le métavers",
                "og:image:alt": "Bourse 8V : Portail vers le métavers crypto",
              },
            },
            finances: {
              title:
                "Gains 8V | Gains crypto avec du staking à pourcentage élevé de rendement annuel APY%",
              meta_name: {
                description:
                  "Stakez des BTC, ETH, DOGE, USDT et beaucoup plus de monnaies numériques | Jusqu’à 22,5 % de rendement annuel | Staking DeFi et cloud mining disponible",
                keywords:
                  "Gains 8V, Staking crypto, bourse crypto du métavers, staking de monnaies numériques",
                "twitter:title":
                  "Gains 8V :  Pourcentage élevé de staking à rendement annuel APY% sur la bourse 8V",
                "twitter:description":
                  "Stakez des USDT, Bitcoins et ETH sur la bourse crypto construite pour le métavers",
              },
              meta_property: {
                "twitter:image:alt":
                  "Bourse 8V : Portail vers le métavers crypto",
                "og:title":
                  "Gains 8V :  Pourcentage élevé de staking à rendement annuel APY% sur la bourse 8V",
                "og:description":
                  "Stakez des USDT, Bitcoins et ETH sur la bourse crypto construite pour le métavers",
                "og:image:alt": "Bourse 8V : Portail vers le métavers crypto",
              },
            },
            aboutus: {
              title: "Bourse 8V | À propos",
              meta_name: {
                description:
                  "8V mène la course pour définir le métavers. Nous offrons une sélection toujours croissante de jetons numériques et de produits financiers afin que nos utilisateurs puissent investir en toute tranquillité",
                keywords:
                  "Bourse 8V, Page À propos, bourse crypto du métavers, Bourse de monnaies numériques",
                "twitter:title": "Bourse 8V : À propos",
                "twitter:description":
                  "8V mène la course pour définir le métavers. Nous offrons une sélection toujours croissante de jetons numériques et de produits financiers afin que nos utilisateurs puissent investir en toute tranquillité",
              },
              meta_property: {
                "twitter:image:alt":
                  "Bourse 8V : Portail vers le métavers crypto",
                "og:title": "Bourse 8V : À propos",
                "og:description":
                  "8V mène la course pour définir le métavers. Nous offrons une sélection toujours croissante de jetons numériques et de produits financiers afin que nos utilisateurs puissent investir en toute tranquillité",
                "og:image:alt": "Bourse 8V : Portail vers le métavers crypto",
              },
            },
            "service-agreement": {
              title: "Bourse 8V | Accord de service",
              meta_name: {
                description: "Terms and Conditions",
                keywords:
                  "Bourse 8V, accord de service, conditions générales, Bourse de monnaies numériques",
                "twitter:title": "Bourse 8V : Accord de service",
                "twitter:description": "Conditions générales",
              },
              meta_property: {
                "twitter:image:alt":
                  "Bourse 8V : Portail vers le métavers crypto",
                "og:title": "Bourse 8V : Accord de service",
                "og:description": "Conditions générales",
                "og:image:alt": "Bourse 8V : Portail vers le métavers crypto",
              },
            },
            register: {
              title: "Bourse 8V | Inscription",
              meta_name: {
                description:
                  "Négociez les BTC, ETH, DOGE, USDT et plus de 200 monnaies numériques | Comptant - Marge 300X - OTC - Produits dérivés - Staking | Rejoignez la bourse crypto avec une zone du métavers",
                keywords:
                  "Bourse 8V, inscription, s’inscrire, saisir l’adresse e-mail",
                "twitter:title": "Bourse 8V : Inscription",
                "twitter:description":
                  "Négociez des centaines de jetons sur la bourse crypto construite pour le métavers",
              },
              meta_property: {
                "twitter:image:alt":
                  "Bourse 8V : Portail vers le métavers crypto",
                "og:title": "Bourse 8V : Inscription",
                "og:description":
                  "Négociez des centaines de jetons sur la bourse crypto construite pour le métavers",
                "og:image:alt": "Bourse 8V : Portail vers le métavers crypto",
              },
            },
            login: {
              title: "Bourse 8V | Connexion",
              meta_name: {
                description:
                  "Négociez les BTC, ETH, DOGE, USDT et plus de 200 monnaies numériques | Comptant - Marge 300X - OTC - Produits dérivés - Staking | Rejoignez la bourse crypto avec une zone du métavers",
                keywords:
                  "Bourse 8V, Connexion, saisir l’adresse e-mail, saisir le mot de passe",
                "twitter:title": "Bourse 8V : Connexion",
                "twitter:description":
                  "Négociez des centaines de jetons sur la bourse crypto construite pour le métavers",
              },
              meta_property: {
                "twitter:image:alt":
                  "Bourse 8V : Portail vers le métavers crypto",
                "og:title": "Bourse 8V : Connexion",
                "og:description":
                  "Négociez des centaines de jetons sur la bourse crypto construite pour le métavers",
                "og:image:alt": "Bourse 8V : Portail vers le métavers crypto",
              },
            },
            forgetPwd: {
              title: "Bourse 8V | Réinitialiser le mot de passe de connexion",
              meta_name: {
                description:
                  "Après la réinitialisation du mot de passe de connexion, les retraits sont interdits pendant 24 heures",
                keywords:
                  "Bourse 8V, Réinitialiser le mot de passe, mot de passe oublié, Bourse de monnaies numériques",
                "twitter:title":
                  "Bourse 8V : Réinitialiser le mot de passe de connexion",
                "twitter:description":
                  "Après la réinitialisation du mot de passe de connexion, les retraits sont interdits pendant 24 heures",
              },
              meta_property: {
                "twitter:image:alt":
                  "Bourse 8V : Portail vers le métavers crypto",
                "og:title":
                  "Bourse 8V : Réinitialiser le mot de passe de connexion",
                "og:description":
                  "Après la réinitialisation du mot de passe de connexion, les retraits sont interdits pendant 24 heures",
                "og:image:alt": "Bourse 8V : Portail vers le métavers crypto",
              },
            },
            "privacy-policy": {
              title: "Bourse 8V | Politique de confidentialité",
              meta_name: {
                description: "Bourse 8V Politique de confidentialité",
                keywords:
                  "Bourse 8V, Politique de confidentialité, sécurité de l’information, Bourse de monnaies numériques",
                "twitter:title": "Bourse 8V : Politique de confidentialité",
                "twitter:description":
                  "Sécurité de l’information et avis de confidentialité",
              },
              meta_property: {
                "twitter:image:alt":
                  "Bourse 8V : Portail vers le métavers crypto",
                "og:title": "Bourse 8V : Politique de confidentialité",
                "og:description":
                  "Sécurité de l’information et avis de confidentialité",
                "og:image:alt": "Bourse 8V : Portail vers le métavers crypto",
              },
            },
            "contract-exchange": {
              title:
                "Négociez des marchés à terme de coinFullName sur une bourse 8V | Marchés à terme perpétuels de coinName/USDT\n      ",
              meta_name: {
                description:
                  "Négociez des marchés à terme de coinName USDT avec jusqu’à 200X de marge | Rejoignez la bourse crypto avec une zone du métavers",
                keywords:
                  "Bourse 8V, Marchés à terme de coinFullName USDT, marchés à terme crypto, marchés à terme perpétuels de coinName",
                "twitter:title":
                  "Négociez des marchés à terme de coinFullName sur une bourse 8V : Marchés à terme perpétuels de coinName/USDT",
                "twitter:description":
                  "Négociez des marchés à terme de coinName USDT avec jusqu’à 200X de marge : Rejoignez la bourse crypto avec une zone du métavers",
              },
              meta_property: {
                "twitter:image:alt":
                  "Bourse 8V : Portail vers le métavers crypto",
                "og:title":
                  "Négociez des marchés à terme de coinFullName sur une bourse 8V : Marchés à terme perpétuels de coinName/USDT",
                "og:description":
                  "Négociez des marchés à terme de coinName USDT avec jusqu’à 200X de marge : Rejoignez la bourse crypto avec une zone du métavers",
                "og:image:alt": "Bourse 8V : Portail vers le métavers crypto",
              },
            },
            delivery: {
              title:
                "Négociez des marchés à terme de coinFullName sur une bourse 8V | Marchés à terme de coinName à marge",
              meta_name: {
                description:
                  "Négociez des marchés à terme de coinName avec jusqu’à 100X de marge | Rejoignez la bourse crypto avec une zone du métavers",
                keywords:
                  "Bourse 8V, Marchés à terme de coinName USDT, marchés à terme de coinFullName, marchés à terme perpétuels",
                "twitter:title":
                  "Négociez des marchés à terme de coinFullName sur une bourse 8V : Marchés à terme de coinName à marge",
                "twitter:description":
                  "Négociez des marchés à terme de coinName avec jusqu’à 100X de marge : Rejoignez la bourse crypto avec une zone du métavers",
              },
              meta_property: {
                "twitter:image:alt":
                  "Bourse 8V : Portail vers le métavers crypto",
                "og:title":
                  "Négociez des marchés à terme de coinFullName sur une bourse 8V : Marchés à terme de coinName à marge",
                "og:description":
                  "Négociez des marchés à terme de coinName avec jusqu’à 100X de marge : Rejoignez la bourse crypto avec une zone du métavers",
                "og:image:alt": "Bourse 8V : Portail vers le métavers crypto",
              },
            },
            exchange: {
              title:
                "Négociez des coinFullName sur une bourse 8V | Graphique des marchés au comptant de coinName",
              meta_name: {
                description:
                  "Achetez des coinName en quelques secondes | Rejoignez la bourse crypto avec une zone du métavers",
                keywords:
                  "Bourse 8V, coinFullName au comptant, graphique de coinName, prix des monnaies numériques",
                "twitter:title":
                  "Négociez des coinFullName sur une bourse 8V : Graphique des marchés au comptant de coinName",
                "twitter:description":
                  "Achetez des coinName en quelques secondes : Rejoignez la bourse crypto avec une zone du métavers",
              },
              meta_property: {
                "twitter:image:alt":
                  "Bourse 8V : Portail vers le métavers crypto",
                "og:title":
                  "Négociez des coinFullName sur une bourse 8V : Graphique des marchés au comptant de coinName",
                "og:description":
                  "Achetez des coinName en quelques secondes : Rejoignez la bourse crypto avec une zone du métavers",
                "og:image:alt": "Bourse 8V : Portail vers le métavers crypto",
              },
            },
          },
        },
        {
          name: "Español",
          value: "es",
          valueFull: "es_ES",
          seoMsg: {
            index: {
              title:
                "Bolsa de 8V | Opera con Bitcoin y otras divisas digitales del Metaverso de forma segura",
              meta_name: {
                description:
                  "Opera con BTC, ETH, DOGE, USDT y otras 200 divisas digitales | Al contado - Margen 300X - Comercio OTC - Derivados - Staking | Únete al Bolsa de criptomonedas con una zona Metaverso",
                keywords:
                  "Bolsa de 8V, Bolsa de criptomonedas, Bolsa de criptomonedas del metaverso, Bolsa de divisas digitales",
                "twitter:title":
                  "Bolsa de 8V : La entrada al Metaverso de las criptomonedas",
                "twitter:description":
                  "Compra y vende cientos de divisas en el Bolsa de criptomonedas diseñado para el Metaverso",
              },
              meta_property: {
                "twitter:image:alt":
                  "Bolsa de 8V : La entrada al Metaverso de las criptomonedas",
                "og:title":
                  "Bolsa de 8V : La entrada al Metaverso de las criptomonedas",
                "og:description":
                  "Compra y vende cientos de divisas en el Bolsa de criptomonedas diseñado para el Metaverso",
                "og:image:alt":
                  "Bolsa de 8V : La entrada al Metaverso de las criptomonedas",
              },
            },
            metaverse: {
              title:
                "Metaverso 8V | Rastreador del mercado de criptomonedas del Metaverso",
              meta_name: {
                description:
                  "Entra en el Metaverso | Mantente al día de las últimas noticias y conoce los precios de las divisas digitales del Metaverso",
                keywords:
                  "Bolsa de 8V, Página del Metaverso, Mercado del Metaverso, Bolsa del Metaverso",
                "twitter:title":
                  "Zona Metaverso V8 : Rastreador de criptomonedas del Metaverso",
                "twitter:description":
                  "Compra y vende cientos de divisas en el Bolsa de criptomonedas diseñado para el Metaverso",
              },
              meta_property: {
                "twitter:image:alt":
                  "Bolsa de 8V : La entrada al Metaverso de las criptomonedas",
                "og:title":
                  "Zona Metaverso V8 : Rastreador de criptomonedas del Metaverso",
                "og:description":
                  "Compra y vende cientos de divisas en el Bolsa de criptomonedas diseñado para el Metaverso",
                "og:image:alt":
                  "Bolsa de 8V : La entrada al Metaverso de las criptomonedas",
              },
            },
            finances: {
              title:
                "8V Earn | Gana criptomonedas por hacer staking con un porcentaje de rendimiento anual (APY) elevado",
              meta_name: {
                description:
                  "Staking de BTC, ETH, DOGE, USDT y muchas más divisas digitales | Rendimiento anual de hasta el 22,5 % | Staking DeFi y Cloud Mining disponible",
                keywords:
                  "8V Earn, Staking de criptomonedas, Bolsa de criptomonedas del Metaverso, Staking de divisas digitales",
                "twitter:title":
                  "8V Earn : Haz staking y obtén un rendimiento APY elevado en el Bolsa de 8V",
                "twitter:description":
                  "Haz staking de USDT, Bitcoin y ETH en el Bolsa de criptomonedas diseñado para el Metaverso",
              },
              meta_property: {
                "twitter:image:alt":
                  "Bolsa de 8V : La entrada al Metaverso de las criptomonedas",
                "og:title":
                  "8V Earn : Haz staking y obtén un rendimiento APY elevado en el Bolsa de 8V",
                "og:description":
                  "Haz staking de USDT, Bitcoin y ETH en el Bolsa de criptomonedas diseñado para el Metaverso",
                "og:image:alt":
                  "Bolsa de 8V : La entrada al Metaverso de las criptomonedas",
              },
            },
            aboutus: {
              title: "Bolsa de 8V | Sobre nosotros",
              meta_name: {
                description:
                  "8V va a la cabeza de la carrera para definir el Metaverso, por lo que ofrecemos una selección cada vez mayor de tokens digitales y productos financieros para que nuestros usuarios inviertan con tranquilidad",
                keywords:
                  "Bolsa de 8V, página Sobre nosotros, Bolsa de criptomonedas del Metaverso, Bolsa de divisas digitales",
                "twitter:title": "Bolsa de 8V : Sobre nosotros",
                "twitter:description":
                  "8V va a la cabeza de la carrera para definir el Metaverso, por lo que ofrecemos una selección cada vez mayor de tokens digitales y productos financieros para que nuestros usuarios inviertan con tranquilidad",
              },
              meta_property: {
                "twitter:image:alt":
                  "Bolsa de 8V : La entrada al Metaverso de las criptomonedas",
                "og:title": "Bolsa de 8V : Sobre nosotros",
                "og:description":
                  "8V va a la cabeza de la carrera para definir el Metaverso, por lo que ofrecemos una selección cada vez mayor de tokens digitales y productos financieros para que nuestros usuarios inviertan con tranquilidad",
                "og:image:alt":
                  "Bolsa de 8V : La entrada al Metaverso de las criptomonedas",
              },
            },
            "service-agreement": {
              title: "Bolsa de 8V | Acuerdo de servicio",
              meta_name: {
                description: "Términos y condiciones",
                keywords:
                  "Bolsa de 8V, acuerdo de servicio, terminos y condiciones, Bolsa de divisas digitales",
                "twitter:title": "Bolsa de 8V : Acuerdo de servicio",
                "twitter:description": "Términos y condiciones",
              },
              meta_property: {
                "twitter:image:alt":
                  "Bolsa de 8V : La entrada al Metaverso de las criptomonedas",
                "og:title": "Bolsa de 8V : Acuerdo de servicio",
                "og:description": "Términos y condiciones",
                "og:image:alt":
                  "Bolsa de 8V : La entrada al Metaverso de las criptomonedas",
              },
            },
            register: {
              title: "Bolsa de 8V | Regístrate",
              meta_name: {
                description:
                  "Opera con BTC, ETH, DOGE, USDT y otras 200 divisas digitales | Al contado - Margen 300X - Comercio OTC - Derivados - Staking | Únete al Bolsa de criptomonedas con una zona Metaverso",
                keywords:
                  "Bolsa de 8V, registro, regístrate, introduce tu correo electrónico",
                "twitter:title": "Bolsa de 8V : Regístrate",
                "twitter:description":
                  "Compra y vende cientos de divisas en el Bolsa de criptomonedas diseñado para el Metaverso",
              },
              meta_property: {
                "twitter:image:alt":
                  "Bolsa de 8V : La entrada al Metaverso de las criptomonedas",
                "og:title": "Bolsa de 8V : Regístrate",
                "og:description":
                  "Compra y vende cientos de divisas en el Bolsa de criptomonedas diseñado para el Metaverso",
                "og:image:alt":
                  "Bolsa de 8V : La entrada al Metaverso de las criptomonedas",
              },
            },
            login: {
              title: "Bolsa de 8V | Iniciar sesión",
              meta_name: {
                description:
                  "Opera con BTC, ETH, DOGE, USDT y otras 200 divisas digitales | Al contado - Margen 300X - Comercio OTC - Derivados - Staking | Únete al Bolsa de criptomonedas con una zona Metaverso",
                keywords:
                  "Bolsa de 8V, Iniciar sesión, introducir correo electrónico, introducir contraseña",
                "twitter:title": "Bolsa de 8V : Iniciar sesión",
                "twitter:description":
                  "Compra y vende cientos de divisas en el Bolsa de criptomonedas diseñado para el Metaverso",
              },
              meta_property: {
                "twitter:image:alt":
                  "Bolsa de 8V : La entrada al Metaverso de las criptomonedas",
                "og:title": "Bolsa de 8V : Iniciar sesión",
                "og:description":
                  "Compra y vende cientos de divisas en el Bolsa de criptomonedas diseñado para el Metaverso",
                "og:image:alt":
                  "Bolsa de 8V : La entrada al Metaverso de las criptomonedas",
              },
            },
            forgetPwd: {
              title: "Bolsa de 8V | Restablecer contraseña de inicio de sesión",
              meta_name: {
                description:
                  "Después de restablecer la contraseña de inicio de sesión, no se pueden realizar retiradas durante 24 horas",
                keywords:
                  "Bolsa de 8V, Restablecer contraseña, Contraseña olvidada, Bolsa de divisas digitales",
                "twitter:title":
                  "Bolsa de 8V : Restablecer contraseña de inicio de sesión",
                "twitter:description":
                  "Después de restablecer la contraseña de inicio de sesión, no se pueden realizar retiradas durante 24 horas",
              },
              meta_property: {
                "twitter:image:alt":
                  "Bolsa de 8V : La entrada al Metaverso de las criptomonedas",
                "og:title":
                  "Bolsa de 8V : Restablecer contraseña de inicio de sesión",
                "og:description":
                  "Después de restablecer la contraseña de inicio de sesión, no se pueden realizar retiradas durante 24 horas",
                "og:image:alt":
                  "Bolsa de 8V : La entrada al Metaverso de las criptomonedas",
              },
            },
            "privacy-policy": {
              title: "Bolsa de 8V | Política de privacidad",
              meta_name: {
                description: "Bolsa de 8V Política de privacidad",
                keywords:
                  "Bolsa de 8V, Política de privacidad, Información sobre seguridad, Bolsa de divisas digitales",
                "twitter:title": "Bolsa de 8V : Política de privacidad",
                "twitter:description":
                  "Confidencialidad y seguridad de la información",
              },
              meta_property: {
                "twitter:image:alt":
                  "Bolsa de 8V : La entrada al Metaverso de las criptomonedas",
                "og:title": "Bolsa de 8V : Política de privacidad",
                "og:description":
                  "Confidencialidad y seguridad de la información",
                "og:image:alt":
                  "Bolsa de 8V : La entrada al Metaverso de las criptomonedas",
              },
            },
            "contract-exchange": {
              title:
                "Opera con futuros de coinFullName en el Bolsa de 8V | Futuros perpetuos coinName/USDT",
              meta_name: {
                description:
                  "Opera con futuros de coinName USDT con un margen de hasta 200X | Únete al Bolsa de criptomonedas con una zona Metaverso",
                keywords:
                  "Bolsa de 8V, Futuros coinFullName USDT, Futuros de criptomonedas, Futuros perpetuos coinName",
                "twitter:title":
                  "Opera con futuros de coinFullName en el Bolsa de 8V : Futuros perpetuos coinName/USDT",
                "twitter:description":
                  "Opera con futuros de coinName USDT con un margen de hasta 200X : Únete al Bolsa de criptomonedas con una zona Metaverso",
              },
              meta_property: {
                "twitter:image:alt":
                  "Bolsa de 8V : La entrada al Metaverso de las criptomonedas",
                "og:title":
                  "Opera con futuros de coinFullName en el Bolsa de 8V : Futuros perpetuos coinName/USDT",
                "og:description":
                  "Opera con futuros de coinName USDT con un margen de hasta 200X : Únete al Bolsa de criptomonedas con una zona Metaverso",
                "og:image:alt":
                  "Bolsa de 8V : La entrada al Metaverso de las criptomonedas",
              },
            },
            delivery: {
              title:
                "Opera con futuros de coinFullName en el Bolsa de 8V | Futuros con margen en coinName",
              meta_name: {
                description:
                  "Opera con futuros de coinName con un margen de hasta 100X | Únete al Bolsa de criptomonedas con una zona Metaverso",
                keywords:
                  "Bolsa de 8V, Futuros coinName USDT, Futuros coinFullName, Futuros perpetuos",
                "twitter:title":
                  "Opera con futuros de coinFullName en el Bolsa de 8V : Futuros con margen en coinName",
                "twitter:description":
                  "Opera con futuros de coinName con un margen de hasta 100X : Únete al Bolsa de criptomonedas con una zona Metaverso",
              },
              meta_property: {
                "twitter:image:alt":
                  "Bolsa de 8V : La entrada al Metaverso de las criptomonedas",
                "og:title":
                  "Opera con futuros de coinFullName en el Bolsa de 8V : Futuros con margen en coinName",
                "og:description":
                  "Opera con futuros de coinName con un margen de hasta 100X : Únete al Bolsa de criptomonedas con una zona Metaverso",
                "og:image:alt":
                  "Bolsa de 8V : La entrada al Metaverso de las criptomonedas",
              },
            },
            exchange: {
              title:
                "Compra y vende coinFullName en el Bolsa de 8V | Gráfico del mercado al contado de coinName",
              meta_name: {
                description:
                  "Compra coinName en segundos | Únete al Bolsa de criptomonedas con una zona Metaverso",
                keywords:
                  "Bolsa de 8V, Al contado de coinFullName, gráfico de coinName, precios divisas digitales",
                "twitter:title":
                  "Compra y vende coinFullName en el Bolsa de 8V : Gráfico del mercado al contado de coinName",
                "twitter:description":
                  "Compra coinName en segundos : Únete al Bolsa de criptomonedas con una zona Metaverso",
              },
              meta_property: {
                "twitter:image:alt":
                  "Bolsa de 8V : La entrada al Metaverso de las criptomonedas",
                "og:title":
                  "Compra y vende coinFullName en el Bolsa de 8V : Gráfico del mercado al contado de coinName",
                "og:description":
                  "Compra coinName en segundos : Únete al Bolsa de criptomonedas con una zona Metaverso",
                "og:image:alt":
                  "Bolsa de 8V : La entrada al Metaverso de las criptomonedas",
              },
            },
          },
        },
        {
          name: "Pусский",
          value: "ru",
          valueFull: "ru_RU",
          seoMsg: {
            index: {
              title:
                "8V Биржа | Безопасная торговля биткоином и метаверсионными криптовалютами",
              meta_name: {
                description:
                  "Торговля BTC ETH DOGE USDT и более 200 другими цифровыми криптовалютами | Спот - 300x кредитное плечо - внебиржевая торговля - деривативы - залоги | Присоединяйтесь к секции Metaverse на криптовалютной бирже",
                keywords:
                  "8V Exchange, Криптовалютная биржа, Метаверсивная криптовалютная биржа, Криптовалютные биржи",
                "twitter:title": "8V Exchange : Ворота в мир криптовалют",
                "twitter:description":
                  "Торговля сотнями монет на криптовалютной бирже, созданной для Метаверсе",
              },
              meta_property: {
                "twitter:image:alt": "8V Exchange : Ворота в мир криптовалют",
                "og:title": "8V Exchange : Ворота в мир криптовалют",
                "og:description":
                  "Торговля сотнями монет на криптовалютной бирже, созданной для Метаверсе",
                "og:image:alt": "8V Exchange : Ворота в мир криптовалют",
              },
            },
            metaverse: {
              title:
                "8V Метаверс | Отслеживание криптовалютного рынка Метаверс",
              meta_name: {
                description:
                  "Перейти в раздел Метаверс - будьте в курсе последних метаверсовских криптовалютных новостей и цен",
                keywords:
                  "8V Exchange, Метаверс страница, Метаверс рынок, Метаверс биржа",
                "twitter:title": "88V Метаверс Зона : Метаверс Крипто Трекер",
                "twitter:description":
                  "Торговля сотнями монет на криптовалютной бирже, созданной для Метаверсе",
              },
              meta_property: {
                "twitter:image:alt": "8V Exchange : Ворота в мир криптовалют",
                "og:title": "8V Метаверс Зона : Метаверс Крипто Трекер",
                "og:description":
                  "Торговля сотнями монет на криптовалютной бирже, созданной для Метаверсе",
                "og:image:alt": "8V Exchange : Ворота в мир криптовалют",
              },
            },
            finances: {
              title:
                "8V Earn | Зарабатывайте высокий доход APY% с помощью управления криптовалютными деньгами",
              meta_name: {
                description:
                  "Залог BTC ETH DOGE USDT и других криптовалют | До 22,5% годовых | Доступны залог DeFi и облачный майнинг",
                keywords:
                  "8V Earn, Криптовалютный залог, криптовалютная биржа Метаверс, залог цифровой валюты",
                "twitter:title": "8V Earn : Высокие APY% ставки на 8V Бирже",
                "twitter:description":
                  "Залог USDT Биткоин и ETH в криптовалютной бирже для Метаверса",
              },
              meta_property: {
                "twitter:image:alt": "8V Exchange : Ворота в мир криптовалют",
                "og:title": "8V Earn : Высокие APY% ставки на 8V Бирже",
                "og:description":
                  "Залог USDT Биткоин и ETH в криптовалютной бирже для Метаверса",
                "og:image:alt": "8V Exchange : Ворота в мир криптовалют",
              },
            },
            aboutus: {
              title: "8V Exchange | О нас",
              meta_name: {
                description:
                  "8V возглавляет гонку по определению метавселенной, поскольку мы предлагаем растущий выбор криптовалют и финансовых продуктов, которые позволяют нашим пользователям инвестировать со спокойной душой",
                keywords:
                  "8V Exchange, О нас страница, Криптовалютная биржа Метаверс, Криптовалютные биржи",
                "twitter:title": "8V Exchange : О нас",
                "twitter:description":
                  "8V возглавляет гонку по определению метавселенной, поскольку мы предлагаем растущий выбор криптовалют и финансовых продуктов, которые позволяют нашим пользователям инвестировать со спокойной душой",
              },
              meta_property: {
                "twitter:image:alt": "8V Exchange : Ворота в мир криптовалют",
                "og:title": "8V Exchange : О нас",
                "og:description":
                  "8V возглавляет гонку по определению метавселенной, поскольку мы предлагаем растущий выбор криптовалют и финансовых продуктов, которые позволяют нашим пользователям инвестировать со спокойной душой",
                "og:image:alt": "8V Exchange : Ворота в мир криптовалют",
              },
            },
            "service-agreement": {
              title: "8V Exchange | Сервисное соглашение",
              meta_name: {
                description: "Положения и условия",
                keywords:
                  "8V Exchange, Сервисное соглашение, Положения и условия, Криптовалютные биржи",
                "twitter:title": "8V Exchange : Сервисное соглашение",
                "twitter:description": "Положения и условия",
              },
              meta_property: {
                "twitter:image:alt": "8V Exchange : Ворота в мир криптовалют",
                "og:title": "8V Exchange : Сервисное соглашение",
                "og:description": "Положения и условия",
                "og:image:alt": "8V Exchange : Ворота в мир криптовалют",
              },
            },
            register: {
              title: "8V Exchange | Регистрация",
              meta_name: {
                description:
                  "Торговля BTC ETH DOGE USDT и более 200 другими цифровыми криптовалютами | Спот - 300x кредитное плечо - внебиржевая торговля - деривативы - залоги | Присоединяйтесь к секции Metaverse на криптовалютной бирже",
                keywords:
                  "8V Exchange, регистрация, Регистрация, ввести электронный адрес",
                "twitter:title": "8V Exchange : Регистрация",
                "twitter:description":
                  "Торговля сотнями монет на криптовалютной бирже, созданной для Метаверсе",
              },
              meta_property: {
                "twitter:image:alt": "8V Exchange : Ворота в мир криптовалют",
                "og:title": "8V Exchange : Регистрация",
                "og:description":
                  "Торговля сотнями монет на криптовалютной бирже, созданной для Метаверсе",
                "og:image:alt": "8V Exchange : Ворота в мир криптовалют",
              },
            },
            login: {
              title: "8V Exchange | Логин",
              meta_name: {
                description:
                  "Торговля BTC ETH DOGE USDT и более 200 другими цифровыми криптовалютами | Спот - 300x кредитное плечо - внебиржевая торговля - деривативы - залоги | Присоединяйтесь к секции Metaverse на криптовалютной бирже",
                keywords:
                  "8V Exchange, Логин, введите электронную почту, введите пароль",
                "twitter:title": "8V Exchange : Логин",
                "twitter:description":
                  "Торговля сотнями монет на криптовалютной бирже, созданной для Метаверсе",
              },
              meta_property: {
                "twitter:image:alt": "8V Exchange : Ворота в мир криптовалют",
                "og:title": "8V Exchange : Логин",
                "og:description":
                  "Торговля сотнями монет на криптовалютной бирже, созданной для Метаверсе",
                "og:image:alt": "8V Exchange : Ворота в мир криптовалют",
              },
            },
            forgetPwd: {
              title: "8V Exchange | Сброс пароля для входа",
              meta_name: {
                description:
                  "В течение 24 часов после сброса пароля для входа не снимаются средства",
                keywords:
                  "8V Exchange, Сброс пароля, забыли пароль, Криптовалютные биржи",
                "twitter:title": "8V Exchange : Сброс пароля для входа",
                "twitter:description":
                  "В течение 24 часов после сброса пароля для входа не снимаются средства",
              },
              meta_property: {
                "twitter:image:alt": "8V Exchange : Ворота в мир криптовалют",
                "og:title": "8V Exchange : Сброс пароля для входа",
                "og:description":
                  "В течение 24 часов после сброса пароля для входа не снимаются средства",
                "og:image:alt": "8V Exchange : Ворота в мир криптовалют",
              },
            },
            "privacy-policy": {
              title: "8V Exchange | Политика конфиденциальности",
              meta_name: {
                description: "8V Exchange Политика конфиденциальности",
                keywords:
                  "8V Exchange, Политика конфиденциальности, информационная безопасность, Криптовалютные биржи",
                "twitter:title": "8V Exchange : Политика конфиденциальности",
                "twitter:description":
                  "Положение об информационной безопасности и конфиденциальности",
              },
              meta_property: {
                "twitter:image:alt": "8V Exchange : Ворота в мир криптовалют",
                "og:title": "8V Exchange : Политика конфиденциальности",
                "og:description":
                  "Положение об информационной безопасности и конфиденциальности",
                "og:image:alt": "8V Exchange : Ворота в мир криптовалют",
              },
            },
            "contract-exchange": {
              title:
                "Торговля фьючерсами на 8V coinFullName | бессрочными контрактами coinName/USDT",
              meta_name: {
                description:
                  "Торговля фьючерсами coinName USDT с кредитным плечом до 200x | Вступить на криптовалютную биржу через зона Метаверс",
                keywords:
                  "8V Exchange, Фьючерсы на coinFullName USDT, фьючерсы на криптовалюту, бессрочные фьючерсы на coinName",
                "twitter:title":
                  "Торговля фьючерсами на 8V coinFullName : бессрочными контрактами coinName/USDT",
                "twitter:description":
                  "Торговля фьючерсами coinName USDT с кредитным плечом до 200x : Вступить на криптовалютную биржу через зона Метаверс",
              },
              meta_property: {
                "twitter:image:alt": "8V Exchange : Ворота в мир криптовалют",
                "og:title":
                  "Торговля фьючерсами на 8V coinFullName : бессрочными контрактами coinName/USDT",
                "og:description":
                  "Торговля фьючерсами coinName USDT с кредитным плечом до 200x : Вступить на криптовалютную биржу через зона Метаверс",
                "og:image:alt": "8V Exchange : Ворота в мир криптовалют",
              },
            },
            delivery: {
              title:
                "Торговля фьючерсами на coinFullName на бирже 8V | coinName Фьючерсы с кредитным плечом",
              meta_name: {
                description:
                  "Торговля фьючерсами coinName с кредитным плечом до 100x | Присоединяйтесь к криптовалютной бирже Метаверсес",
                keywords:
                  "8V Exchange, Фьючерсы coinName USDT, фьючерсы на coinFullName, бессрочные фьючерсы",
                "twitter:title":
                  "Торговля фьючерсами на coinFullName на бирже 8V : coinName Фьючерсы с кредитным плечом",
                "twitter:description":
                  "Торговля фьючерсами coinName с кредитным плечом до 100x : Присоединяйтесь к криптовалютной бирже Метаверсес",
              },
              meta_property: {
                "twitter:image:alt": "8V Exchange : Ворота в мир криптовалют",
                "og:title":
                  "Торговля фьючерсами на coinFullName на бирже 8V : coinName Фьючерсы с кредитным плечом",
                "og:description":
                  "Торговля фьючерсами coinName с кредитным плечом до 100x : Присоединяйтесь к криптовалютной бирже Метаверсес",
                "og:image:alt": "8V Exchange : Ворота в мир криптовалют",
              },
            },
            exchange: {
              title:
                "Торговля coinFullName на бирже 8V | coinName спотовый рыночный график",
              meta_name: {
                description:
                  "Купить coinName за секунды | Присоединяйтесь к криптовалютной бирже с зоной Metaverse",
                keywords:
                  "8V Exchange, coinFullName спот, график coinName, цена цифровой валюты",
                "twitter:title":
                  "Торговля coinFullName на бирже 8V : coinName спотовый рыночный график",
                "twitter:description":
                  "Купить coinName за секунды : Присоединяйтесь к криптовалютной бирже с зоной Metaverse",
              },
              meta_property: {
                "twitter:image:alt": "8V Exchange : Ворота в мир криптовалют",
                "og:title":
                  "Торговля coinFullName на бирже 8V : coinName спотовый рыночный график",
                "og:description":
                  "Купить coinName за секунды : Присоединяйтесь к криптовалютной бирже с зоной Metaverse",
                "og:image:alt": "8V Exchange : Ворота в мир криптовалют",
              },
            },
          },
        },
        {
          name: "Deutsch",
          value: "de",
          valueFull: "de_DE",
          seoMsg: {
            index: {
              title:
                "8V Börse | Sicheren Handel mit Bitcoin und Metaverse-Währung",
              meta_name: {
                description:
                  "Trade BTC ETH DOGE USDT & über 200 weiteren Digitalwährungen | Spot - Margin 300X - OTC - Derivative - Staking | Schließen Sie sich der Kryptobörse mit einer Metaverse Zone an",
                keywords:
                  "8V Börse, Kryptobörse, Metaverse Kryptowährungsbörse, digitale Währungen Börse",
                "twitter:title": "8V Börse: Portal zum Krypto-Metaversum",
                "twitter:description":
                  "Händeln Sie Hunderte von Münzen auf der für das Metaverse entwickelten Kryptobörse",
              },
              meta_property: {
                "twitter:image:alt": "8V Börse: Portal zum Krypto-Metaversum",
                "og:title": "8V Börse: Portal zum Krypto-Metaversum",
                "og:description":
                  "Händeln Sie Hunderte von Münzen auf der für das Metaverse entwickelten Kryptobörse",
                "og:image:alt": "8V Börse: Portal zum Krypto-Metaversum",
              },
            },
            metaverse: {
              title: "8V Metaverse | Metaverse Kryptobörse Tracker",
              meta_name: {
                description:
                  "Betreten Sie die Metaverse Zone | Metaverse Crypto Market Tracker | Bleiben Sie auf dem Laufenden mit den neuesten Nachrichten und Preisen für digitale Währungen von Metaverse",
                keywords:
                  "8V Börse, Metaverse Seite, Metaverse Markt, Metaverse Börse",
                "twitter:title": "8V Metaverse Zone : Metaverse Krypto Tracker",
                "twitter:description":
                  "Händeln Sie Hunderte von Münzen auf der für das Metaverse entwickelten Kryptobörse",
              },
              meta_property: {
                "twitter:image:alt": "8V Börse: Portal zum Krypto-Metaversum",
                "og:title": "8V Metaverse Zone : Metaverse Krypto Tracker",
                "og:description":
                  "Händeln Sie Hunderte von Münzen auf der für das Metaverse entwickelten Kryptobörse",
                "og:image:alt": "8V Börse: Portal zum Krypto-Metaversum",
              },
            },
            finances: {
              title: "8V Earn | Verdienen Sie Krypto mit hohem APY%-Einsatz",
              meta_name: {
                description:
                  "Setzten Sie auf BTC ETH DOGE USDT & viele weitere digitale Währungen | bis zu 22,5% Jahresausblick | Staking DeFi und Cloud Mining verfügbar",
                keywords:
                  "8V Earn, Krypto-Einsatz, Metaverse Krypto Börse, Digital Currency Staking",
                "twitter:title": "8V Earn : Höhe APY% Einsatz auf 8V Exchange",
                "twitter:description":
                  "Stake USDT Bitcoin und ETH auf der Krypto-Börse für das Metaverse gebaut",
              },
              meta_property: {
                "twitter:image:alt": "8V Börse: Portal zum Krypto-Metaversum",
                "og:title": "8V Earn : Höhe APY% Einsatz auf 8V Exchange",
                "og:description":
                  "Stake USDT Bitcoin und ETH auf der Krypto-Börse für das Metaverse gebaut",
                "og:image:alt": "8V Börse: Portal zum Krypto-Metaversum",
              },
            },
            aboutus: {
              title: "8V Börse | Impressum",
              meta_name: {
                description:
                  "8V ist führend im Rennen um die Definition des Metaversums. Wir bieten eine ständig wachsende Auswahl an digitalen Token und Finanzprodukten, damit unsere Nutzer beruhigt investieren können",
                keywords:
                  "8V Börse, Impressum Seite, Metaverse Kryptowährungsbörse, Exchange digitaler Währungen",
                "twitter:title": "8V Börse : Impressum",
                "twitter:description":
                  "8V ist führend im Rennen um die Definition des Metaversums. Wir bieten eine ständig wachsende Auswahl an digitalen Token und Finanzprodukten, damit unsere Nutzer beruhigt investieren können",
              },
              meta_property: {
                "twitter:image:alt": "8V Börse: Portal zum Krypto-Metaversum",
                "og:title": "8V Börse : Impressum",
                "og:description":
                  "8V ist führend im Rennen um die Definition des Metaversums. Wir bieten eine ständig wachsende Auswahl an digitalen Token und Finanzprodukten, damit unsere Nutzer beruhigt investieren können",
                "og:image:alt": "8V Börse: Portal zum Krypto-Metaversum",
              },
            },
            "service-agreement": {
              title: "8V Börse | Dienstleistungsvertrag",
              meta_name: {
                description: "Allgemeine Geschäftsbedingungen",
                keywords:
                  "8V Börse, Dienstleistungsvertrag, Allgemeine Geschäftsbedingungen, Exchange digitaler Währungen",
                "twitter:title": "8V Börse : Dienstleistungsvertrag",
                "twitter:description": "Allgemeine Geschäftsbedingungen",
              },
              meta_property: {
                "twitter:image:alt": "8V Börse: Portal zum Krypto-Metaversum",
                "og:title": "8V Börse : Dienstleistungsvertrag",
                "og:description": "Allgemeine Geschäftsbedingungen",
                "og:image:alt": "8V Börse: Portal zum Krypto-Metaversum",
              },
            },
            register: {
              title: "8V Börse | Registrieren",
              meta_name: {
                description:
                  "Trade BTC ETH DOGE USDT & über 200 weiteren Digitalwährungen | Spot - Margin 300X - OTC - Derivative - Staking | Schließen Sie sich der Kryptobörse mit einer Metaverse Zone an",
                keywords:
                  "8V Börse, sich registrieren, Anmeldung, E-Mail Adresse eingeben",
                "twitter:title": "8V Börse : Registrieren",
                "twitter:description":
                  "Handeln Sie Hunderte von Coins an der Krypto-Börse, die für das Metaverse entwickelt wurde",
              },
              meta_property: {
                "twitter:image:alt": "8V Börse: Portal zum Krypto-Metaversum",
                "og:title": "8V Börse : Registrieren",
                "og:description":
                  "Handeln Sie Hunderte von Coins an der Krypto-Börse, die für das Metaverse entwickelt wurde",
                "og:image:alt": "8V Börse: Portal zum Krypto-Metaversum",
              },
            },
            login: {
              title: "8V Börse | Einloggen",
              meta_name: {
                description:
                  "Trade BTC ETH DOGE USDT & über 200 weiteren Digitalwährungen | Spot - Margin 300X - OTC - Derivative - Staking | Schließen Sie sich der Kryptobörse mit einer Metaverse Zone an",
                keywords:
                  "8V Börse, Einloggen, E-Mail eingeben, Passwort eingeben",
                "twitter:title": "8V Börse : Einloggen",
                "twitter:description":
                  "Handeln Sie Hunderte von Coins an der Krypto-Börse, die für das Metaverse entwickelt wurde",
              },
              meta_property: {
                "twitter:image:alt": "8V Börse: Portal zum Krypto-Metaversum",
                "og:title": "8V Börse : Einloggen",
                "og:description":
                  "Handeln Sie Hunderte von Coins an der Krypto-Börse, die für das Metaverse entwickelt wurde",
                "og:image:alt": "8V Börse: Portal zum Krypto-Metaversum",
              },
            },
            forgetPwd: {
              title: "8V Börse | Passwort zurücksetzen",
              meta_name: {
                description:
                  "Nach Zurücksetzung des Passworts sind Auszahlungen für 24 Stunden untersagt",
                keywords:
                  "8V Börse, Passwort zurücksetzen, Passwort vergessen, Exchange digitaler Währungen",
                "twitter:title": "8V Börse : Passwort zurücksetzen",
                "twitter:description":
                  "Nach Zurücksetzung des Passworts sind Auszahlungen für 24 Stunden untersagt",
              },
              meta_property: {
                "twitter:image:alt": "8V Börse: Portal zum Krypto-Metaversum",
                "og:title": "8V Börse : Passwort zurücksetzen",
                "og:description":
                  "Nach Zurücksetzung des Passworts sind Auszahlungen für 24 Stunden untersagt",
                "og:image:alt": "8V Börse: Portal zum Krypto-Metaversum",
              },
            },
            "privacy-policy": {
              title: "8V Börse | Datenschutzrichtlinie",
              meta_name: {
                description: "8V Börse Datenschutzrichtlinie",
                keywords:
                  "8V Börse, Datenschutzrichtlinie, Informations-Sicherheit, Exchange digitaler Währungen",
                "twitter:title": "8V Börse : Datenschutzrichtlinie",
                "twitter:description": "Informationssicherheit und Datenschutz",
              },
              meta_property: {
                "twitter:image:alt": "8V Börse: Portal zum Krypto-Metaversum",
                "og:title": "8V Börse : Datenschutzrichtlinie",
                "og:description": "Informationssicherheit und Datenschutz",
                "og:image:alt": "8V Börse: Portal zum Krypto-Metaversum",
              },
            },
            "contract-exchange": {
              title:
                "Trade coinFullName Futures auf 8V Exchange | coinName/USDT Perpetual Futures",
              meta_name: {
                description:
                  "Trading coinName USDT Futures bis zu 200X Margin | Schließen Sie sich der Krypto-Börse mit einer Metaverse Zone an",
                keywords:
                  "8V Börse, coinFullName USDT-Futures, Krypto-Futures, coinName-Perpetual-Futures",
                "twitter:title":
                  "Trade coinFullName Futures auf 8V Exchange : coinName/USDT Perpetual Futures",
                "twitter:description":
                  "Trading coinName USDT Futures bis zu 200X Margin : Schließen Sie sich der Krypto-Börse mit einer Metaverse Zone an",
              },
              meta_property: {
                "twitter:image:alt": "8V Börse: Portal zum Krypto-Metaversum",
                "og:title":
                  "Trade coinFullName Futures auf 8V Exchange : coinName/USDT Perpetual Futures",
                "og:description":
                  "Trading coinName USDT Futures bis zu 200X Margin : Schließen Sie sich der Krypto-Börse mit einer Metaverse Zone an",
                "og:image:alt": "8V Börse: Portal zum Krypto-Metaversum",
              },
            },
            delivery: {
              title:
                "Trading coinFullName-Futures auf 8V Exchange | coinName Margined Futures",
              meta_name: {
                description:
                  "Trading coinName Margined Futures bis zu 100X Margin | Schließen Sie sich der Krypto-Börse mit einer Metaverse Zone an",
                keywords:
                  "8V Börse, coinName USDT-Futures, coinFullName-Futures, Perpetual-Futures",
                "twitter:title":
                  "Trading coinFullName-Futures auf 8V Exchange : coinName Margined Futures",
                "twitter:description":
                  "Trading coinName Margined Futures bis zu 100X Margin : Schließen Sie sich der Krypto-Börse mit einer Metaverse Zone an",
              },
              meta_property: {
                "twitter:image:alt": "8V Börse: Portal zum Krypto-Metaversum",
                "og:title":
                  "Trading coinFullName-Futures auf 8V Exchange : coinName Margined Futures",
                "og:description":
                  "Trading coinName Margined Futures bis zu 100X Margin : Schließen Sie sich der Krypto-Börse mit einer Metaverse Zone an",
                "og:image:alt": "8V Börse: Portal zum Krypto-Metaversum",
              },
            },
            exchange: {
              title:
                " coinFullName an der 8V Börse handeln | coinName Spot Market Chart",
              meta_name: {
                description:
                  "Kaufen Sie coinName in Sekunden | Treten Sie der Krypto-Börse mit einer Metaverse-Zone bei",
                keywords:
                  "8V Börse, coinFullName Spot, coinName Chart, Digitaler Währungspreis",
                "twitter:title":
                  "coinFullName an der 8V Börse handeln : coinName Spot Market Chart",
                "twitter:description":
                  "Kaufen Sie coinName in Sekunden : Treten Sie der Krypto-Börse mit einer Metaverse-Zone bei",
              },
              meta_property: {
                "twitter:image:alt": "8V Börse: Portal zum Krypto-Metaversum",
                "og:title":
                  "coinFullName an der 8V Börse handeln : coinName Spot Market Chart",
                "og:description":
                  "Kaufen Sie coinName in Sekunden : Treten Sie der Krypto-Börse mit einer Metaverse-Zone bei",
                "og:image:alt": "8V Börse: Portal zum Krypto-Metaversum",
              },
            },
          },
        },
        {
          name: "Português",
          value: "pt",
          valueFull: "pt_BR",
          seoMsg: {
            index: {
              title:
                "8V Exchange | Transacione moedas Bitcoin e Metaverse em segurança",
              meta_name: {
                description:
                  "Transacione BTC ETH DOGE USDT e mais de 200 outras moedas digitais | Spot - Margem 300X - OTC - Derivados - Staking | Junte-se ao mercado das criptomoedas com uma zona Metaverse",
                keywords:
                  "8V Exchange, Crypto Exchange, Exchange de criptomoeda Metaverse, Exchange de moeda digital",
                "twitter:title":
                  "8V Exchange : Porta de entrada para a criptomoeda metaverse",
                "twitter:description":
                  "Transacione centenas de moedas na exchange de criptomoeda criada para a Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Porta de entrada para a criptomoeda metaverse",
                "og:title":
                  "8V Exchange : Porta de entrada para a criptomoeda metaverse",
                "og:description":
                  "Transacione centenas de moedas na exchange de criptomoeda criada para a Metaverse",
                "og:image:alt":
                  "8V Exchange : Porta de entrada para a criptomoeda metaverse",
              },
            },
            metaverse: {
              title:
                "8V Metaverse | Tracker do mercado de criptomoeda Metaverse",
              meta_name: {
                description:
                  "Entre na Zona Metaverse | Tracker do mercado de criptomoeda Metaverse | Mantenha-se atualizado com as mais recentes notícias e preços da moeda digital Metaverse",
                keywords:
                  "8V Exchange, Página da Metaverse, mercado da Metaverse, Exchange da Metaverse",
                "twitter:title":
                  "8V Zona Metaverse: tracker de criptomoeda Metaverse",
                "twitter:description":
                  "Transacione centenas de moedas na exchange de criptomoeda criada para a Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Porta de entrada para a criptomoeda metaverse",
                "og:title":
                  "8V Zona Metaverse: tracker de criptomoeda Metaverse",
                "og:description":
                  "Transacione centenas de moedas na exchange de criptomoeda criada para a Metaverse",
                "og:image:alt":
                  "8V Exchange : Porta de entrada para a criptomoeda metaverse",
              },
            },
            finances: {
              title: "8V Earn | Ganhe Criptomoeda com Staking de Alta APY%",
              meta_name: {
                description:
                  "Realize o Staking de BTC ETH DOGE USDT e muitas mais moedas digitais | Até 22,5% de Rendimento Anual | Disponibilidade de DeFi de Staking e Mineração na Cloud",
                keywords:
                  "8V Earn, Staking de criptomoeda, Exchange de criptomoeda Metaverse, staking de moeda digital",
                "twitter:title": "8V Earn: Staking de alta APY% em exchange 8V",
                "twitter:description":
                  "Realize staking de bitcoin USDT e ETH na exchange de criptomoeda criada para a Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Porta de entrada para a criptomoeda metaverse",
                "og:title": "8V Earn: Staking de alta APY% em exchange 8V",
                "og:description":
                  "Realize staking de bitcoin USDT e ETH na exchange de criptomoeda criada para a Metaverse",
                "og:image:alt":
                  "8V Exchange : Porta de entrada para a criptomoeda metaverse",
              },
            },
            aboutus: {
              title: "8V Exchange | Sobre nós",
              meta_name: {
                description:
                  "8V lidera a corrida na definição da metaverse, oferecendo uma seleção crescente de tokens digitais e produtos financeiros , de modo a que os nossos utilizadores possam investir com tranquilidade",
                keywords:
                  "8V Exchange, página sobre nós, Exchange de criptomoeda Metaverse, Exchange de moeda digital",
                "twitter:title": "8V Exchange : Sobre nós",
                "twitter:description":
                  "8V lidera a corrida na definição da metaverse, oferecendo uma seleção crescente de tokens digitais e produtos financeiros , de modo a que os nossos utilizadores possam investir com tranquilidade",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Porta de entrada para a criptomoeda metaverse",
                "og:title": "8V Exchange : Sobre nós",
                "og:description":
                  "8V lidera a corrida na definição da metaverse, oferecendo uma seleção crescente de tokens digitais e produtos financeiros , de modo a que os nossos utilizadores possam investir com tranquilidade",
                "og:image:alt":
                  "8V Exchange : Porta de entrada para a criptomoeda metaverse",
              },
            },
            "service-agreement": {
              title: "8V Exchange | Contrato de serviço",
              meta_name: {
                description: "Termos e condições",
                keywords:
                  "8V Exchange, Contrato de serviço, Termos e condições, Exchange de moeda digital",
                "twitter:title": "8V Exchange : Contrato de serviço",
                "twitter:description": "Termos e condições",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Porta de entrada para a criptomoeda metaverse",
                "og:title": "8V Exchange : Contrato de serviço",
                "og:description": "Termos e condições",
                "og:image:alt":
                  "8V Exchange : Porta de entrada para a criptomoeda metaverse",
              },
            },
            register: {
              title: "8V Exchange | Registar",
              meta_name: {
                description:
                  "Transacione BTC ETH DOGE USDT e mais de 200 outras moedas digitais | Spot - Margem 300X - OTC - Derivados - Staking | Junte-se ao mercado das criptomoedas com uma zona Metaverse",
                keywords: "8V Exchange, Registar, inserir endereço de e-mail",
                "twitter:title": "8V Exchange : Registar",
                "twitter:description":
                  "Transacione centenas de moedas na exchange de criptomoeda criada para a Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Porta de entrada para a criptomoeda metaverse",
                "og:title": "8V Exchange : Registar",
                "og:description":
                  "Transacione centenas de moedas na exchange de criptomoeda criada para a Metaverse",
                "og:image:alt":
                  "8V Exchange : Porta de entrada para a criptomoeda metaverse",
              },
            },
            login: {
              title: "8V Exchange | Iniciar sessão",
              meta_name: {
                description:
                  "Transacione BTC ETH DOGE USDT e mais de 200 outras moedas digitais | Spot - Margem 300X - OTC - Derivados - Staking | Junte-se ao mercado das criptomoedas com uma zona Metaverse",
                keywords:
                  "8V Exchange, Iniciar sessão, inserir e-mail, inserir palavra-passe",
                "twitter:title": "8V Exchange : Iniciar sessão",
                "twitter:description":
                  "Transacione centenas de moedas na exchange de criptomoeda criada para a Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Porta de entrada para a criptomoeda metaverse",
                "og:title": "8V Exchange : Iniciar sessão",
                "og:description":
                  "Transacione centenas de moedas na exchange de criptomoeda criada para a Metaverse",
                "og:image:alt":
                  "8V Exchange : Porta de entrada para a criptomoeda metaverse",
              },
            },
            forgetPwd: {
              title: "8V Exchange | Repor palavra-passe de início de sessão",
              meta_name: {
                description:
                  "Após a reposição da palavra-passe de início de sessão, os levantamentos estão proibidos durante 24 horas",
                keywords:
                  "8V Exchange, Repor palavra-passe, esqueci-me da palavra-passe, Exchange de moeda digital",
                "twitter:title":
                  "8V Exchange : Repor palavra-passe de início de sessão",
                "twitter:description":
                  "Após a reposição da palavra-passe de início de sessão, os levantamentos estão proibidos durante 24 horas",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Porta de entrada para a criptomoeda metaverse",
                "og:title":
                  "8V Exchange : Repor palavra-passe de início de sessão",
                "og:description":
                  "Após a reposição da palavra-passe de início de sessão, os levantamentos estão proibidos durante 24 horas",
                "og:image:alt":
                  "8V Exchange : Porta de entrada para a criptomoeda metaverse",
              },
            },
            "privacy-policy": {
              title: "8V Exchange | Política de privacidade",
              meta_name: {
                description: "8V Exchange Política de privacidade",
                keywords:
                  "8V Exchange, Política de privacidade, segurança das informações, Exchange de moeda digital",
                "twitter:title": "8V Exchange : Política de privacidade",
                "twitter:description":
                  "Aviso de segurança e privacidade da Informação",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Porta de entrada para a criptomoeda metaverse",
                "og:title": "8V Exchange : Política de privacidade",
                "og:description":
                  "Aviso de segurança e privacidade da Informação",
                "og:image:alt":
                  "8V Exchange : Porta de entrada para a criptomoeda metaverse",
              },
            },
            "contract-exchange": {
              title:
                "Transacione futuros em coinFullName na Exchange 8V | Futuros Perpétuos em coinName/USDT",
              meta_name: {
                description:
                  "Transacione Futuros coinName USDT com uma Margem de até 200X | Junte-se ao Exchange de criptomoeda com uma zona Metaverse",
                keywords:
                  "8V Exchange, Futuros USDT de coinFullName, futuros de criptomoeda, futuros perpétuos de coinName",
                "twitter:title":
                  "Transacione futuros em coinFullName na Exchange 8V : Futuros Perpétuos em coinName/USDT",
                "twitter:description":
                  "Transacione Futuros coinName USDT com uma Margem de até 200X : Junte-se ao Exchange de criptomoeda com uma zona Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Porta de entrada para a criptomoeda metaverse",
                "og:title":
                  "Transacione futuros em coinFullName na Exchange 8V : Futuros Perpétuos em coinName/USDT",
                "og:description":
                  "Transacione Futuros coinName USDT com uma Margem de até 200X : Junte-se ao Exchange de criptomoeda com uma zona Metaverse",
                "og:image:alt":
                  "8V Exchange : Porta de entrada para a criptomoeda metaverse",
              },
            },
            delivery: {
              title:
                "Transacione futuros em coinFullName na Exchange 8V | Futuros marginados em coinName",
              meta_name: {
                description:
                  "Transacione futuros marginados em coinName com uma margem de até 100X | Junte-se ao Exchange de criptomoeda com uma zona Metaverse",
                keywords:
                  "8V Exchange, Futuros USDT de coinName, futuros de coinFullName, futuros Perpétuos",
                "twitter:title":
                  "Transacione futuros em coinFullName na Exchange 8V : Futuros marginados em coinName",
                "twitter:description":
                  "Transacione futuros marginados em coinName com uma margem de até 100X | Junte-se ao Exchange de criptomoeda com uma zona Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Porta de entrada para a criptomoeda metaverse",
                "og:title":
                  "Transacione futuros em coinFullName na Exchange 8V : Futuros marginados em coinName",
                "og:description":
                  "Transacione futuros marginados em coinName com uma margem de até 100X : Junte-se ao Exchange de criptomoeda com uma zona Metaverse",
                "og:image:alt":
                  "8V Exchange : Porta de entrada para a criptomoeda metaverse",
              },
            },
            exchange: {
              title:
                "Transacione coinFullName na Exchange 8V | Gráfico do mercado à vista de coinName",
              meta_name: {
                description:
                  "Compre coinName em segundos | Junte-se ao Exchange de Criptomoeda com uma zona metaverse",
                keywords:
                  "8V Exchange, Mercado à vista de coinFullName, gráfico de coinName, preço da moeda digital",
                "twitter:title":
                  "Transacione coinFullName na Exchange 8V : Gráfico do mercado à vista de coinName",
                "twitter:description":
                  "Compre coinName em segundos : Junte-se ao Exchange de Criptomoeda com uma zona metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Porta de entrada para a criptomoeda metaverse",
                "og:title":
                  "Transacione coinFullName na Exchange 8V : Gráfico do mercado à vista de coinName",
                "og:description":
                  "Compre coinName em segundos : Junte-se ao Exchange de Criptomoeda com uma zona metaverse",
                "og:image:alt":
                  "8V Exchange : Porta de entrada para a criptomoeda metaverse",
              },
            },
          },
        },
        {
          name: "हिंदी",
          value: "hi",
          valueFull: "hi_IN",
          seoMsg: {
            index: {
              title: "8V एक्सचेंज | बिटकॉइन ट्रेड एवं मेटावर्स मुद्रा सुरक्षा",
              meta_name: {
                description:
                  "BTC ETH DOGE USDT एवं 200 से अधिक डिजिटल मुद्रा से ट्रेड करें | स्पॉट - मार्जिन 300X - OTC - डेरीवेटिव्स- स्टेकिंग | मेटावर्स ज़ोन के साथ क्रिप्टो एक्सचेंज में शामिल हों",
                keywords:
                  "8V एक्सचेंज, क्रिप्टो एक्सचेंज, मेटावर्स क्रिप्टोकरेंसी एक्सचेंज, डिजिटल मुद्रा एक्सचेंज",
                "twitter:title": "8V एक्सचेंज : क्रिप्टो मेटावर्स के लिए गेटवे",
                "twitter:description":
                  "मेटावर्स के लिए निर्मित क्रिप्टो एक्सचेंज पर सैकड़ों कॉइन्स का ट्रेड करें",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V एक्सचेंज : क्रिप्टो मेटावर्स के लिए गेटवे",
                "og:title": "8V एक्सचेंज : क्रिप्टो मेटावर्स के लिए गेटवे",
                "og:description":
                  "मेटावर्स के लिए निर्मित क्रिप्टो एक्सचेंज पर सैकड़ों कॉइन्स का ट्रेड करें",
                "og:image:alt": "8V एक्सचेंज : क्रिप्टो मेटावर्स के लिए गेटवे",
              },
            },
            metaverse: {
              title: "8V मेटावर्स | मेटावर्स क्रिप्टो बाजार ट्रैकर",
              meta_name: {
                description:
                  "मेटावर्स ज़ोन दर्ज करें | नवीनतम मेटावर्स डिजिटल मुद्रा समाचार और कीमतों से अद्यतन बने रहें",
                keywords:
                  "8V एक्सचेंज, मेटावर्स पेज, मेटावर्स बाजार, मेटावर्स एक्सचेंज",
                "twitter:title": "8V मेटावर्स ज़ोन : मेटावर्स क्रिप्टो ट्रैकर",
                "twitter:description":
                  "मेटावर्स के लिए निर्मित क्रिप्टो एक्सचेंज पर सैकड़ों कॉइन्स का ट्रेड करें",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V एक्सचेंज : क्रिप्टो मेटावर्स के लिए गेटवे",
                "og:title": "8V मेटावर्स ज़ोन : मेटावर्स क्रिप्टो ट्रैकर",
                "og:description":
                  "मेटावर्स के लिए निर्मित क्रिप्टो एक्सचेंज पर सैकड़ों कॉइन्स का ट्रेड करें",
                "og:image:alt": "8V एक्सचेंज : क्रिप्टो मेटावर्स के लिए गेटवे",
              },
            },
            finances: {
              title: "8V कमाएं | उच्च APY% स्टेकिंग के साथ क्रिप्टो कमाएं",
              meta_name: {
                description:
                  "BTC ETH DOGE USDT एवं बहुत सी डिजिटल मुद्रा में स्टेक लगाएं | 22.5% तक वार्षिक प्राप्ति | DeFi स्टेकिंग और क्लाउड माइनिंग उपलब्ध",
                keywords:
                  "8V कमाएं, क्रिप्टो स्टेकिंग, मेटावर्स क्रिप्टो एक्सचेंज, डिजिटल मुद्रा स्टेकिंग",
                "twitter:title": "8V कमाएं : 8V एक्सचेंज पर उच्च APY%",
                "twitter:description":
                  "मेटावर्स के लिए निर्मित क्रिप्टो एक्सचेंज पर स्टेक USDT बिटकॉइन तथा ETH",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V एक्सचेंज : क्रिप्टो मेटावर्स के लिए गेटवे",
                "og:title": "8V कमाएं : 8V एक्सचेंज पर उच्च APY%",
                "og:description":
                  "मेटावर्स के लिए निर्मित क्रिप्टो एक्सचेंज पर स्टेक USDT बिटकॉइन तथा ETH",
                "og:image:alt": "8V एक्सचेंज : क्रिप्टो मेटावर्स के लिए गेटवे",
              },
            },
            aboutus: {
              title: "8V एक्सचेंज | हमारे बारे में",
              meta_name: {
                description:
                  "8V मेटावर्स को परिभाषित करने की दौड़ में अग्रणी है, हम डिजिटल टोकन और वित्तीय उत्पादों के बढ़ते चयन की पेशकश करते हैं ताकि हमारे उपयोगकर्ता शांतिपूर्वक निवेश कर सकें",
                keywords:
                  "8V एक्सचेंज, हमारे बारे में का पेज, मेटावर्स क्रिप्टोकरेंसी एक्सचेंज, डिजिटल मुद्रा एक्सचेंज",
                "twitter:title": "8V एक्सचेंज : हमारे बारे में",
                "twitter:description":
                  "8V मेटावर्स को परिभाषित करने की दौड़ में अग्रणी है, हम डिजिटल टोकन और वित्तीय उत्पादों के बढ़ते चयन की पेशकश करते हैं ताकि हमारे उपयोगकर्ता शांतिपूर्वक निवेश कर सकें",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V एक्सचेंज : क्रिप्टो मेटावर्स के लिए गेटवे",
                "og:title": "8V एक्सचेंज : हमारे बारे में",
                "og:description":
                  "8V मेटावर्स को परिभाषित करने की दौड़ में अग्रणी है, हम डिजिटल टोकन और वित्तीय उत्पादों के बढ़ते चयन की पेशकश करते हैं ताकि हमारे उपयोगकर्ता शांतिपूर्वक निवेश कर सकें",
                "og:image:alt": "8V एक्सचेंज : क्रिप्टो मेटावर्स के लिए गेटवे",
              },
            },
            "service-agreement": {
              title: "8V एक्सचेंज | सेवा अनुबंध",
              meta_name: {
                description: "नियम और शर्तें",
                keywords:
                  "8V एक्सचेंज, सेवा अनुबंध, नियम और शर्तें, डिजिटल मुद्रा एक्सचेंज",
                "twitter:title": "8V एक्सचेंज : सेवा अनुबंध",
                "twitter:description": "नियम और शर्तें",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V एक्सचेंज : क्रिप्टो मेटावर्स के लिए गेटवे",
                "og:title": "8V एक्सचेंज : सेवा अनुबंध",
                "og:description": "नियम और शर्तें",
                "og:image:alt": "8V एक्सचेंज : क्रिप्टो मेटावर्स के लिए गेटवे",
              },
            },
            register: {
              title: "8V एक्सचेंज | साइन अप करें",
              meta_name: {
                description:
                  "BTC ETH DOGE USDT एवं 200 से अधिक डिजिटल मुद्रा से ट्रेड करें | स्पॉट - मार्जिन 300X - OTC - डेरीवेटिव्स- स्टेकिंग | मेटावर्स ज़ोन के साथ क्रिप्टो एक्सचेंज में शामिल हों",
                keywords:
                  "8V एक्सचेंज, रजिस्टर करें, साइन अप करें, ईमेल पता दर्ज करें",
                "twitter:title": "8V एक्सचेंज : साइन अप करें",
                "twitter:description":
                  "मेटावर्स के लिए निर्मित क्रिप्टो एक्सचेंज पर सैकड़ों कॉइन्स का ट्रेड करें",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V एक्सचेंज : क्रिप्टो मेटावर्स के लिए गेटवे",
                "og:title": "8V एक्सचेंज : साइन अप करें",
                "og:description":
                  "मेटावर्स के लिए निर्मित क्रिप्टो एक्सचेंज पर सैकड़ों कॉइन्स का ट्रेड करें",
                "og:image:alt": "8V एक्सचेंज : क्रिप्टो मेटावर्स के लिए गेटवे",
              },
            },
            login: {
              title: "8V एक्सचेंज | लॉग इन करें",
              meta_name: {
                description:
                  "BTC ETH DOGE USDT एवं 200 से अधिक डिजिटल मुद्रा से ट्रेड करें | स्पॉट - मार्जिन 300X - OTC - डेरीवेटिव्स- स्टेकिंग | मेटावर्स ज़ोन के साथ क्रिप्टो एक्सचेंज में शामिल हों",
                keywords: "8V एक्सचेंज, Log in, enter email, enter password",
                "twitter:title": "8V एक्सचेंज : लॉग इन करें",
                "twitter:description":
                  "मेटावर्स के लिए निर्मित क्रिप्टो एक्सचेंज पर सैकड़ों कॉइन्स का ट्रेड करें",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V एक्सचेंज : क्रिप्टो मेटावर्स के लिए गेटवे",
                "og:title": "8V एक्सचेंज : लॉग इन करें",
                "og:description":
                  "मेटावर्स के लिए निर्मित क्रिप्टो एक्सचेंज पर सैकड़ों कॉइन्स का ट्रेड करें",
                "og:image:alt": "8V एक्सचेंज : क्रिप्टो मेटावर्स के लिए गेटवे",
              },
            },
            forgetPwd: {
              title: "8V एक्सचेंज | लॉग इन पासवर्ड रीसेट करें",
              meta_name: {
                description:
                  "लॉग इन पासवर्ड रीसेट करने के बाद, निकासियाँ 24 घंटों के लिए निषिद्ध होती हैं",
                keywords:
                  "8V एक्सचेंज, Reset Password, Forgot Password, डिजिटल मुद्रा एक्सचेंज",
                "twitter:title": "8V एक्सचेंज : लॉग इन पासवर्ड रीसेट करें",
                "twitter:description":
                  "लॉग इन पासवर्ड रीसेट करने के बाद, निकासियाँ 24 घंटों के लिए निषिद्ध होती हैं",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V एक्सचेंज : क्रिप्टो मेटावर्स के लिए गेटवे",
                "og:title": "8V एक्सचेंज : लॉग इन पासवर्ड रीसेट करें",
                "og:description":
                  "लॉग इन पासवर्ड रीसेट करने के बाद, निकासियाँ 24 घंटों के लिए निषिद्ध होती हैं",
                "og:image:alt": "8V एक्सचेंज : क्रिप्टो मेटावर्स के लिए गेटवे",
              },
            },
            "privacy-policy": {
              title: "8V एक्सचेंज | निजता नीति",
              meta_name: {
                description: "8V एक्सचेंज निजता नीति",
                keywords:
                  "8V एक्सचेंज, निजता नीति, जानकारी सुरक्षा, डिजिटल मुद्रा एक्सचेंज",
                "twitter:title": "8V एक्सचेंज : निजता नीति",
                "twitter:description": "जानकारी सुरक्षा और निजता सूचना",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V एक्सचेंज : क्रिप्टो मेटावर्स के लिए गेटवे",
                "og:title": "8V एक्सचेंज : निजता नीति",
                "og:description": "जानकारी सुरक्षा और निजता सूचना",
                "og:image:alt": "8V एक्सचेंज : क्रिप्टो मेटावर्स के लिए गेटवे",
              },
            },
            "contract-exchange": {
              title:
                "8V एक्सचेंज पर coinFullName फ्यूचर्स ट्रेड करें | coinName/USDT पर्पीचुअल फ्यूचर्स",
              meta_name: {
                description:
                  "200X मार्जिन तक coinName USDT फ्यूचर्स ट्रेड करें | मेटावर्स ज़ोन के साथ क्रिप्टो एक्सचेंज में शामिल हों",
                keywords:
                  "8V एक्सचेंज, coinFullName USDT फ्यूचर्स, क्रिप्टो फ्यूचर्स, coinName पर्पीचुअल फ्यूचर्स",
                "twitter:title":
                  "8V एक्सचेंज पर coinFullName फ्यूचर्स ट्रेड करें : coinName/USDT पर्पीचुअल फ्यूचर्स",
                "twitter:description":
                  "200X मार्जिन तक coinName USDT फ्यूचर्स ट्रेड करें : मेटावर्स ज़ोन के साथ क्रिप्टो एक्सचेंज में शामिल हों",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V एक्सचेंज : क्रिप्टो मेटावर्स के लिए गेटवे",
                "og:title":
                  "8V एक्सचेंज पर coinFullName फ्यूचर्स ट्रेड करें : coinName/USDT पर्पीचुअल फ्यूचर्स",
                "og:description":
                  "200X मार्जिन तक coinName USDT फ्यूचर्स ट्रेड करें : मेटावर्स ज़ोन के साथ क्रिप्टो एक्सचेंज में शामिल हों",
                "og:image:alt": "8V एक्सचेंज : क्रिप्टो मेटावर्स के लिए गेटवे",
              },
            },
            delivery: {
              title:
                "8V एक्सचेंज पर coinFullName फ्यूचर्स ट्रेड करें | coinName मार्जिन्ड फ्यूचर्स",
              meta_name: {
                description:
                  "100X मार्जिन तक coinName मार्जिन्ड फ्यूचर्स ट्रेड करें |मेटावर्स ज़ोन के साथ क्रिप्टो एक्सचेंज में शामिल हों",
                keywords:
                  "8V एक्सचेंज,coinName USDT फ्यूचर्स, coinFullName फ्यूचर्स, पर्पीचुअल फ्यूचर्स",
                "twitter:title":
                  "8V एक्सचेंज पर coinFullName फ्यूचर्स ट्रेड करें : coinName मार्जिन्ड फ्यूचर्स",
                "twitter:description":
                  "100X मार्जिन तक coinName मार्जिन्ड फ्यूचर्स ट्रेड करें |मेटावर्स ज़ोन के साथ क्रिप्टो एक्सचेंज में शामिल हों",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V एक्सचेंज : क्रिप्टो मेटावर्स के लिए गेटवे",
                "og:title":
                  "8V एक्सचेंज पर coinFullName फ्यूचर्स ट्रेड करें : coinName मार्जिन्ड फ्यूचर्स",
                "og:description":
                  "100X मार्जिन तक coinName मार्जिन्ड फ्यूचर्स ट्रेड करें |मेटावर्स ज़ोन के साथ क्रिप्टो एक्सचेंज में शामिल हों",
                "og:image:alt": "8V एक्सचेंज : क्रिप्टो मेटावर्स के लिए गेटवे",
              },
            },
            exchange: {
              title:
                "8V एक्सचेंज पर coinFullName ट्रेड करें | coinName स्पॉट बाजार चार्ट",
              meta_name: {
                description:
                  "सेकंड में coinName खरीदें | मेटावर्स ज़ोन के साथ क्रिप्टो एक्सचेंज में शामिल हों",
                keywords:
                  "8V एक्सचेंज,coinFullName स्पॉट, coinName चार्ट, डिजिटल मुद्रा कीमत",
                "twitter:title":
                  "8V एक्सचेंज पर coinFullName ट्रेड करें : coinName स्पॉट बाजार चार्ट",
                "twitter:description":
                  "सेकंड में coinName खरीदें : मेटावर्स ज़ोन के साथ क्रिप्टो एक्सचेंज में शामिल हों",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V एक्सचेंज : क्रिप्टो मेटावर्स के लिए गेटवे",
                "og:title":
                  "8V एक्सचेंज पर coinFullName ट्रेड करें : coinName स्पॉट बाजार चार्ट",
                "og:description":
                  "सेकंड में coinName खरीदें : मेटावर्स ज़ोन के साथ क्रिप्टो एक्सचेंज में शामिल हों",
                "og:image:alt": "8V एक्सचेंज : क्रिप्टो मेटावर्स के लिए गेटवे",
              },
            },
          },
        },
        {
          name: "Tiếng Việt",
          value: "vi",
          valueFull: "vi_VN",
          seoMsg: {
            index: {
              title:
                "Sàn giao dịch 8V | Giao dịch Bitcoin & Tiền tệ Metaverse một cách an toàn",
              meta_name: {
                description:
                  "Giao dịch BTC ETH DOGE USDT và hơn 200 loại Tiền kỹ thuật số khác | Giao ngay - Ký quỹ 300X - OTC - Công cụ phái sinh - Staking | Tham gia Sàn giao dịch Tiền điện tử với Metaverse Zone",
                keywords:
                  "Sàn giao dịch 8V, Sàn giao dịch Tiền điện tử, Sàn giao dịch Tiền điện tử Metaverse, Sàn giao dịch Tiền kỹ thuật số",
                "twitter:title": "Sàn giao dịch 8V : Cửa vào Crypto Metaverse",
                "twitter:description":
                  "Giao dịch hàng trăm đồng tiền trên sàn giao dịch tiền điện tử được xây dựng cho Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "Sàn giao dịch 8V : Cửa vào Crypto Metaverse",
                "og:title": "Sàn giao dịch 8V : Cửa vào Crypto Metaverse",
                "og:description":
                  "Giao dịch hàng trăm đồng tiền trên sàn giao dịch tiền điện tử được xây dựng cho Metaverse",
                "og:image:alt": "Sàn giao dịch 8V : Cửa vào Crypto Metaverse",
              },
            },
            metaverse: {
              title:
                "8V Metaverse | Công cụ theo dõi Thị trường Tiền điện tử Metaverse",
              meta_name: {
                description:
                  "Vào Metaverse Zone | Luôn cập nhật Tin tức và Giá tiền kỹ thuật số Metaverse mới nhất",
                keywords:
                  "Sàn giao dịch 8V, Trang Metaverse, Thị trường Metaverse, Sàn giao dịch Metaverse",
                "twitter:title":
                  "Metaverse Zone 8V : Công cụ theo dõi Tiền điện tử Metaverse",
                "twitter:description":
                  "Giao dịch hàng trăm đồng tiền trên sàn giao dịch tiền điện tử được xây dựng cho Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "Sàn giao dịch 8V : Cửa vào Crypto Metaverse",
                "og:title":
                  "Metaverse Zone 8V : Công cụ theo dõi Tiền điện tử Metaverse",
                "og:description":
                  "Giao dịch hàng trăm đồng tiền trên sàn giao dịch tiền điện tử được xây dựng cho Metaverse",
                "og:image:alt": "Sàn giao dịch 8V : Cửa vào Crypto Metaverse",
              },
            },
            finances: {
              title: "Kiếm 8V | Kiếm Tiền điện tử với Staking APY% cao",
              meta_name: {
                description:
                  "Stake BTC ETH DOGE USDT & Nhiều loại Tiền kỹ thuật số khác | Lên tới 22,5% Lợi tức hàng năm | Staking DeFi và Khai thác trên Nền tảng đám mây có sẵn",
                keywords:
                  "8V Earn, Staking Tiền điện tử, Sàn giao dịch Tiền điện tử Metaverse, Staking Tiền kỹ thuật số",
                "twitter:title":
                  "Kiếm 8V : Kiếm Tiền điện tử với Staking APY% cao",
                "twitter:description":
                  "Stake USDT Bitcoin và ETH trên sàn giao dịch tiền điện tử được xây dựng cho Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "Sàn giao dịch 8V : Cửa vào Crypto Metaverse",
                "og:title": "Kiếm 8V : Kiếm Tiền điện tử với Staking APY% cao",
                "og:description":
                  "Stake USDT Bitcoin và ETH trên sàn giao dịch tiền điện tử được xây dựng cho Metaverse",
                "og:image:alt": "Sàn giao dịch 8V : Cửa vào Crypto Metaverse",
              },
            },
            aboutus: {
              title: "Sàn giao dịch 8V | Về chúng tôi",
              meta_name: {
                description:
                  "8V đang dẫn đầu cuộc đua xác định Metaverse, chúng tôi cung cấp nhiều lựa chọn mã token kỹ thuật số và sản phẩm tài chính để người dùng của chúng tôi có thể yên tâm đầu tư",
                keywords:
                  "Sàn giao dịch 8V, trang giới thiệu về chúng tôi, Sàn giao dịch Tiền điện tử Metaverse, Sàn giao dịch Tiền kỹ thuật số",
                "twitter:title": "Sàn giao dịch 8V : Về chúng tôi",
                "twitter:description":
                  "8V đang dẫn đầu cuộc đua xác định Metaverse, chúng tôi cung cấp nhiều lựa chọn mã token kỹ thuật số và sản phẩm tài chính để người dùng của chúng tôi có thể yên tâm đầu tư",
              },
              meta_property: {
                "twitter:image:alt":
                  "Sàn giao dịch 8V : Cửa vào Crypto Metaverse",
                "og:title": "Sàn giao dịch 8V : Về chúng tôi",
                "og:description":
                  "8V đang dẫn đầu cuộc đua xác định Metaverse, chúng tôi cung cấp nhiều lựa chọn mã token kỹ thuật số và sản phẩm tài chính để người dùng của chúng tôi có thể yên tâm đầu tư",
                "og:image:alt": "Sàn giao dịch 8V : Cửa vào Crypto Metaverse",
              },
            },
            "service-agreement": {
              title: "Sàn giao dịch 8V | Thỏa thuận Dịch vụ",
              meta_name: {
                description: "Các điều khoản và Điều kiện",
                keywords:
                  "Sàn giao dịch 8V, Thỏa thuận Dịch vụ, Các điều khoản và Điều kiện, Sàn giao dịch Tiền kỹ thuật số",
                "twitter:title": "Sàn giao dịch 8V : Thỏa thuận Dịch vụ",
                "twitter:description": "Các điều khoản và Điều kiện",
              },
              meta_property: {
                "twitter:image:alt":
                  "Sàn giao dịch 8V : Cửa vào Crypto Metaverse",
                "og:title": "Sàn giao dịch 8V : Thỏa thuận Dịch vụ",
                "og:description": "Các điều khoản và Điều kiện",
                "og:image:alt": "Sàn giao dịch 8V : Cửa vào Crypto Metaverse",
              },
            },
            register: {
              title: "Sàn giao dịch 8V | Đăng ký",
              meta_name: {
                description:
                  "Giao dịch BTC ETH DOGE USDT và hơn 200 loại Tiền kỹ thuật số khác | Giao ngay - Ký quỹ 300X - OTC - Công cụ phái sinh - Staking | Tham gia Sàn giao dịch Tiền điện tử với Metaverse Zone",
                keywords:
                  "Sàn giao dịch 8V, đăng ký, Đăng ký, nhập địa chỉ email",
                "twitter:title": "Sàn giao dịch 8V : Đăng ký",
                "twitter:description":
                  "Giao dịch hàng trăm đồng tiền trên sàn giao dịch tiền điện tử được xây dựng cho Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "Sàn giao dịch 8V : Cửa vào Crypto Metaverse",
                "og:title": "Sàn giao dịch 8V : Đăng ký",
                "og:description":
                  "Giao dịch hàng trăm đồng tiền trên sàn giao dịch tiền điện tử được xây dựng cho Metaverse",
                "og:image:alt": "Sàn giao dịch 8V : Cửa vào Crypto Metaverse",
              },
            },
            login: {
              title: "Sàn giao dịch 8V | Đăng nhập",
              meta_name: {
                description:
                  "Giao dịch BTC ETH DOGE USDT và hơn 200 loại Tiền kỹ thuật số khác | Giao ngay - Ký quỹ 300X - OTC - Công cụ phái sinh - Staking | Tham gia Sàn giao dịch Tiền điện tử với Metaverse Zone",
                keywords:
                  "Sàn giao dịch 8V, Đăng nhập, nhập email, nhập mật khẩu",
                "twitter:title": "Sàn giao dịch 8V : Đăng nhập",
                "twitter:description":
                  "Giao dịch hàng trăm đồng tiền trên sàn giao dịch tiền điện tử được xây dựng cho Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "Sàn giao dịch 8V : Cửa vào Crypto Metaverse",
                "og:title": "Sàn giao dịch 8V : Đăng nhập",
                "og:description":
                  "Giao dịch hàng trăm đồng tiền trên sàn giao dịch tiền điện tử được xây dựng cho Metaverse",
                "og:image:alt": "Sàn giao dịch 8V : Cửa vào Crypto Metaverse",
              },
            },
            forgetPwd: {
              title: "Sàn giao dịch 8V | Đặt Lại Mật Khẩu Đăng Nhập",
              meta_name: {
                description:
                  "Sau khi Đặt lại Mật khẩu Đăng nhập, Rút tiền bị Cấm trong 24 giờ",
                keywords:
                  "Sàn giao dịch 8V, Đặt lại Mật khẩu, Quên mật khẩu, Sàn giao dịch Tiền kỹ thuật số",
                "twitter:title":
                  "Sàn giao dịch 8V : Đặt Lại Mật Khẩu Đăng Nhập",
                "twitter:description":
                  "Sau khi Đặt lại Mật khẩu Đăng nhập, Rút tiền bị Cấm trong 24 giờ",
              },
              meta_property: {
                "twitter:image:alt":
                  "Sàn giao dịch 8V : Cửa vào Crypto Metaverse",
                "og:title": "Sàn giao dịch 8V : Đặt Lại Mật Khẩu Đăng Nhập",
                "og:description":
                  "Sau khi Đặt lại Mật khẩu Đăng nhập, Rút tiền bị Cấm trong 24 giờ",
                "og:image:alt": "Sàn giao dịch 8V : Cửa vào Crypto Metaverse",
              },
            },
            "privacy-policy": {
              title: "Sàn giao dịch 8V | Chính sách Quyền riêng tư",
              meta_name: {
                description: "Sàn giao dịch 8V Chính sách Quyền riêng tư",
                keywords:
                  "Sàn giao dịch 8V, Chính sách Quyền riêng tư, bảo mật thông tin, Sàn giao dịch Tiền kỹ thuật số",
                "twitter:title": "Sàn giao dịch 8V : Chính sách Quyền riêng tư",
                "twitter:description": "Thông báo Bảo mật và Quyền riêng tư",
              },
              meta_property: {
                "twitter:image:alt":
                  "Sàn giao dịch 8V : Cửa vào Crypto Metaverse",
                "og:title": "Sàn giao dịch 8V : Chính sách Quyền riêng tư",
                "og:description": "Thông báo Bảo mật và Quyền riêng tư",
                "og:image:alt": "Sàn giao dịch 8V : Cửa vào Crypto Metaverse",
              },
            },
            "contract-exchange": {
              title:
                "Giao dịch Hợp đồng tương lai coinFullName trên Sàn giao dịch 8V | Hợp đồng tương lai vĩnh viễn coinName/USDT\n\t",
              meta_name: {
                description:
                  "Giao dịch Hợp đồng tương lai coinName USDT với Số tiền ký quỹ lên tới 200 lần | Tham gia Sàn giao dịch Tiền điện tử với Metaverse Zone",
                keywords:
                  "Sàn giao dịch 8V, Hợp đồng tương lai coinFullName USDT, Hợp đồng tương lai Tiền điện tử, Hợp đồng tương lai vĩnh viễn coinName",
                "twitter:title":
                  "Giao dịch Hợp đồng tương lai coinFullName trên Sàn giao dịch 8V : Hợp đồng tương lai vĩnh viễn coinName/USDT",
                "twitter:description":
                  "Giao dịch Hợp đồng tương lai coinName USDT với Số tiền ký quỹ lên tới 200 lần : Tham gia Sàn giao dịch Tiền điện tử với Metaverse Zone",
              },
              meta_property: {
                "twitter:image:alt":
                  "Sàn giao dịch 8V : Cửa vào Crypto Metaverse",
                "og:title":
                  "Giao dịch Hợp đồng tương lai coinFullName trên Sàn giao dịch 8V : Hợp đồng tương lai vĩnh viễn coinName/USDT",
                "og:description":
                  "Giao dịch Hợp đồng tương lai coinName USDT với Số tiền ký quỹ lên tới 200 lần : Tham gia Sàn giao dịch Tiền điện tử với Metaverse Zone",
                "og:image:alt": "Sàn giao dịch 8V : Cửa vào Crypto Metaverse",
              },
            },
            delivery: {
              title:
                "Giao dịch Hợp đồng tương lai coinFullName trên Sàn giao dịch 8V | Hợp đồng tương lai Ký quỹ coinName",
              meta_name: {
                description:
                  "Giao dịch Hợp đồng tương lai Ký quỹ coinName lên tới 100 lần Ký quỹ | Tham gia Sàn giao dịch Tiền điện tử với Metaverse Zone",
                keywords:
                  "Sàn giao dịch 8V, Hợp đồng tương lai coinName USDT, Hợp đồng tương lai coinFullName, Hợp đồng vĩnh viễn",
                "twitter:title":
                  "Giao dịch Hợp đồng tương lai coinFullName trên Sàn giao dịch 8V | Hợp đồng tương lai Ký quỹ coinName",
                "twitter:description":
                  "Giao dịch Hợp đồng tương lai Ký quỹ coinName lên tới 100 lần Ký quỹ | Tham gia Sàn giao dịch Tiền điện tử với Metaverse Zone",
              },
              meta_property: {
                "twitter:image:alt":
                  "Sàn giao dịch 8V : Cửa vào Crypto Metaverse",
                "og:title":
                  "Giao dịch Hợp đồng tương lai coinFullName trên Sàn giao dịch 8V | Hợp đồng tương lai Ký quỹ coinName",
                "og:description":
                  "Giao dịch Hợp đồng tương lai Ký quỹ coinName lên tới 100 lần Ký quỹ | Tham gia Sàn giao dịch Tiền điện tử với Metaverse Zone",
                "og:image:alt": "Sàn giao dịch 8V : Cửa vào Crypto Metaverse",
              },
            },
            exchange: {
              title:
                "Giao dịch coinFullName trên Sàn giao dịch 8V | Biểu đồ Thị trường Giao ngay coinName",
              meta_name: {
                description:
                  "Mua coinName Trong Vài Giây | Tham gia Sàn giao dịch Tiền điện tử với Metaverse Zone",
                keywords:
                  "Sàn giao dịch 8V, coinFullName giao ngay, biểu đồ xu hướng coinName, giá tiền kỹ thuật số",
                "twitter:title":
                  "Giao dịch coinFullName trên Sàn giao dịch 8V : Biểu đồ Thị trường Giao ngay coinName",
                "twitter:description":
                  "Mua coinName Trong Vài Giây : Tham gia Sàn giao dịch Tiền điện tử với Metaverse Zone",
              },
              meta_property: {
                "twitter:image:alt":
                  "Sàn giao dịch 8V : Cửa vào Crypto Metaverse",
                "og:title":
                  "Giao dịch coinFullName trên Sàn giao dịch 8V : Biểu đồ Thị trường Giao ngay coinName",
                "og:description":
                  "Mua coinName Trong Vài Giây : Tham gia Sàn giao dịch Tiền điện tử với Metaverse Zone",
                "og:image:alt": "Sàn giao dịch 8V : Cửa vào Crypto Metaverse",
              },
            },
          },
        },
        {
          name: "Pilipino",
          value: "tl",
          valueFull: "tl_PH",
          seoMsg: {
            index: {
              title:
                "8V Exchange | Ligtas na Mangalakal ng Bitcoin & Metaverse Currency",
              meta_name: {
                description:
                  "Mangalakal ng BTC ETH DOGE USDT at mahigit pang 200 na Digital Currency | Spot - Margin 300X - OTC - Derivatives - Pag-stake | Sumali sa Palitan ng Crypto sa Metaverse Zone",
                keywords:
                  "8V Exchange, Palitan ng Crypto, Palitan ng Metaverse Cryptocurrency, Palitan ng Digital Currency",
                "twitter:title": "8V Exchange : Gateway sa Crypto Metaverse",
                "twitter:description":
                  "Mangalakal ng daan-daang barya sa palitan ng crypto na binuo para sa Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Gateway sa Crypto Metaverse",
                "og:title": "8V Exchange : Gateway sa Crypto Metaverse",
                "og:description":
                  "Mangalakal ng daan-daang barya sa palitan ng crypto na binuo para sa Metaverse",
                "og:image:alt": "8V Exchange : Gateway sa Crypto Metaverse",
              },
            },
            metaverse: {
              title: "8V Metaverse | Tracker ng Merkado sa Crypto ng Metaverse",
              meta_name: {
                description:
                  "Pumasok sa Metaverse Zone | Maging Up to Date sa Pinakabagong Balita at mga Presyo sa Digital Currency na Metaverse",
                keywords:
                  "8V Exchange, Pahina ng Metaverse, Merkado ng Metaverse, Palitan ng Metaverse",
                "twitter:title":
                  "8V Metaverse Zone : Tracker ng Merkado sa Crypto ng Metaverse",
                "twitter:description":
                  "Mangalakal ng daan-daang barya sa palitan ng crypto na binuo para sa Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Gateway sa Crypto Metaverse",
                "og:title":
                  "8V Metaverse Zone : Tracker ng Merkado sa Crypto ng Metaverse",
                "og:description":
                  "Mangalakal ng daan-daang barya sa palitan ng crypto na binuo para sa Metaverse",
                "og:image:alt": "8V Exchange : Gateway sa Crypto Metaverse",
              },
            },
            finances: {
              title:
                "8V Earn | Kumita ng Crypto sa Mataas na APY% na Pag-stake",
              meta_name: {
                description:
                  "Mag-stake ng BTC ETH DOGE USDT at Marami pang Higiti na Digital Currency | Hanggang sa 22.5% Taunang Kita | Pag-stake ng DeFi at Available na Cloud Mining",
                keywords:
                  "8V Earn, Crypto Staking, Metaverse Crypto Exchange, Digital Currrency Staking",
                "twitter:title":
                  "8V Earn : Mataas na APY% na Pag-stake sa 8V Exchange",
                "twitter:description":
                  "Mag-stake ng USDT Bitcoin at ETH sa palitan ng crypto na binuo para sa Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Gateway sa Crypto Metaverse",
                "og:title":
                  "8V Earn : Mataas na APY% na Pag-stake sa 8V Exchange",
                "og:description":
                  "Mag-stake ng USDT Bitcoin at ETH sa palitan ng crypto na binuo para sa Metaverse",
                "og:image:alt": "8V Exchange : Gateway sa Crypto Metaverse",
              },
            },
            aboutus: {
              title: "8V Exchange | Tungkol sa Amin",
              meta_name: {
                description:
                  "Nangunguna ang 8V sa karera ng pagtukoy sa metaverse, nag-aalok kami ng patuloy na lumalagong seleksyon ng mga digital na token at mga produktong pampinansyal upang makapag-invest nang may kapayapaan ng isip ang aming mga gumagamit",
                keywords:
                  "8V Exchange, pahina ng tungkol sa amin, Palitan ng Metaverse Cryptocurrency, Palitan ng Digital Currency",
                "twitter:title": "8V Exchange : Tungkol sa Amin",
                "twitter:description":
                  "Nangunguna ang 8V sa karera ng pagtukoy sa metaverse, nag-aalok kami ng patuloy na lumalagong seleksyon ng mga digital na token at mga produktong pampinansyal upang makapag-invest nang may kapayapaan ng isip ang aming mga gumagamit",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Gateway sa Crypto Metaverse",
                "og:title": "8V Exchange : Tungkol sa Amin",
                "og:description":
                  "Nangunguna ang 8V sa karera ng pagtukoy sa metaverse, nag-aalok kami ng patuloy na lumalagong seleksyon ng mga digital na token at mga produktong pampinansyal upang makapag-invest nang may kapayapaan ng isip ang aming mga gumagamit",
                "og:image:alt": "8V Exchange : Gateway sa Crypto Metaverse",
              },
            },
            "service-agreement": {
              title: "8V Exchange | Kasunduan sa Paggamit",
              meta_name: {
                description: "Mga Tuntunin at Kondisyon",
                keywords:
                  "8V Exchange, kasunduan sa serbisyo, mga tuntunin at kondisyon, Palitan ng Digital Currency",
                "twitter:title": "8V Exchange : Kasunduan sa Paggamit",
                "twitter:description": "Mga Tuntunin at Kondisyon",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Gateway sa Crypto Metaverse",
                "og:title": "8V Exchange : Kasunduan sa Paggamit",
                "og:description": "Mga Tuntunin at Kondisyon",
                "og:image:alt": "8V Exchange : Gateway sa Crypto Metaverse",
              },
            },
            register: {
              title: "8V Exchange | Mag-sign Up",
              meta_name: {
                description:
                  "Mangalakal ng BTC ETH DOGE USDT at mahigit pang 200 na Digital Currency | Spot - Margin 300X - OTC - Derivatives - Pag-stake | Sumali sa Palitan ng Crypto sa Metaverse Zone",
                keywords:
                  "8V Exchange, magrehistro, Mag-sign up, ilagay ang email address",
                "twitter:title": "8V Exchange : Mag-sign Up",
                "twitter:description":
                  "Mangalakal ng daan-daang barya sa palitan ng crypto na binuo para sa Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Gateway sa Crypto Metaverse",
                "og:title": "8V Exchange : Mag-sign Up",
                "og:description":
                  "Mangalakal ng daan-daang barya sa palitan ng crypto na binuo para sa Metaverse",
                "og:image:alt": "8V Exchange : Gateway sa Crypto Metaverse",
              },
            },
            login: {
              title: "8V Exchange | Mag-log In",
              meta_name: {
                description:
                  "Mangalakal ng BTC ETH DOGE USDT at mahigit pang 200 na Digital Currency | Spot - Margin 300X - OTC - Derivatives - Pag-stake | Sumali sa Palitan ng Crypto sa Metaverse Zone",
                keywords:
                  "8V Exchange, Log in, ilagay ang email, ilagay ang password",
                "twitter:title": "8V Exchange : Mag-log In",
                "twitter:description":
                  "Mangalakal ng daan-daang barya sa palitan ng crypto na binuo para sa Metaverse",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Gateway sa Crypto Metaverse",
                "og:title": "8V Exchange : Mag-log In",
                "og:description":
                  "Mangalakal ng daan-daang barya sa palitan ng crypto na binuo para sa Metaverse",
                "og:image:alt": "8V Exchange : Gateway sa Crypto Metaverse",
              },
            },
            forgetPwd: {
              title: "8V Exchange | I-reset ang Password ng Pag-log In",
              meta_name: {
                description:
                  "Matapos I-reset ang Password ng Pag-log In, Hindi Pinahihintulutan ang Pag-withdraw ng 24 Oras",
                keywords:
                  "8V Exchange, Reset Password, Forgot Password, Palitan ng Digital Currency",
                "twitter:title":
                  "8V Exchange : I-reset ang Password ng Pag-log In",
                "twitter:description":
                  "Matapos I-reset ang Password ng Pag-log In, Hindi Pinahihintulutan ang Pag-withdraw ng 24 Oras",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Gateway sa Crypto Metaverse",
                "og:title": "8V Exchange : I-reset ang Password ng Pag-log In",
                "og:description":
                  "Matapos I-reset ang Password ng Pag-log In, Hindi Pinahihintulutan ang Pag-withdraw ng 24 Oras",
                "og:image:alt": "8V Exchange : Gateway sa Crypto Metaverse",
              },
            },
            "privacy-policy": {
              title: "8V Exchange | Patakaran sa Pagkapribado",
              meta_name: {
                description: "8V Exchange Patakaran sa Pagkapribado",
                keywords:
                  "8V Exchange, Patakaran sa Pagkapribado, kaligtasan ng impormasyon, Palitan ng Digital Currency",
                "twitter:title": "8V Exchange : Patakaran sa Pagkapribado",
                "twitter:description":
                  "Kaligtasan ng Impormasyon at Abiso ng Pagkapribado",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Gateway sa Crypto Metaverse",
                "og:title": "8V Exchange : Patakaran sa Pagkapribado",
                "og:description":
                  "Kaligtasan ng Impormasyon at Abiso ng Pagkapribado",
                "og:image:alt": "8V Exchange : Gateway sa Crypto Metaverse",
              },
            },
            "contract-exchange": {
              title:
                "Mangalakal ng coinFullName Futures sa 8V Exchange | coinName/USDT Walang Katapusang Futures",
              meta_name: {
                description:
                  "Mangalakal ng coinName USDT Futures hanggang 200X Margin | Sumali sa Palitan ng Crypto sa Metaverse Zone",
                keywords:
                  "8V Exchange, Futures ng coinFullName USDT, Futures ng Crypto, Walang Katapusang Futures ng coinName",
                "twitter:title":
                  "Mangalakal ng coinFullName Futures sa 8V Exchange : coinName/USDT Walang Katapusang Futures",
                "twitter:description":
                  "Mangalakal ng coinName USDT Futures hanggang 200X Margin : Sumali sa Palitan ng Crypto sa Metaverse Zone",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Gateway sa Crypto Metaverse",
                "og:title":
                  "Mangalakal ng coinFullName Futures sa 8V Exchange : coinName/USDT Walang Katapusang Futures",
                "og:description":
                  "Mangalakal ng coinName USDT Futures hanggang 200X Margin : Sumali sa Palitan ng Crypto sa Metaverse Zone",
                "og:image:alt": "8V Exchange : Gateway sa Crypto Metaverse",
              },
            },
            delivery: {
              title:
                "Mangalakal ng coinFullName Futures sa 8V Exchange | Naka-margin na Futures ng coinName",
              meta_name: {
                description:
                  "Mangalakal ng Naka-margin na Futures ng coinName hanggang 100X Margin | Sumali sa Palitan ng Crypto sa Metaverse Zone",
                keywords:
                  "8V Exchange, Futures ng coinName USDT, Futures ng coinFullName, Walang Katapusang Futures",
                "twitter:title":
                  "Mangalakal ng coinFullName Futures sa 8V Exchange : Naka-margin na Futures ng coinName",
                "twitter:description":
                  "Mangalakal ng Naka-margin na Futures ng coinName hanggang 100X Margin | Sumali sa Palitan ng Crypto sa Metaverse Zone",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Gateway sa Crypto Metaverse",
                "og:title":
                  "Mangalakal ng coinFullName Futures sa 8V Exchange : Naka-margin na Futures ng coinName",
                "og:description":
                  "Mangalakal ng Naka-margin na Futures ng coinName hanggang 100X Margin | Sumali sa Palitan ng Crypto sa Metaverse Zone",
                "og:image:alt": "8V Exchange : Gateway sa Crypto Metaverse",
              },
            },
            exchange: {
              title:
                "Mangalakal ng coinFullName sa 8V Exchange | Tsart ng coinName Spot Market",
              meta_name: {
                description:
                  "Bumili ng coinName Sa Ilang Segundo | Sumali sa Palitan ng Crypto sa Metaverse Zone",
                keywords:
                  "8V Exchange, Spot ng coinFullName, Tsart ng coinName, presyo ng digital currency",
                "twitter:title":
                  "Bumili ng coinName Sa Ilang Segundo : Tsart ng coinName Spot Market",
                "twitter:description":
                  "Bumili ng coinName Sa Ilang Segundo : Sumali sa Palitan ng Crypto sa Metaverse Zone",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Gateway sa Crypto Metaverse",
                "og:title":
                  "Bumili ng coinName Sa Ilang Segundo : Tsart ng coinName Spot Market",
                "og:description":
                  "Bumili ng coinName Sa Ilang Segundo : Sumali sa Palitan ng Crypto sa Metaverse Zone",
                "og:image:alt": "8V Exchange : Gateway sa Crypto Metaverse",
              },
            },
          },
        },
        {
          name: "ภาษาไทย",
          value: "th",
          valueFull: "th_TH",
          seoMsg: {
            index: {
              title:
                "8V Exchange | เริ่มเทรดบิตคอยน์และสกุลเงินดิจิทัลกว่า 200 สกุลเงิน",
              meta_name: {
                description:
                  "ดำเนินการเทรด BTC, ETH, DOGE, USDT และสกุลเงินดิจิทัลอื่นๆ บน 8V Exchange - แพลตฟอร์มที่น่าเชื่อถือสำหรับการเทรดแบบทันที, การเทรดแบบมีหลักประกัน 300 เท่า, OTC และอนุพันธ์. เข้าร่วมเราและเริ่มการเทรดทันที!",
                keywords:
                  "8V Exchange, แพลตฟอร์มซื้อขายคริปโต, แพลตฟอร์มซื้อขายเงินตราเข้ารหัสลับในโลกเมตาเวิร์ส, แพลตฟอร์มซื้อขายสกุลเงินดิจิทัล",
                "twitter:title":
                  "8V Exchange : ประตูสู่โลกเมตาเวิร์สที่มีคริปโต",
                "twitter:description":
                  "เทรดเหรียญนับร้อยในแพลตฟอร์มซื้อขายคริปโตที่สร้างมาเพื่อโลกเมตาเวิร์ส",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : ประตูสู่โลกเมตาเวิร์สที่มีคริปโต",
                "og:title": "8V Exchange : ประตูสู่โลกเมตาเวิร์สที่มีคริปโต",
                "og:description":
                  "เทรดเหรียญนับร้อยในแพลตฟอร์มซื้อขายคริปโตที่สร้างมาเพื่อโลกเมตาเวิร์ส",
                "og:image:alt":
                  "8V Exchange : ประตูสู่โลกเมตาเวิร์สที่มีคริปโต",
              },
            },
            metaverse: {
              title: "8V Metaverse | เครื่องมือติดตามตลาดคริปโตในโลกเมตาเวิร์ส",
              meta_name: {
                description:
                  "เข้าสู่เขตเมตาเวิร์ส | เครื่องมือติดตามตลาดคริปโตในโลกเมตาเวิร์ส | เท่าทันข่าวสารและราคาสกุลเงินดิจิทัลล่าสุดในโลกเมตาเวิร์ส",
                keywords:
                  "8V Exchange, หน้าที่มีข้อมูลเกี่ยวกับเมตาเวิร์ส, ตลาดเมตาเวิร์ส, แพลตฟอร์มซื้อขายในโลกเมตาเวิร์ส",
                "twitter:title":
                  "8V Metaverse Zone : เครื่องมือติดตามตลาดคริปโตในโลกเมตาเวิร์ส",
                "twitter:description":
                  "เทรดเหรียญนับร้อยในแพลตฟอร์มซื้อขายคริปโตที่สร้างมาเพื่อโลกเมตาเวิร์ส",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : ประตูสู่โลกเมตาเวิร์สที่มีคริปโต",
                "og:title":
                  "8V Metaverse Zone : เครื่องมือติดตามตลาดคริปโตในโลกเมตาเวิร์ส",
                "og:description":
                  "เทรดเหรียญนับร้อยในแพลตฟอร์มซื้อขายคริปโตที่สร้างมาเพื่อโลกเมตาเวิร์ส",
                "og:image:alt":
                  "8V Exchange : ประตูสู่โลกเมตาเวิร์สที่มีคริปโต",
              },
            },
            finances: {
              title: "8V Earn | ทำกำไรจากคริปโตด้วยการเดิมพันที่มี %APY สูง",
              meta_name: {
                description:
                  "เดิมพันกับ BTC ETH DOGE USDT และสกุลเงินดิจิทัลอื่นๆ อีกมากมาย| รับผลตอบแทนรายปีสูงสุดถึง 22.5% | มีบริการเดิมพัน DeFi และขุดคริปโตบนคลาวด์",
                keywords:
                  "8V Earn, การเดิมพันกับคริปโต, แพลตฟอร์มซื้อขายคริปโตในโลกเมตาเวิร์ส, การเดินพันสกุลเงินดิจิทัล",
                "twitter:title":
                  "8V Earn : การเดิมพันที่มี %APY สูงบน 8V Exchange",
                "twitter:description":
                  "เดิมพันบิตคอยน์ในสกุลเงิน USDT และ ETH ในแพลตฟอร์มซื้อขายคริปโตที่สร้างมาเพื่อโลกเมตาเวิร์ส",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : ประตูสู่โลกเมตาเวิร์สที่มีคริปโต",
                "og:title":
                  "8V Earn : ทำกำไรกับ 8V: การเดิมพันที่มี %APY สูงบน 8V Exchange",
                "og:description":
                  "เดิมพันบิตคอยน์ในสกุลเงิน USDT และ ETH ในแพลตฟอร์มซื้อขายคริปโตที่สร้างมาเพื่อโลกเมตาเวิร์ส",
                "og:image:alt":
                  "8V Exchange : ประตูสู่โลกเมตาเวิร์สที่มีคริปโต",
              },
            },
            aboutus: {
              title: "8V Exchange | เกี่ยวกับเรา",
              meta_name: {
                description:
                  "8V เป็นผู้นำการแข่งขันเพื่อนิยามโลกเมตาเวิร์ส เรานำเสนอโทเค็นดิจิทัลที่คัดสรรและผลิตภัณฑ์ทางการเงินที่เพิ่มมากขึ้นเรื่อยๆ เพื่อให้ผู้ใช้สามารถลงทุนได้อย่างสบายใจ",
                keywords:
                  "8V Exchange, หน้าเกี่ยวกับเรา, แพลตฟอร์มซื้อขายเงินตราเข้ารหัสลับในโลกเมตาเวิร์ส, แพลตฟอร์มซื้อขายสกุลเงินดิจิทัล",
                "twitter:title": "8V Exchange : เกี่ยวกับเรา",
                "twitter:description":
                  "8V เป็นผู้นำการแข่งขันเพื่อนิยามโลกเมตาเวิร์ส เรานำเสนอโทเค็นดิจิทัลที่คัดสรรและผลิตภัณฑ์ทางการเงินที่เพิ่มมากขึ้นเรื่อยๆ เพื่อให้ผู้ใช้สามารถลงทุนได้อย่างสบายใจ",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : ประตูสู่โลกเมตาเวิร์สที่มีคริปโต",
                "og:title": "8V Exchange : เกี่ยวกับเรา",
                "og:description":
                  "8V เป็นผู้นำการแข่งขันเพื่อนิยามโลกเมตาเวิร์ส เรานำเสนอโทเค็นดิจิทัลที่คัดสรรและผลิตภัณฑ์ทางการเงินที่เพิ่มมากขึ้นเรื่อยๆ เพื่อให้ผู้ใช้สามารถลงทุนได้อย่างสบายใจ",
                "og:image:alt":
                  "8V Exchange : ประตูสู่โลกเมตาเวิร์สที่มีคริปโต",
              },
            },
            "service-agreement": {
              title: "8V Exchange | สัญญาบริการ",
              meta_name: {
                description: "ข้อกำหนดและเงื่อนไข",
                keywords:
                  "8V Exchange, สัญญาบริการ, ข้อกำหนดและเงื่อนไข, แพลตฟอร์มซื้อขายสกุลเงินดิจิทัล",
                "twitter:title": "8V Exchange : สัญญาบริการ",
                "twitter:description": "ข้อกำหนดและเงื่อนไข",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : ประตูสู่โลกเมตาเวิร์สที่มีคริปโต",
                "og:title": "8V Exchange : สัญญาบริการ",
                "og:description": "ข้อกำหนดและเงื่อนไข",
                "og:image:alt":
                  "8V Exchange : ประตูสู่โลกเมตาเวิร์สที่มีคริปโต",
              },
            },
            register: {
              title: "8V Exchange | สมัคร",
              meta_name: {
                description:
                  "เทรด BTC ETH DOGE USDT และสกุลเงินดิจิทัลอีกกว่า 200 สกุลเงิน | การเทรดแบบทันที - แบบมีหลักประกัน 300 เท่า - OTC - อนุพันธ์ - การเดิมพัน | เข้าร่วมแพลตฟอร์มซื้อขายคริปโตที่มีโซนเมตาเวิร์ส",
                keywords: "8V Exchange, ลงทะเบียน, สมัคร, กรอกที่อยู่อีเมล",
                "twitter:title": "8V Exchange : สมัคร",
                "twitter:description":
                  "เทรดเหรียญนับร้อยในแพลตฟอร์มซื้อขายคริปโตที่สร้างมาเพื่อโลกเมตาเวิร์ส",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : ประตูสู่โลกเมตาเวิร์สที่มีคริปโต",
                "og:title": "8V Exchange : สมัคร",
                "og:description":
                  "เทรดเหรียญนับร้อยในแพลตฟอร์มซื้อขายคริปโตที่สร้างมาเพื่อโลกเมตาเวิร์ส",
                "og:image:alt":
                  "8V Exchange : ประตูสู่โลกเมตาเวิร์สที่มีคริปโต",
              },
            },
            login: {
              title: "8V Exchange | เข้าสู่ระบบ",
              meta_name: {
                description:
                  "เทรด BTC ETH DOGE USDT และสกุลเงินดิจิทัลอีกกว่า 200 สกุลเงิน | การเทรดแบบทันที - แบบมีหลักประกัน 300 เท่า - OTC - อนุพันธ์ - การเดิมพัน | เข้าร่วมแพลตฟอร์มซื้อขายคริปโตที่มีโซนเมตาเวิร์ส",
                keywords: "8V Exchange, เข้าสู่ระบบ, กรอกอีเมล, กรอกรหัสผ่าน",
                "twitter:title": "8V Exchange : เข้าสู่ระบบ",
                "twitter:description":
                  "เทรดเหรียญนับร้อยในแพลตฟอร์มซื้อขายคริปโตที่สร้างมาเพื่อโลกเมตาเวิร์ส",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : ประตูสู่โลกเมตาเวิร์สที่มีคริปโต",
                "og:title": "8V Exchange : เข้าสู่ระบบ",
                "og:description":
                  "เทรดเหรียญนับร้อยในแพลตฟอร์มซื้อขายคริปโตที่สร้างมาเพื่อโลกเมตาเวิร์ส",
                "og:image:alt":
                  "8V Exchange : ประตูสู่โลกเมตาเวิร์สที่มีคริปโต",
              },
            },
            forgetPwd: {
              title: "8V Exchange | รีเซ็ตรหัสผ่านที่ใช้เข้าสู่ระบบ",
              meta_name: {
                description:
                  "หลังจากรีเซ็ตรหัสผ่านที่ใช้เข้าสู่ระบบ คุณจะไม่สามารถถอนเงินได้อีก 24 ชั่วโมง",
                keywords:
                  "8V Exchange, รีเซ็ตรหัสผ่าน, ลืมรหัสผ่าน,, แพลตฟอร์มซื้อขายสกุลเงินดิจิทัล",
                "twitter:title":
                  "8V Exchange : รีเซ็ตรหัสผ่านที่ใช้เข้าสู่ระบบ",
                "twitter:description":
                  "หลังจากรีเซ็ตรหัสผ่านที่ใช้เข้าสู่ระบบ คุณจะไม่สามารถถอนเงินได้อีก 24 ชั่วโมง",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : ประตูสู่โลกเมตาเวิร์สที่มีคริปโต",
                "og:title": "8V Exchange : รีเซ็ตรหัสผ่านที่ใช้เข้าสู่ระบบ",
                "og:description":
                  "หลังจากรีเซ็ตรหัสผ่านที่ใช้เข้าสู่ระบบ คุณจะไม่สามารถถอนเงินได้อีก 24 ชั่วโมง",
                "og:image:alt":
                  "8V Exchange : ประตูสู่โลกเมตาเวิร์สที่มีคริปโต",
              },
            },
            "privacy-policy": {
              title: "8V Exchange | นโยบายความเป็นส่วนตัว",
              meta_name: {
                description: "8V Exchange นโยบายความเป็นส่วนตัว",
                keywords:
                  "8V Exchange, นโยบายความเป็นส่วนตัว, ความปลอดภัยของข้อมูล, แพลตฟอร์มซื้อขายสกุลเงินดิจิทัล",
                "twitter:title": "8V Exchange : นโยบายความเป็นส่วนตัว",
                "twitter:description":
                  "ความปลอดภัยของข้อมูลและประกาศความเป็นส่วนตัว",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : ประตูสู่โลกเมตาเวิร์สที่มีคริปโต",
                "og:title": "8V Exchange : นโยบายความเป็นส่วนตัว",
                "og:description":
                  "ความปลอดภัยของข้อมูลและประกาศความเป็นส่วนตัว",
                "og:image:alt":
                  "8V Exchange : ประตูสู่โลกเมตาเวิร์สที่มีคริปโต",
              },
            },
            "contract-exchange": {
              title:
                "ซื้อขาย coinFullName ฟิวเจอร์สบน 8V Exchange | สัญญาซื้อขาย coinName/USDT ล่วงหน้าแบบไม่มีกำหนดเวลา",
              meta_name: {
                description:
                  "เทรด coinName USDT แบบฟิวเจอร์ส โดยใช้หลักประกันสูงถึง 200x เท่า | เข้าร่วมแพลตฟอร์มซื้อขายคริปโตที่มีโซนเมตาเวิร์ส",
                keywords:
                  "8V Exchange, USDT coinFullName ฟิวเจอร์ส, ฟิวเจอร์สของคริปโต, สัญญาซื้อขาย coinName ล่วงหน้าแบบไม่มีกำหนดเวลา",
                "twitter:title":
                  "ซื้อขาย coinFullName ฟิวเจอร์สบน 8V Exchange : สัญญาซื้อขาย coinName/USDT ล่วงหน้าแบบไม่มีกำหนดเวลา",
                "twitter:description":
                  "เทรด coinName USDT แบบฟิวเจอร์ส โดยใช้หลักประกันสูงถึง 200x เท่า : เข้าร่วมแพลตฟอร์มซื้อขายคริปโตที่มีโซนเมตาเวิร์ส",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : ประตูสู่โลกเมตาเวิร์สที่มีคริปโต",
                "og:title":
                  "ซื้อขาย coinFullName ฟิวเจอร์สบน 8V Exchange : สัญญาซื้อขาย coinName/USDT ล่วงหน้าแบบไม่มีกำหนดเวลา",
                "og:description":
                  "เทรด coinName USDT แบบฟิวเจอร์ส โดยใช้หลักประกันสูงถึง 200x เท่า : เข้าร่วมแพลตฟอร์มซื้อขายคริปโตที่มีโซนเมตาเวิร์ส",
                "og:image:alt":
                  "8V Exchange : ประตูสู่โลกเมตาเวิร์สที่มีคริปโต",
              },
            },
            delivery: {
              title:
                "เทรดบิตคอย coinFullName วเจอร์สบน 8V Exchange | coinName ฟิวเจอร์สแบบมีหลักประกันของ coinName",
              meta_name: {
                description:
                  "เทรด coinName ฟิวเจอร์สแบบมีหลักประกันสูงถึง 100x เท่า | เข้าร่วมแพลตฟอร์มซื้อขายคริปโตที่มีโซนเมตาเวิร์ส",
                keywords:
                  "8V Exchange, ฟิวเจอ coinName ตคอยน์ในสกุลเงิน USDT, coinFullName สของบิตคอยน์, สัญญาซื้อขาย ล่วงหน้าแบบไม่มีกำหนดเวลา",
                "twitter:title":
                  "เทรดบิตคอย coinFullName วเจอร์สบน 8V Exchange | coinName ฟิวเจอร์สแบบมีหลักประกันของ coinName",
                "twitter:description":
                  "เทรด coinName ฟิวเจอร์สแบบมีหลักประกันสูงถึง 100x เท่า | เข้าร่วมแพลตฟอร์มซื้อขายคริปโตที่มีโซนเมตาเวิร์ส",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : ประตูสู่โลกเมตาเวิร์สที่มีคริปโต",
                "og:title":
                  "เทรดบิตคอย coinFullName วเจอร์สบน 8V Exchange | coinName ฟิวเจอร์สแบบมีหลักประกันของ coinName",
                "og:description":
                  "เทรด coinName ฟิวเจอร์สแบบมีหลักประกันสูงถึง 100x เท่า | เข้าร่วมแพลตฟอร์มซื้อขายคริปโตที่มีโซนเมตาเวิร์ส",
                "og:image:alt":
                  "8V Exchange : ประตูสู่โลกเมตาเวิร์สที่มีคริปโต",
              },
            },
            exchange: {
              title:
                "เทรด coinFullName บน 8V Exchange | แผนภูมิตลาดซื้อขาย coinName ทันท",
              meta_name: {
                description:
                  "ซื้อ coinName ได้ภายในไม่กี่วินาที | เข้าร่วมแพลตฟอร์มซื้อขายคริปโตที่มีโซนเมตาเวิร์ส",
                keywords:
                  "8V Exchange, การซื้อขาย coinFullName แบบทันที, แผนภูมิ coinName, ราคาสกุลเงินดิจิทัล",
                "twitter:title":
                  "เทรด coinFullName บน 8V Exchange | แผนภูมิตลาดซื้อขาย coinName ทันท",
                "twitter:description":
                  "ซื้อ coinName ได้ภายในไม่กี่วินาที | เข้าร่วมแพลตฟอร์มซื้อขายคริปโตที่มีโซนเมตาเวิร์ส",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : ประตูสู่โลกเมตาเวิร์สที่มีคริปโต",
                "og:title":
                  "เทรด coinFullName บน 8V Exchange | แผนภูมิตลาดซื้อขาย coinName ทันท",
                "og:description":
                  "ซื้อ coinName ได้ภายในไม่กี่วินาที | เข้าร่วมแพลตฟอร์มซื้อขายคริปโตที่มีโซนเมตาเวิร์ส",
                "og:image:alt":
                  "8V Exchange : ประตูสู่โลกเมตาเวิร์สที่มีคริปโต",
              },
            },
          },
        },
        {
          name: "Türk",
          value: "tr",
          valueFull: "tr_TR",
          seoMsg: {
            index: {
              title:
                "8V Borsası | Bitcoin ve Metaverse Para Birimlerinde Güvenle Yatırım Yapın",
              meta_name: {
                description:
                  "BTC ETH DOGE USDT ve 200'den fazla Dijital Para Biriminde yatırım yapın | Spot - 300 Kat Marj - OTC - Türevler - Staking | Metaverse Alanıyla Kripto Borsasına Katılın",
                keywords:
                  "8V Borsası, Kripto Borsası, Metaverse Kripto Para Borsası",
                "twitter:title": "8V Borsası : Kripto Metaverse'e Açılan Kapı",
                "twitter:description":
                  "Metaverse için oluşturulmuş kripto borsasında yüzlerce koinle yatırım yapın",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Borsası : Kripto Metaverse'e Açılan Kapı",
                "og:title": "8V Borsası : Kripto Metaverse'e Açılan Kapı",
                "og:description":
                  "Metaverse için oluşturulmuş kripto borsasında yüzlerce koinle yatırım yapın",
                "og:image:alt": "8V Borsası : Kripto Metaverse'e Açılan Kapı",
              },
            },
            metaverse: {
              title: "8V Metaverse | Metaverse Kripto Piyasası Takibi",
              meta_name: {
                description:
                  "Metaverse Alanına Girin | Metaverse Kripto Piyasası Takibi | En Güncel Metaverse Dijital Para Haberleri ve Fiyatlarından Haberdar Olun",
                keywords:
                  "8V Borsası, Metaverse Sayfası, Metaverse Market, Metaverse Borsası",
                "twitter:title":
                  "8V Metaverse Alanı : Metaverse Kripto İzleyici",
                "twitter:description":
                  "Metaverse için oluşturulmuş kripto borsasında yüzlerce koinle yatırım yapın",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Borsası : Kripto Metaverse'e Açılan Kapı",
                "og:title": "8V Metaverse Alanı : Metaverse Kripto İzleyici",
                "og:description":
                  "Metaverse için oluşturulmuş kripto borsasında yüzlerce koinle yatırım yapın",
                "og:image:alt": "8V Borsası : Kripto Metaverse'e Açılan Kapı",
              },
            },
            finances: {
              title: "8V Kazan | Yüksek APY% Staking ile Kripto Para Kazanın",
              meta_name: {
                description:
                  "BTC ETH DOGE USDT ve Daha Birçok Dijital Parayı Stake Edin | Yıllık %22,5'e Varan Getiri | DeFi Staking ve Bulut Madenciliği Mevcut",
                keywords:
                  "8V Earn, Kripto Staking, Metaverse Kripto Borsası, Dijital Para Staking",
                "twitter:title": "8V Kazan : 8V Borsasında Yüksek APY% Staking",
                "twitter:description":
                  "Metaverse için oluşturulan kripto borsasında USDT Bitcoin ve ETH stake edin",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Borsası : Kripto Metaverse'e Açılan Kapı",
                "og:title": "8V Kazan : 8V Borsasında Yüksek APY% Staking",
                "og:description":
                  "Metaverse için oluşturulan kripto borsasında USDT Bitcoin ve ETH stake edin",
                "og:image:alt": "8V Borsası : Kripto Metaverse'e Açılan Kapı",
              },
            },
            aboutus: {
              title: "8V Borsası | Hakkımızda",
              meta_name: {
                description:
                  "8V, metaverse'i tanımlama yarışına liderlik ediyor, kullanıcılarımızın gönül rahatlığıyla yatırım yapabilmeleri için sürekli büyüyen bir dijital token ve finansal ürün yelpazesi sunuyoruz",
                keywords:
                  "8V Borsası, hakkımızda sayfası, Metaverse Kripto Para Borsası, Dijital Para Borsası",
                "twitter:title": "8V Borsası : Hakkımızda",
                "twitter:description":
                  "8V, metaverse'i tanımlama yarışına liderlik ediyor, kullanıcılarımızın gönül rahatlığıyla yatırım yapabilmeleri için sürekli büyüyen bir dijital token ve finansal ürün yelpazesi sunuyoruz",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Borsası : Kripto Metaverse'e Açılan Kapı",
                "og:title": "8V Borsası : Hakkımızda",
                "og:description":
                  "8V, metaverse'i tanımlama yarışına liderlik ediyor, kullanıcılarımızın gönül rahatlığıyla yatırım yapabilmeleri için sürekli büyüyen bir dijital token ve finansal ürün yelpazesi sunuyoruz",
                "og:image:alt": "8V Borsası : Kripto Metaverse'e Açılan Kapı",
              },
            },
            "service-agreement": {
              title: "8V Borsası | Hizmet Sözleşmesi",
              meta_name: {
                description: "Şartlar ve Koşullar",
                keywords:
                  "8V Borsası, Hizmet Sözleşmesi, Şartlar ve Koşullar, Dijital Para Borsası",
                "twitter:title": "8V Borsası : Hizmet Sözleşmesi",
                "twitter:description": "Şartlar ve Koşullar",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Borsası : Kripto Metaverse'e Açılan Kapı",
                "og:title": "8V Borsası : Hizmet Sözleşmesi",
                "og:description": "Şartlar ve Koşullar",
                "og:image:alt": "8V Borsası : Kripto Metaverse'e Açılan Kapı",
              },
            },
            register: {
              title: "8V Borsası | Kaydol",
              meta_name: {
                description:
                  "BTC ETH DOGE USDT ve 200'den fazla Dijital Para Biriminde yatırım yapın | Spot - 300 Kat Marj - OTC - Türevler - Staking | Metaverse Alanıyla Kripto Borsasına Katılın",
                keywords: "8V Borsası, kayıt ol, Kaydol, e-posta adresini gir",
                "twitter:title": "8V Borsası : Kaydol",
                "twitter:description":
                  "Metaverse için oluşturulmuş kripto borsasında yüzlerce koinle yatırım yapın",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Borsası : Kripto Metaverse'e Açılan Kapı",
                "og:title": "8V Borsası : Kaydol",
                "og:description":
                  "Metaverse için oluşturulmuş kripto borsasında yüzlerce koinle yatırım yapın",
                "og:image:alt": "8V Borsası : Kripto Metaverse'e Açılan Kapı",
              },
            },
            login: {
              title: "8V Borsası | Giriş Yap",
              meta_name: {
                description:
                  "BTC ETH DOGE USDT ve 200'den fazla Dijital Para Biriminde yatırım yapın | Spot - 300 Kat Marj - OTC - Türevler - Staking | Metaverse Alanıyla Kripto Borsasına Katılın",
                keywords: "8V Borsası, Giriş Yap, e-posta gir, şifre gir",
                "twitter:title": "8V Borsası : Giriş Yap",
                "twitter:description":
                  "Metaverse için oluşturulmuş kripto borsasında yüzlerce koinle yatırım yapın",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Borsası : Kripto Metaverse'e Açılan Kapı",
                "og:title": "8V Borsası : Giriş Yap",
                "og:description":
                  "Metaverse için oluşturulmuş kripto borsasında yüzlerce koinle yatırım yapın",
                "og:image:alt": "8V Borsası : Kripto Metaverse'e Açılan Kapı",
              },
            },
            forgetPwd: {
              title: "8V Borsası | Giriş Şifresini Sıfırla",
              meta_name: {
                description:
                  "Oturum Açma Şifresi Sıfırlandıktan Sonra Para Çekme İşlemleri 24 Saat Süreyle Yasaklanır",
                keywords:
                  "8V Borsası, Şifre Sıfırla, Şifremi Unuttum, Dijital Para Borsası",
                "twitter:title": "8V Borsası : Giriş Şifresini Sıfırla",
                "twitter:description":
                  "Oturum Açma Şifresi Sıfırlandıktan Sonra Para Çekme İşlemleri 24 Saat Süreyle Yasaklanır",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Borsası : Kripto Metaverse'e Açılan Kapı",
                "og:title": "8V Borsası : Giriş Şifresini Sıfırla",
                "og:description":
                  "Oturum Açma Şifresi Sıfırlandıktan Sonra Para Çekme İşlemleri 24 Saat Süreyle Yasaklanır",
                "og:image:alt": "8V Borsası : Kripto Metaverse'e Açılan Kapı",
              },
            },
            "privacy-policy": {
              title: "8V Borsası | Gizlilik Politikası",
              meta_name: {
                description: "8V Borsası Gizlilik Politikası",
                keywords:
                  "8V Borsası, Gizlilik Politikası, bi̇lgi̇ güvenli̇ği̇, Dijital Para Borsası",
                "twitter:title": "8V Borsası : Gizlilik Politikası",
                "twitter:description": "Bilgi Güvenliği ve Gizlilik Bildirimi",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Borsası : Kripto Metaverse'e Açılan Kapı",
                "og:title": "8V Borsası : Gizlilik Politikası",
                "og:description": "Bilgi Güvenliği ve Gizlilik Bildirimi",
                "og:image:alt": "8V Borsası : Kripto Metaverse'e Açılan Kapı",
              },
            },
            "contract-exchange": {
              title:
                "8V Borsasında coinFullName Futures İşlemleri Yapın | coinName/USDT Kalıcı Futures İşlemleri",
              meta_name: {
                description:
                  "200 Kat Marja Kadar coinName USDT Futures İşlemleri Yapın | Metaverse Alanı ile Kripto Borsasına Katılın",
                keywords:
                  "8V Borsası, coinFullName USDT Futures İşlemleri, Kripto Futures İşlemleri, coinName Kalıcı Futures İşlemleri",
                "twitter:title":
                  "8V Borsasında coinFullName Futures İşlemleri Yapın : coinName/USDT Kalıcı Futures İşlemleri",
                "twitter:description":
                  "200 Kat Marja Kadar coinName USDT Futures İşlemleri Yapın : Metaverse Alanı ile Kripto Borsasına Katılın",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Borsası : Kripto Metaverse'e Açılan Kapı",
                "og:title":
                  "8V Borsasında coinFullName Futures İşlemleri Yapın : coinName/USDT Kalıcı Futures İşlemleri",
                "og:description":
                  "200 Kat Marja Kadar coinName USDT Futures İşlemleri Yapın : Metaverse Alanı ile Kripto Borsasına Katılın",
                "og:image:alt": "8V Borsası : Kripto Metaverse'e Açılan Kapı",
              },
            },
            delivery: {
              title:
                "8V Borsasında coinFullName Futures İşlemleri Yapın | coinName Marjlı Futures İşlemleri",
              meta_name: {
                description:
                  "100 Kat Marja Kadar coinName Marjlı Futures İşlemleri Yapın | Metaverse Alanı ile Kripto Borsasına Katılın",
                keywords:
                  "8V Borsası, coinName USDT Futures İşlemleri, coinFullName Futures İşlemleri, Kalıcı Futures İşlemleri",
                "twitter:title":
                  "8V Borsasında coinFullName Futures İşlemleri Yapın : coinName Marjlı Futures İşlemleri",
                "twitter:description":
                  "100 Kat Marja Kadar coinName Marjlı Futures İşlemleri Yapın : Metaverse Alanı ile Kripto Borsasına Katılın",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Borsası : Kripto Metaverse'e Açılan Kapı",
                "og:title":
                  "8V Borsasında coinFullName Futures İşlemleri Yapın : coinName Marjlı Futures İşlemleri",
                "og:description":
                  "100 Kat Marja Kadar coinName Marjlı Futures İşlemleri Yapın : Metaverse Alanı ile Kripto Borsasına Katılın",
                "og:image:alt": "8V Borsası : Kripto Metaverse'e Açılan Kapı",
              },
            },
            exchange: {
              title:
                "8V Borsasında coinFullName İşlemleri Yapın | coinName Spot Piyasa Grafiği",
              meta_name: {
                description:
                  "Saniyeler İçinde coinName Satın Alın | Metaverse Alanı ile Kripto Borsasına Katılın",
                keywords:
                  "8V Borsası, coinFullName spot, coinName grafiği, dijital para fiyatı",
                "twitter:title":
                  "8V Borsasında coinFullName İşlemleri Yapın : coinName Spot Piyasa Grafiği",
                "twitter:description":
                  "Saniyeler İçinde coinName Satın Alın : Metaverse Alanı ile Kripto Borsasına Katılın",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Borsası : Kripto Metaverse'e Açılan Kapı",
                "og:title":
                  "8V Borsasında coinFullName İşlemleri Yapın : coinName Spot Piyasa Grafiği",
                "og:description":
                  "Saniyeler İçinde coinName Satın Alın : Metaverse Alanı ile Kripto Borsasına Katılın",
                "og:image:alt": "8V Borsası : Kripto Metaverse'e Açılan Kapı",
              },
            },
          },
        },
        {
          name: "Italiano",
          value: "it",
          valueFull: "it_IT",
          seoMsg: {
            index: {
              title:
                "8V Exchange | Negozia Bitcoin & Valuta del Metaverso in Modo Sicuro",
              meta_name: {
                description:
                  "Scambia BTC ETH DOGE USDT & oltre 200 altre Valute Digitali | Posto - Margine 300X - OTC - Derivati - Puntata | Unisciti allo Scambio di Criptovalute con una Zona di Metaverso",
                keywords:
                  "8V Exchange, Scambio di Criptovalute, Borsa della Criptovaluta del Metaverso, Borsa di Valuta Digitale",
                "twitter:title":
                  "8V Exchange : Ingresso alle Criptovalute del Metaverso",
                "twitter:description":
                  "Negozia centinaia di monete sullo scambio di criptovalute costruita per il Metaverso",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Ingresso alle Criptovalute del Metaverso",
                "og:title":
                  "8V Exchange : Ingresso alle Criptovalute del Metaverso",
                "og:description":
                  "Negozia centinaia di monete sullo scambio di criptovalute costruita per il Metaverso",
                "og:image:alt":
                  "8V Exchange : Ingresso alle Criptovalute del Metaverso",
              },
            },
            metaverse: {
              title:
                "Metaverso 8V | Monitoraggio di Mercato delle Criptovalute del Metaverso",
              meta_name: {
                description:
                  "Entra nella zona del Metaverso | Resta Aggiornato Con le Ultime Novità e Prezzi della Valuta Digitale del Metaverso",
                keywords:
                  "8V Exchange, Pagina del Metaverso, Mercato del Metaverso, Scambio del Metaverso",
                "twitter:title":
                  "Zona 8V del Metaverso: Monitoraggio Criptovalute del Metaverso",
                "twitter:description":
                  "Negozia centinaia di monete sullo scambio di criptovalute costruita per il Metaverso",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Ingresso alle Criptovalute del Metaverso",
                "og:title":
                  "Zona 8V del Metaverso: Monitoraggio Criptovalute del Metaverso",
                "og:description":
                  "Negozia centinaia di monete sullo scambio di criptovalute costruita per il Metaverso",
                "og:image:alt":
                  "8V Exchange : Ingresso alle Criptovalute del Metaverso",
              },
            },
            finances: {
              title:
                "Guadagno 8V | Guadagna Criptovalute con un Puntata ad Alto APY%",
              meta_name: {
                description:
                  "Puntare su BTC ETH DOGE USDT & Molte Altre Valute Digitali | Fino a 22.5% di Rendimento Annuale | Posizionamento DeFi e Cloud Mining Disponibile",
                keywords:
                  "8V Earn, Puntata di Criptovalute, Scambio di Criptovalute del Metaverso, Puntata della Valuta Digitale",
                "twitter:title":
                  "Guadagno 8V: Puntata ad Alto APY% su Scambi 8V",
                "twitter:description":
                  "Puntare su USDT Bitcoin e ETH sullo scambio di criptovalute costruito per il Metaverso",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Ingresso alle Criptovalute del Metaverso",
                "og:title": "Guadagno 8V: Puntata ad Alto APY% su Scambi 8V",
                "og:description":
                  "Puntare su USDT Bitcoin e ETH sullo scambio di criptovalute costruito per il Metaverso",
                "og:image:alt":
                  "8V Exchange : Ingresso alle Criptovalute del Metaverso",
              },
            },
            aboutus: {
              title: "8V Exchange | Su di noi",
              meta_name: {
                description:
                  "8V sta guidando la corsa alla definizione del metaverso, offriamo una selezione sempre crescente di token digitali e prodotti finanziari in modo che i nostri utenti possano investire in tutta tranquillità",
                keywords:
                  "8V Exchange, pagina su di noi, Borsa di Criptovaluta del Metaverso, Borsa di Valuta Digitale",
                "twitter:title": "8V Exchange : Su di noi",
                "twitter:description":
                  "8V sta guidando la corsa alla definizione del metaverso, offriamo una selezione sempre crescente di token digitali e prodotti finanziari in modo che i nostri utenti possano investire in tutta tranquillità",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Ingresso alle Criptovalute del Metaverso",
                "og:title": "8V Exchange : Su di noi",
                "og:description":
                  "8V sta guidando la corsa alla definizione del metaverso, offriamo una selezione sempre crescente di token digitali e prodotti finanziari in modo che i nostri utenti possano investire in tutta tranquillità",
                "og:image:alt":
                  "8V Exchange : Ingresso alle Criptovalute del Metaverso",
              },
            },
            "service-agreement": {
              title: "8V Exchange | Contratto di Servizio",
              meta_name: {
                description: "Termini e Condizioni",
                keywords:
                  "8V Exchange, Contratto di Servizio, Termini e Condizioni, Borsa di Valuta Digitale",
                "twitter:title": "8V Exchange : Contratto di Servizio",
                "twitter:description": "Termini e Condizioni",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Ingresso alle Criptovalute del Metaverso",
                "og:title": "8V Exchange : Contratto di Servizio",
                "og:description": "Termini e Condizioni",
                "og:image:alt":
                  "8V Exchange : Ingresso alle Criptovalute del Metaverso",
              },
            },
            register: {
              title: "8V Exchange | Iscriviti",
              meta_name: {
                description:
                  "Scambia BTC ETH DOGE USDT & oltre 200 altre Valute Digitali | Posto - Margine 300X - OTC - Derivati - Puntata | Unisciti allo Scambio di Criptovalute con una Zona di Metaverso",
                keywords:
                  "8V Exchange, registrazione, Iscriviti, inserisci l'indirizzo email",
                "twitter:title": "8V Exchange : Iscriviti",
                "twitter:description":
                  "Negozia centinaia di monete sullo scambio di criptovalute costruita per il Metaverso",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Ingresso alle Criptovalute del Metaverso",
                "og:title": "8V Exchange : Iscriviti",
                "og:description":
                  "Negozia centinaia di monete sullo scambio di criptovalute costruita per il Metaverso",
                "og:image:alt":
                  "8V Exchange : Ingresso alle Criptovalute del Metaverso",
              },
            },
            login: {
              title: "8V Exchange | Accesso",
              meta_name: {
                description:
                  "Scambia BTC ETH DOGE USDT & oltre 200 altre Valute Digitali | Posto - Margine 300X - OTC - Derivati - Puntata | Unisciti allo Scambio di Criptovalute con una Zona di Metaverso",
                keywords:
                  "8V Exchange, Accesso, inserisci l'email, inserisci la password",
                "twitter:title": "8V Exchange : Accesso",
                "twitter:description":
                  "Negozia centinaia di monete sullo scambio di criptovalute costruita per il Metaverso",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Ingresso alle Criptovalute del Metaverso",
                "og:title": "8V Exchange : Accesso",
                "og:description":
                  "Negozia centinaia di monete sullo scambio di criptovalute costruita per il Metaverso",
                "og:image:alt":
                  "8V Exchange : Ingresso alle Criptovalute del Metaverso",
              },
            },
            forgetPwd: {
              title: "8V Exchange | Resetta la Password per l'Accesso",
              meta_name: {
                description:
                  "Dopo la Reimpostazione della Password di Accesso, i Prelievi sono Vietati per 24 Ore",
                keywords:
                  "8V Exchange, Resetta la password, Ho Dimenticato la Password, Borsa di Valuta Digitale",
                "twitter:title":
                  "8V Exchange : Resetta la Password per l'Accesso",
                "twitter:description":
                  "Dopo la Reimpostazione della Password di Accesso, i Prelievi sono Vietati per 24 Ore",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Ingresso alle Criptovalute del Metaverso",
                "og:title": "8V Exchange : Resetta la Password per l'Accesso",
                "og:description":
                  "Dopo la Reimpostazione della Password di Accesso, i Prelievi sono Vietati per 24 Ore",
                "og:image:alt":
                  "8V Exchange : Ingresso alle Criptovalute del Metaverso",
              },
            },
            "privacy-policy": {
              title: "8V Exchange | Informativa sulla Privacy",
              meta_name: {
                description: "8V Exchange Informativa sulla Privacy",
                keywords:
                  "8V Exchange, Informativa sulla Privacy, sicurezza della privacy, Borsa di Valuta Digitale",
                "twitter:title": "8V Exchange : Informativa sulla Privacy",
                "twitter:description":
                  "Avviso sulle Informazioni di Sicurezza e Privacy",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Ingresso alle Criptovalute del Metaverso",
                "og:title": "8V Exchange : Informativa sulla Privacy",
                "og:description":
                  "Avviso sulle Informazioni di Sicurezza e Privacy",
                "og:image:alt":
                  "8V Exchange : Ingresso alle Criptovalute del Metaverso",
              },
            },
            "contract-exchange": {
              title:
                "Negozia i Futures di coinFullName sullo Scambio 8V | Futures coinName/USDT Perpetui",
              meta_name: {
                description:
                  "Negozia i Futures di coinName USDT fino a un Margine di 200x | Unisciti allo Scambio di Criptovalute con una Zona di Metaverso",
                keywords:
                  "8V Exchange, Futures di coinFullName USDT, Futures di Criptovalute, Futures di coinName Perpetui",
                "twitter:title":
                  "Negozia i Futures di coinFullName sullo Scambio 8V : Futures coinName/USDT Perpetui",
                "twitter:description":
                  "Negozia i Futures di coinName USDT fino a un Margine di 200x : Unisciti allo Scambio di Criptovalute con una Zona di Metaverso",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Ingresso alle Criptovalute del Metaverso",
                "og:title":
                  "Negozia i Futures di coinFullName sullo Scambio 8V : Futures coinName/USDT Perpetui",
                "og:description":
                  "Negozia i Futures di coinName USDT fino a un Margine di 200x : Unisciti allo Scambio di Criptovalute con una Zona di Metaverso",
                "og:image:alt":
                  "8V Exchange : Ingresso alle Criptovalute del Metaverso",
              },
            },
            delivery: {
              title:
                "Negozia futures di coinFullName sullo Scambio 8V | Futures coinName Marginati",
              meta_name: {
                description:
                  "Negozia Futures Marginati coinName fino a un Margine di 100x | Unisciti allo Scambio di Criptovalute con una Zona di Metaverso",
                keywords:
                  "8V Exchange, Futures di coinName USDT, Futures di coinFullName, Futures Perpetui",
                "twitter:title":
                  "Negozia futures di coinFullName sullo Scambio 8V : Futures coinName Marginati",
                "twitter:description":
                  "Negozia Futures Marginati coinName fino a un Margine di 100x : Unisciti allo Scambio di Criptovalute con una Zona di Metaverso",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Ingresso alle Criptovalute del Metaverso",
                "og:title":
                  "Negozia futures di coinFullName sullo Scambio 8V : Futures coinName Marginati",
                "og:description":
                  "Negozia Futures Marginati coinName fino a un Margine di 100x : Unisciti allo Scambio di Criptovalute con una Zona di Metaverso",
                "og:image:alt":
                  "8V Exchange : Ingresso alle Criptovalute del Metaverso",
              },
            },
            exchange: {
              title:
                "Negozia coinFullName sullo Scambio 8V | Grafico di Posizionamento nel Mercato coinName",
              meta_name: {
                description:
                  "Compra coinName In Secondi | Unisciti allo Scambio di Criptovalute con una Zona di Metaverso",
                keywords:
                  "8V Exchange, Posizione coinFullName, Grafico coinName, prezzo valuta digitale",
                "twitter:title":
                  "Negozia coinFullName sullo Scambio 8V : Grafico di Posizionamento nel Mercato coinName",
                "twitter:description":
                  "Compra coinName In Secondi : Unisciti allo Scambio di Criptovalute con una Zona di Metaverso",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V Exchange : Ingresso alle Criptovalute del Metaverso",
                "og:title":
                  "Negozia coinFullName sullo Scambio 8V : Grafico di Posizionamento nel Mercato coinName",
                "og:description":
                  "Compra coinName In Secondi : Unisciti allo Scambio di Criptovalute con una Zona di Metaverso",
                "og:image:alt":
                  "8V Exchange : Ingresso alle Criptovalute del Metaverso",
              },
            },
          },
        },
        {
          name: "bahasa Indonesia",
          value: "id",
          valueFull: "id_ID",
          seoMsg: {
            index: {
              title:
                "Bursa 8V | Perdagangkan Bitcoin dan Mata Uang Metaverse dengan Aman",
              meta_name: {
                description:
                  "Perdagangkan BTC ETH DOGE USDT & lebih dari 200 Mata Uang Digital lainnya | Spot - Margin 300X - OTC - Derivatif - Pertaruhan | Bergabunglah dengan Bursa Kripto bersama Zona Metaverse",
                keywords:
                  "Bursa 8V, Bursa Kripto, Bursa Mata Uang Kripto Metaverse, Bursa Mata Uang Digital",
                "twitter:title": "Bursa 8V : Gateway ke Metaverse Kripto",
                "twitter:description":
                  "Perdagangkan ratusan koin di bursa kripto yang diciptakan untuk Metaverse",
              },
              meta_property: {
                "twitter:image:alt": "Bursa 8V : Gateway ke Metaverse Kripto",
                "og:title": "Bursa 8V : Gateway ke Metaverse Kripto",
                "og:description":
                  "Perdagangkan ratusan koin di bursa kripto yang diciptakan untuk Metaverse",
                "og:image:alt": "Bursa 8V : Gateway ke Metaverse Kripto",
              },
            },
            metaverse: {
              title: "Metaverse 8V | Pelacak Pasar Kripto Metaverse",
              meta_name: {
                description:
                  "Masuk ke Zona Metaverse | Simak Terus Berita dan Harga Mata Uang Digital Metaverse Terbaru",
                keywords:
                  "Bursa 8V, Halaman Metaverse, Pasar Metaverse, Bursa Metaverse",
                "twitter:title": "Zona Metaverse 8V : Pelacak Kripto Metaverse",
                "twitter:description":
                  "Perdagangkan ratusan koin di bursa kripto yang diciptakan untuk Metaverse",
              },
              meta_property: {
                "twitter:image:alt": "Bursa 8V : Gateway ke Metaverse Kripto",
                "og:title": "Zona Metaverse 8V : Pelacak Kripto Metaverse",
                "og:description":
                  "Perdagangkan ratusan koin di bursa kripto yang diciptakan untuk Metaverse",
                "og:image:alt": "Bursa 8V : Gateway ke Metaverse Kripto",
              },
            },
            finances: {
              title:
                "Penghasilan 8V | Hasilkan Kripto dengan Pertaruhan APY% Tinggi",
              meta_name: {
                description:
                  "Pertaruhkan BTC ETH DOGE USDT & Berbagai Mata Uang Digital Lainnya | Hingga 22,5% Hasil Tahunan | Tersedia DeFi Pertaruhan dan Penambangan Cloud",
                keywords:
                  "Penghasilan 8V, Pertaruhan Kripto, Bursa Kripto Metaverse, Pertaruhan Mata Uang Digital",
                "twitter:title":
                  "Penghasilan 8V: Pertaruhan APY% tinggi di Bursa 8V",
                "twitter:description":
                  "Pertaruhkan Bitcoin USDT dan ETH di bursa kripto yang diciptakan untuk Metaverse",
              },
              meta_property: {
                "twitter:image:alt": "Bursa 8V : Gateway ke Metaverse Kripto",
                "og:title":
                  "Penghasilan 8V: Pertaruhan APY% tinggi di Bursa 8V",
                "og:description":
                  "Pertaruhkan Bitcoin USDT dan ETH di bursa kripto yang diciptakan untuk Metaverse",
                "og:image:alt": "Bursa 8V : Gateway ke Metaverse Kripto",
              },
            },
            aboutus: {
              title: "Bursa 8V | Tentang Kami",
              meta_name: {
                description:
                  "8V adalah yang terdepan dalam menetapkan metaverse, kami menawarkan pilihan token digital dan produk keuangan yang terus berkembang sehingga pengguna kami selalu merasa aman saat berinvestasi",
                keywords:
                  "Bursa 8V, halaman tentang kami, Bursa Mata Uang Kripto Metaverse, Bursa Mata Uang Digital",
                "twitter:title": "Bursa 8V : Tentang Kami",
                "twitter:description":
                  "8V adalah yang terdepan dalam menetapkan metaverse, kami menawarkan pilihan token digital dan produk keuangan yang terus berkembang sehingga pengguna kami selalu merasa aman saat berinvestasi",
              },
              meta_property: {
                "twitter:image:alt": "Bursa 8V : Gateway ke Metaverse Kripto",
                "og:title": "Bursa 8V : Tentang Kami",
                "og:description":
                  "8V adalah yang terdepan dalam menetapkan metaverse, kami menawarkan pilihan token digital dan produk keuangan yang terus berkembang sehingga pengguna kami selalu merasa aman saat berinvestasi",
                "og:image:alt": "Bursa 8V : Gateway ke Metaverse Kripto",
              },
            },
            "service-agreement": {
              title: "Bursa 8V | Perjanjian Layanan",
              meta_name: {
                description: "Syarat dan Ketentuan",
                keywords:
                  "Bursa 8V, Perjanjian Layanan, Syarat dan Ketentuan, Bursa Mata Uang Digital",
                "twitter:title": "Bursa 8V : Perjanjian Layanan",
                "twitter:description": "Syarat dan Ketentuan",
              },
              meta_property: {
                "twitter:image:alt": "Bursa 8V : Gateway ke Metaverse Kripto",
                "og:title": "Bursa 8V : Perjanjian Layanan",
                "og:description": "Syarat dan Ketentuan",
                "og:image:alt": "Bursa 8V : Gateway ke Metaverse Kripto",
              },
            },
            register: {
              title:
                "Bergabung dan Mulai Perdagangan di Bursa 8V | Daftar Sekarang",
              meta_name: {
                description:
                  "Mulai perdagangan BTC, ETH, DOGE, USDT, dan lebih dari 200 mata uang digital di Bursa 8V. Nikmati Spot, Margin 300X, OTC, Derivatif dan Pertaruhan. Daftar sekarang!",
                keywords: "Bursa 8V, register, Daftar, masukkan alamat email",
                "twitter:title": "Bursa 8V : Daftar",
                "twitter:description":
                  "Perdagangkan ratusan koin di bursa kripto yang diciptakan untuk Metaverse",
              },
              meta_property: {
                "twitter:image:alt": "Bursa 8V : Gateway ke Metaverse Kripto",
                "og:title": "Bursa 8V : Daftar",
                "og:description":
                  "Perdagangkan ratusan koin di bursa kripto yang diciptakan untuk Metaverse",
                "og:image:alt": "Bursa 8V : Gateway ke Metaverse Kripto",
              },
            },
            login: {
              title: "Bursa 8V | Masuk",
              meta_name: {
                description:
                  "Perdagangkan BTC ETH DOGE USDT & lebih dari 200 Mata Uang Digital lainnya | Spot - Margin 300X - OTC - Derivatif - Pertaruhan | Bergabunglah dengan Bursa Kripto bersama Zona Metaverse",
                keywords:
                  "Bursa 8V, Masuk, masukkan email, masukkan kata sandi",
                "twitter:title": "Bursa 8V : Masuk",
                "twitter:description":
                  "Perdagangkan ratusan koin di bursa kripto yang diciptakan untuk Metaverse",
              },
              meta_property: {
                "twitter:image:alt": "Bursa 8V : Gateway ke Metaverse Kripto",
                "og:title": "Bursa 8V : Masuk",
                "og:description":
                  "Perdagangkan ratusan koin di bursa kripto yang diciptakan untuk Metaverse",
                "og:image:alt": "Bursa 8V : Gateway ke Metaverse Kripto",
              },
            },
            forgetPwd: {
              title: "Bursa 8V | Atur Ulang Kata Sandi Masuk",
              meta_name: {
                description:
                  "Setelah Mengatur Ulang Kata Sandi Masuk, Penarikan Tidak Bisa Dilakukan Selama 24 Jam",
                keywords:
                  "Bursa 8V, Reset Password, Forgot Password, Bursa Mata Uang Digital",
                "twitter:title": "Bursa 8V : Atur Ulang Kata Sandi Masuk",
                "twitter:description":
                  "Setelah Mengatur Ulang Kata Sandi Masuk, Penarikan Tidak Bisa Dilakukan Selama 24 Jam",
              },
              meta_property: {
                "twitter:image:alt": "Bursa 8V : Gateway ke Metaverse Kripto",
                "og:title": "Bursa 8V : Atur Ulang Kata Sandi Masuk",
                "og:description":
                  "Setelah Mengatur Ulang Kata Sandi Masuk, Penarikan Tidak Bisa Dilakukan Selama 24 Jam",
                "og:image:alt": "Bursa 8V : Gateway ke Metaverse Kripto",
              },
            },
            "privacy-policy": {
              title: "Bursa 8V | Kebijakan Privasi",
              meta_name: {
                description: "Bursa 8V Kebijakan Privasi",
                keywords:
                  "Bursa 8V, Kebijakan Privasi, keamanan informasi, Bursa Mata Uang Digital",
                "twitter:title": "Bursa 8V : Kebijakan Privasi",
                "twitter:description":
                  "Keamanan Informasi dan Pemberitahuan Privasi",
              },
              meta_property: {
                "twitter:image:alt": "Bursa 8V : Gateway ke Metaverse Kripto",
                "og:title": "Bursa 8V : Kebijakan Privasi",
                "og:description":
                  "Keamanan Informasi dan Pemberitahuan Privasi",
                "og:image:alt": "Bursa 8V : Gateway ke Metaverse Kripto",
              },
            },
            "contract-exchange": {
              title:
                "Perdagangkan Kontrak Berjangka coinFullName di Bursa 8V | Kontrak Berjangka Perpetual coinName/USDT",
              meta_name: {
                description:
                  "Perdagangkan Kontrak Berjangka coinName USDT hingga 200X Margin | Bergabunglah dengan Bursa Kripto bersama Zona Metaverse",
                keywords:
                  "Bursa 8V, Kontrak Berjangka USDT coinFullName, Kontrak Berjangka Kripto, Kontrak Berjangka Perpetual coinName",
                "twitter:title":
                  "Perdagangkan Kontrak Berjangka coinFullName di Bursa 8V : Kontrak Berjangka Perpetual coinName/USDT",
                "twitter:description":
                  "Perdagangkan Kontrak Berjangka coinName USDT hingga 200X Margin : Bergabunglah dengan Bursa Kripto bersama Zona Metaverse",
              },
              meta_property: {
                "twitter:image:alt": "Bursa 8V : Gateway ke Metaverse Kripto",
                "og:title":
                  "Perdagangkan Kontrak Berjangka coinFullName di Bursa 8V : Kontrak Berjangka Perpetual coinName/USDT",
                "og:description":
                  "Perdagangkan Kontrak Berjangka coinName USDT hingga 200X Margin : Bergabunglah dengan Bursa Kripto bersama Zona Metaverse",
                "og:image:alt": "Bursa 8V : Gateway ke Metaverse Kripto",
              },
            },
            delivery: {
              title:
                "Perdagangkan Kontrak Berjangka coinFullName di Bursa 8V | Kontrak Berjangka Bermargin coinName",
              meta_name: {
                description:
                  "Perdagangkan Kontrak Berjangka Bermargin coinName hingga 100X Margin | Bergabunglah dengan Bursa Kripto bersama Zona Metaverse",
                keywords:
                  "Bursa 8V, Kontrak Berjangka coinName USDT, Kontrak Berjangka coinFullName, Kontrak Berjangka Perpetual",
                "twitter:title":
                  "Perdagangkan Kontrak Berjangka coinFullName di Bursa 8V : Kontrak Berjangka Bermargin coinName",
                "twitter:description":
                  "Perdagangkan Kontrak Berjangka Bermargin coinName hingga 100X Margin : Bergabunglah dengan Bursa Kripto bersama Zona Metaverse",
              },
              meta_property: {
                "twitter:image:alt": "Bursa 8V : Gateway ke Metaverse Kripto",
                "og:title":
                  "Perdagangkan Kontrak Berjangka coinFullName di Bursa 8V : Kontrak Berjangka Bermargin coinName",
                "og:description":
                  "Perdagangkan Kontrak Berjangka Bermargin coinName hingga 100X Margin : Bergabunglah dengan Bursa Kripto bersama Zona Metaverse",
                "og:image:alt": "Bursa 8V : Gateway ke Metaverse Kripto",
              },
            },
            exchange: {
              title:
                "Perdagangkan coinFullName di Bursa 8V | Bagan Pasar coinName",
              meta_name: {
                description:
                  "Beli coinName Dalam Hitungan Detik | Bergabunglah dengan Bursa Kripto bersama Zona Metaverse",
                keywords:
                  "Bursa 8V, Spot coinFullName, bagan coinName, harga mata uang digital",
                "twitter:title":
                  "Perdagangkan coinFullName di Bursa 8V : Bagan Pasar coinName",
                "twitter:description":
                  "Beli coinName Dalam Hitungan Detik : Bergabunglah dengan Bursa Kripto bersama Zona Metaverse",
              },
              meta_property: {
                "twitter:image:alt": "Bursa 8V : Gateway ke Metaverse Kripto",
                "og:title":
                  "Perdagangkan coinFullName di Bursa 8V : Bagan Pasar coinName",
                "og:description":
                  "Beli coinName Dalam Hitungan Detik : Bergabunglah dengan Bursa Kripto bersama Zona Metaverse",
                "og:image:alt": "Bursa 8V : Gateway ke Metaverse Kripto",
              },
            },
          },
        },
        {
          name: "عربي",
          value: "ar",
          valueFull: "ar_AR",
          seoMsg: {
            index: {
              title: "ﻦﻣﺁ ﻞﻜﺸﺑ ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻠﻤﻋﻭ ﻦﻳﻮﻜﺘﻴﺒﻟا ﻝﻭاﺪﺗ | 8V ﺔﺻﺭﻮﺑ",
              // title: "8V | ﻦﻣﺁ ﻞﻜﺸﺑ ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻠﻤﻋﻭ ﻦﻳﻮﻜﺘﻴﺒﻟا ﻝﻭاﺪﺗ  ﺔﺻﺭﻮﺑ",
              meta_name: {
                description:
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻘﻄﻨﻣ ﻊﻣ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻰﻟﺇ ﻢﻀﻧا | (ﻝاﻮﻣﻷﻟ) ﻝﺎﻔﻗﻹا ﻭﺃ ﺖﻴﺒﺜﺘﻟا - ﺔﻘﺘﺸﻤﻟا ﺕاﻭﺩﻷا - (OTC) ﺔﺻﺭﻮﻟا ﺝﺭﺎﺧ ﻝﻭاﺪﺘﻟا - 300X ﺶﻣﺎﻫ - ﻱﺭﻮﻔﻟا ﻝﻭاﺪﺘﻟا | ﻯﺮﺧﺃ ﺔﻴﻤﻗﺭ ﺔﻠﻤﻋ 200 ﻦﻣ ﺮﺜﻛﺃﻭ ﺮﺜﻴﺗﻭ ﻦﻳﻮﻜﺟﺩﻭ ﻡﻮﻳﺮﺜﻳﻹاﻭ ﻦﻳﻮﻜﺘﻴﺒﻟا ﻝﻭاﺪﺗ",
                keywords:
                  "8V ﺔﺻﺭﻮﺑ, ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ, ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﻲﻓ, ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ",
                "twitter:title": "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : 8V ﺔﺻﺭﻮﺑ",
                // "twitter:title": "8V: ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ  ﺔﺻﺭﻮﺑ",
                "twitter:description":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻠﻟ ﺔﻤﻤﺼﻤﻟا ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻲﻓ ﺕﻼﻤﻌﻟا ﺕﺎﺌﻣ ﻝﻭاﺪﺗ",
              },
              meta_property: {
                "twitter:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : 8V ﺔﺻﺭﻮﺑ",
                // "8V: ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ ﺔﺻﺭﻮﺑ" ,
                "og:title": "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : 8V ﺔﺻﺭﻮﺑ",
                // "og:title": "8V: ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ  ﺔﺻﺭﻮﺑ",
                "og:description":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻠﻟ ﺔﻤﻤﺼﻤﻟا ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻲﻓ ﺕﻼﻤﻌﻟا ﺕﺎﺌﻣ ﻝﻭاﺪﺗ",
                "og:image:alt": "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : 8V ﺔﺻﺭﻮﺑ",
                // "og:image:alt": "8V: ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ  ﺔﺻﺭﻮﺑ" ,
              },
            },
            metaverse: {
              title: "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﻲﻓ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﻕﻮﺳ ﻊﺒﺘﺗ ﺓاﺩﺃ | 8V ﺱﺮﻴﻓﺎﺘﻴﻣ",
              meta_name: {
                description:
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﻲﻓ ﺔﻴﻤﻗﺮﻟا ﺕﻼﻤﻌﻟا ﺭﺎﻌﺳﺃﻭ ﺭﺎﺒﺧﺃ ﺙﺪﺣﺄﺑ ﻉﻼﻃا ﻰﻠﻋ ﻖﺑا | ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻘﻄﻨﻣ ﻞﺧﺩا",
                keywords:
                  "8V ﺔﺻﺭﻮﺑ, ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﺤﻔﺻ, ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﻕﻮﺳ, ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﺻﺭﻮﺑ",
                "twitter:title":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﻲﻓ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﻊﺒﺘﺗ ﺓاﺩﺃ : 8V ﺱﺮﻴﻓﺎﺘﻴﻣ ﺔﻘﻄﻨﻣ",
                "twitter:description":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻠﻟ ﺔﻤﻤﺼﻤﻟا ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻲﻓ ﺕﻼﻤﻌﻟا ﺕﺎﺌﻣ ﻝﻭاﺪﺗ",
              },
              meta_property: {
                "twitter:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : 8V ﺔﺻﺭﻮﺑ",
                "og:title":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﻲﻓ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﻊﺒﺘﺗ ﺓاﺩﺃ : 8V ﺱﺮﻴﻓﺎﺘﻴﻣ ﺔﻘﻄﻨﻣ",
                "og:description":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻠﻟ ﺔﻤﻤﺼﻤﻟا ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻲﻓ ﺕﻼﻤﻌﻟا ﺕﺎﺌﻣ ﻝﻭاﺪﺗ",
                "og:image:alt": "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : 8V ﺔﺻﺭﻮﺑ",
              },
            },
            finances: {
              title:
                "ﺔﻌﻔﺗﺮﻣ (%) ﺔﻳﻮﻨﺳ ﺪﺋﺎﻋ ﺔﺒﺴﻨﺑ ﻝاﻮﻣﻷا ﻝﺎﻔﻗﺇ/ﺖﻴﺒﺜﺗ ﻊﻣ ﺓﺮﻔﺸﻣ ﺕﻼﻤﻋ ﺢﺑﺭا | 8V ﺢﺑﺭ",
              meta_name: {
                description:
                  ".ﻲﺑﺎﺤﺴﻟا ﻦﻳﺪﻌﺘﻟاﻭ ﻱﺰﻛﺮﻣﻻﻟا ﻞﻳﻮﻤﺘﻟا ﻝﺎﻔﻗﺇ/ﺖﻴﺒﺜﺗ ﺮﻓﻮﺘﻳ | %22.5 ﻰﻟﺇ ﻞﺼﺗ ﺔﺒﺴﻨﺑ ﻱﻮﻨﺳ ﺪﺋﺎﻋ | ﻯﺮﺧﻷا ﺔﻴﻤﻗﺮﻟا ﺕﻼﻤﻌﻟا ﻦﻣ ﺎﻫﺮﻴﻏ ﺮﻴﺜﻜﻟاﻭ ﺮﺜﻴﺗﻭ ﻦﻳﻮﻜﺟﺩﻭ ﻡﻮﻳﺮﺜﻳﻹاﻭ ﻦﻳﻮﻜﺘﻴﺒﻟا ﺕﻼﻤﻋ ﻝﺎﻔﻗﺇ/ﺖﻴﺒﺜﺗ",
                keywords:
                  "8V ﺢﺑﺭ, ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﻝﺎﻔﻗﺇ/ﺖﻴﺒﺜﺗ, ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ, ﺔﻴﻤﻗﺮﻟا ﺕﻼﻤﻌﻟا ﻝﺎﻔﻗﺇ/ﺖﻴﺒﺜﺗ",
                "twitter:title":
                  "8V ﺔﺻﺭﻮﺑ ﻲﻓ ﺔﻌﻔﺗﺮﻣ (%) ﺔﻳﻮﻨﺳ ﺪﺋﺎﻋ ﺔﺒﺴﻨﺑ ﻝاﻮﻣﻷا ﻝﺎﻔﻗﺇ/ﺖﻴﺒﺜﺗ : 8V ﺢﺑﺭ",
                "twitter:description":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻠﻟ ﺔﻤﻤﺼﻤﻟا ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻲﻓ ﺮﺜﻴﺗﻭ ﻦﻳﻮﻜﺘﻴﺒﻟاﻭ ﻡﻮﻳﺮﺜﻳﻹا ﻝﻭاﺪﺗ ﻝﺎﻔﻗﺇ/ﺖﻴﺒﺜﺗ",
              },
              meta_property: {
                "twitter:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : 8V ﺔﺻﺭﻮﺑ",
                "og:title":
                  "8V ﺔﺻﺭﻮﺑ ﻲﻓ ﺔﻌﻔﺗﺮﻣ (%) ﺔﻳﻮﻨﺳ ﺪﺋﺎﻋ ﺔﺒﺴﻨﺑ ﻝاﻮﻣﻷا ﻝﺎﻔﻗﺇ/ﺖﻴﺒﺜﺗ : 8V ﺢﺑﺭ",
                "og:description":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻠﻟ ﺔﻤﻤﺼﻤﻟا ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻲﻓ ﺮﺜﻴﺗﻭ ﻦﻳﻮﻜﺘﻴﺒﻟاﻭ ﻡﻮﻳﺮﺜﻳﻹا ﻝﻭاﺪﺗ ﻝﺎﻔﻗﺇ/ﺖﻴﺒﺜﺗ",
                "og:image:alt": "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : 8V ﺔﺻﺭﻮﺑ",
              },
            },
            aboutus: {
              title: "ﺎﻨﻋ ﺓﺬﺒﻧ | 8V ﺔﺻﺭﻮﺑ",
              meta_name: {
                description:
                  "ﻝﺎﺑ ﺔﺣاﺭﻭ ﺔﻴﺤﻳﺭﺄﺑ ﺭﺎﻤﺜﺘﺳﻻا ﻦﻣ ﺎﻨﻳﺪﻟ ﻥﻮﻣﺪﺨﺘﺴﻤﻟا ﻦﻜﻤﺘﻳ ﻰﺘﺣ ﺔﻴﻟﺎﻤﻟا ﺕﺎﺠﺘﻨﻤﻟاﻭ ﺔﻴﻤﻗﺮﻟا ﺯﻮﻣﺮﻟا ﻦﻣ ﺭاﺮﻤﺘﺳﺎﺑ ﺓﺪﻳاﺰﺘﻣ ﺔﻋﻮﻤﺠﻣ ﻡﺪﻘﻧ ﻦﺤﻨﻓ ،ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺪﻳﺪﺤﺘﻟ ﻕﺎﺒﺴﻟا 8V ﺩﻮﻘﺗ",
                keywords:
                  "8V ﺔﺻﺭﻮﺑ, ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﻲﻓ ﺓﺮﻔﺸﻤﻟا ﺔﻠﻤﻌﻟا ﺔﺻﺭﻮﺑ, ﺎﻨﻋ ﺓﺬﺒﻧ ﺔﺤﻔﺻ, ﺔﻴﻤﻗﺮﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ",
                "twitter:title": "ﺎﻨﻋ ﺓﺬﺒﻧ : 8V ﺔﺻﺭﻮﺑ",
                "twitter:description":
                  "ﻝﺎﺑ ﺔﺣاﺭﻭ ﺔﻴﺤﻳﺭﺄﺑ ﺭﺎﻤﺜﺘﺳﻻا ﻦﻣ ﺎﻨﻳﺪﻟ ﻥﻮﻣﺪﺨﺘﺴﻤﻟا ﻦﻜﻤﺘﻳ ﻰﺘﺣ ﺔﻴﻟﺎﻤﻟا ﺕﺎﺠﺘﻨﻤﻟاﻭ ﺔﻴﻤﻗﺮﻟا ﺯﻮﻣﺮﻟا ﻦﻣ ﺭاﺮﻤﺘﺳﺎﺑ ﺓﺪﻳاﺰﺘﻣ ﺔﻋﻮﻤﺠﻣ ﻡﺪﻘﻧ ﻦﺤﻨﻓ ،ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺪﻳﺪﺤﺘﻟ ﻕﺎﺒﺴﻟا 8V ﺩﻮﻘﺗ",
              },
              meta_property: {
                "twitter:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : 8V ﺔﺻﺭﻮﺑ",
                "og:title": "ﺎﻨﻋ ﺓﺬﺒﻧ : 8V ﺔﺻﺭﻮﺑ",
                "og:description":
                  "ﻝﺎﺑ ﺔﺣاﺭﻭ ﺔﻴﺤﻳﺭﺄﺑ ﺭﺎﻤﺜﺘﺳﻻا ﻦﻣ ﺎﻨﻳﺪﻟ ﻥﻮﻣﺪﺨﺘﺴﻤﻟا ﻦﻜﻤﺘﻳ ﻰﺘﺣ ﺔﻴﻟﺎﻤﻟا ﺕﺎﺠﺘﻨﻤﻟاﻭ ﺔﻴﻤﻗﺮﻟا ﺯﻮﻣﺮﻟا ﻦﻣ ﺭاﺮﻤﺘﺳﺎﺑ ﺓﺪﻳاﺰﺘﻣ ﺔﻋﻮﻤﺠﻣ ﻡﺪﻘﻧ ﻦﺤﻨﻓ ،ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺪﻳﺪﺤﺘﻟ ﻕﺎﺒﺴﻟا 8V ﺩﻮﻘﺗ",
                "og:image:alt": "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : 8V ﺔﺻﺭﻮﺑ",
              },
            },
            "service-agreement": {
              title: "ﺔﻣﺪﺨﻟا ﺔﻴﻗﺎﻔﺗا | 8V ﺔﺻﺭﻮﺑ",
              meta_name: {
                description: "ﻡﺎﻜﺣﻷاﻭ ﻁﻭﺮﺸﻟا",
                keywords:
                  "8V ﺔﺻﺭﻮﺑ, ﺔﻣﺪﺨﻟا ﺔﻴﻗﺎﻔﺗا, ﻡﺎﻜﺣﻷاﻭ ﻁﻭﺮﺸﻟا, ﺔﻴﻤﻗﺮﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ",
                "twitter:title": "ﺔﻣﺪﺨﻟا ﺔﻴﻗﺎﻔﺗا : 8V ﺔﺻﺭﻮﺑ",
                "twitter:description": "ﻡﺎﻜﺣﻷاﻭ ﻁﻭﺮﺸﻟا",
              },
              meta_property: {
                "twitter:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : 8V ﺔﺻﺭﻮﺑ",
                "og:title": "ﺔﻣﺪﺨﻟا ﺔﻴﻗﺎﻔﺗا : 8V ﺔﺻﺭﻮﺑ",
                "og:description": "ﻡﺎﻜﺣﻷاﻭ ﻁﻭﺮﺸﻟا",
                "og:image:alt": "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : 8V ﺔﺻﺭﻮﺑ",
              },
            },
            register: {
              title: "ﻙاﺮﺘﺷﻻا | 8V ﺔﺻﺭﻮﺑ",
              meta_name: {
                description:
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻘﻄﻨﻣ ﻊﻣ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻰﻟﺇ ﻢﻀﻧا | (ﻝاﻮﻣﻷﻟ) ﻝﺎﻔﻗﻹا ﻭﺃ ﺖﻴﺒﺜﺘﻟا - ﺔﻘﺘﺸﻤﻟا ﺕاﻭﺩﻷا - (OTC) ﺔﺻﺭﻮﻟا ﺝﺭﺎﺧ ﻝﻭاﺪﺘﻟا - 300X ﺶﻣﺎﻫ - ﻱﺭﻮﻔﻟا ﻝﻭاﺪﺘﻟا | ﻯﺮﺧﺃ ﺔﻴﻤﻗﺭ ﺔﻠﻤﻋ 200 ﻦﻣ ﺮﺜﻛﺃﻭ ﺮﺜﻴﺗﻭ ﻦﻳﻮﻜﺟﺩﻭ ﻡﻮﻳﺮﺜﻳﻹاﻭ ﻦﻳﻮﻜﺘﻴﺒﻟا ﻝﻭاﺪﺗ",
                keywords:
                  "8V ﺔﺻﺭﻮﺑ, ﺗﺴﺠﻴﻞ, ﻙاﺮﺘﺷا, ﻲﻧﻭﺮﺘﻜﻟﻹا ﺪﻳﺮﺒﻟا ﻥاﻮﻨﻋ ﻞﺧﺩﺃ",
                "twitter:title": "ﻙاﺮﺘﺷﻻا : 8V ﺔﺻﺭﻮﺑ",
                "twitter:description":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻠﻟ ﺔﻤﻤﺼﻤﻟا ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻲﻓ ﺕﻼﻤﻌﻟا ﺕﺎﺌﻣ ﻝﻭاﺪﺗ",
              },
              meta_property: {
                "twitter:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : 8V ﺔﺻﺭﻮﺑ",
                "og:title": "ﻙاﺮﺘﺷﻻا : 8V ﺔﺻﺭﻮﺑ",
                "og:description":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻠﻟ ﺔﻤﻤﺼﻤﻟا ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻲﻓ ﺕﻼﻤﻌﻟا ﺕﺎﺌﻣ ﻝﻭاﺪﺗ",
                "og:image:alt": "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : 8V ﺔﺻﺭﻮﺑ",
              },
            },
            login: {
              title: "ﻝﻮﺧﺪﻟا ﻞﻴﺠﺴﺗ | 8V ﺔﺻﺭﻮﺑ",
              meta_name: {
                description:
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻘﻄﻨﻣ ﻊﻣ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻰﻟﺇ ﻢﻀﻧا | (ﻝاﻮﻣﻷﻟ) ﻝﺎﻔﻗﻹا ﻭﺃ ﺖﻴﺒﺜﺘﻟا - ﺔﻘﺘﺸﻤﻟا ﺕاﻭﺩﻷا - (OTC) ﺔﺻﺭﻮﻟا ﺝﺭﺎﺧ ﻝﻭاﺪﺘﻟا - 300X ﺶﻣﺎﻫ - ﻱﺭﻮﻔﻟا ﻝﻭاﺪﺘﻟا | ﻯﺮﺧﺃ ﺔﻴﻤﻗﺭ ﺔﻠﻤﻋ 200 ﻦﻣ ﺮﺜﻛﺃﻭ ﺮﺜﻴﺗﻭ ﻦﻳﻮﻜﺟﺩﻭ ﻡﻮﻳﺮﺜﻳﻹاﻭ ﻦﻳﻮﻜﺘﻴﺒﻟا ﻝﻭاﺪﺗ",
                keywords:
                  "8V ﺔﺻﺭﻮﺑ, ﻝﻮﺧﺪﻟا ﻞﻴﺠﺴﺗ, ﻲﻧﻭﺮﺘﻜﻟﻹا ﺪﻳﺮﺒﻟا ﻞﺧﺩﺃ, ﺭﻭﺮﻤﻟا ﺔﻤﻠﻛ ﻞﺧﺩﺃ",
                "twitter:title": "ﻝﻮﺧﺪﻟا ﻞﻴﺠﺴﺗ : 8V ﺔﺻﺭﻮﺑ",
                "twitter:description":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻠﻟ ﺔﻤﻤﺼﻤﻟا ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻲﻓ ﺕﻼﻤﻌﻟا ﺕﺎﺌﻣ ﻝﻭاﺪﺗ",
              },
              meta_property: {
                "twitter:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : 8V ﺔﺻﺭﻮﺑ",
                "og:title": "ﻝﻮﺧﺪﻟا ﻞﻴﺠﺴﺗ : 8V ﺔﺻﺭﻮﺑ",
                "og:description":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻠﻟ ﺔﻤﻤﺼﻤﻟا ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻲﻓ ﺕﻼﻤﻌﻟا ﺕﺎﺌﻣ ﻝﻭاﺪﺗ",
                "og:image:alt": "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : 8V ﺔﺻﺭﻮﺑ",
              },
            },
            forgetPwd: {
              title: "ﻝﻮﺧﺪﻟا ﻞﻴﺠﺴﺗ ﺭﻭﺮﻣ ﺔﻤﻠﻛ ﻦﻴﻴﻌﺗ ﺓﺩﺎﻋﺇ | 8V ﺔﺻﺭﻮﺑ",
              meta_name: {
                description:
                  "ﺔﻋﺎﺳ 24 ﺓﺪﻤﻟ ﺐﺤﺴﻟا ﺮﻈﺤُﻳ ،ﻝﻮﺧﺪﻟا ﻞﻴﺠﺴﺗ ﺭﻭﺮﻣ ﺔﻤﻠﻛ ﻦﻴﻴﻌﺗ ﺓﺩﺎﻋﺇ ﺪﻌﺑ",
                keywords:
                  "8V ﺔﺻﺭﻮﺑ, ﺭﻭﺮﻤﻟا ﺔﻤﻠﻛ ﻦﻴﻴﻌﺗ ﺓﺩﺎﻋﺇ, ﺭﻭﺮﻤﻟا ﺔﻤﻠﻛ ﺖﻴﺴﻧ, ﺔﻴﻤﻗﺮﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ",
                "twitter:title":
                  "ﻝﻮﺧﺪﻟا ﻞﻴﺠﺴﺗ ﺭﻭﺮﻣ ﺔﻤﻠﻛ ﻦﻴﻴﻌﺗ ﺓﺩﺎﻋﺇ : 8V ﺔﺻﺭﻮﺑ",
                "twitter:description":
                  "ﺔﻋﺎﺳ 24 ﺓﺪﻤﻟ ﺐﺤﺴﻟا ﺮﻈﺤُﻳ ،ﻝﻮﺧﺪﻟا ﻞﻴﺠﺴﺗ ﺭﻭﺮﻣ ﺔﻤﻠﻛ ﻦﻴﻴﻌﺗ ﺓﺩﺎﻋﺇ ﺪﻌﺑ",
              },
              meta_property: {
                "twitter:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : 8V ﺔﺻﺭﻮﺑ",
                "og:title": "ﻝﻮﺧﺪﻟا ﻞﻴﺠﺴﺗ ﺭﻭﺮﻣ ﺔﻤﻠﻛ ﻦﻴﻴﻌﺗ ﺓﺩﺎﻋﺇ : 8V ﺔﺻﺭﻮﺑ",
                "og:description":
                  "ﺔﻋﺎﺳ 24 ﺓﺪﻤﻟ ﺐﺤﺴﻟا ﺮﻈﺤُﻳ ،ﻝﻮﺧﺪﻟا ﻞﻴﺠﺴﺗ ﺭﻭﺮﻣ ﺔﻤﻠﻛ ﻦﻴﻴﻌﺗ ﺓﺩﺎﻋﺇ ﺪﻌﺑ",
                "og:image:alt": "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : 8V ﺔﺻﺭﻮﺑ",
              },
            },
            "privacy-policy": {
              title: "ﺔﻴﺻﻮﺼﺨﻟا ﺔﺳﺎﻴﺳ | 8V ﺔﺻﺭﻮﺑ",
              meta_name: {
                description: "ﺔﻴﺻﻮﺼﺨﻟا ﺔﺳﺎﻴﺳ 8V ﺔﺻﺭﻮﺑ",
                keywords:
                  "8V ﺔﺻﺭﻮﺑ, ﺔﻴﺻﻮﺼﺨﻟا ﺔﺳﺎﻴﺳ, ﺕﺎﻣﻮﻠﻌﻤﻟا ﻦﻣﺃ, ﺔﻴﻤﻗﺮﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ",
                "twitter:title": "ﺔﻴﺻﻮﺼﺨﻟا ﺔﺳﺎﻴﺳ : 8V ﺔﺻﺭﻮﺑ",
                "twitter:description": "ﺕﺎﻣﻮﻠﻌﻤﻟا ﻦﻣﺃﻭ ﺔﻴﺻﻮﺼﺨﻟا ﺭﺎﻌﺷﺇ",
              },
              meta_property: {
                "twitter:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : 8V ﺔﺻﺭﻮﺑ",
                "og:title": "ﺔﻴﺻﻮﺼﺨﻟا ﺔﺳﺎﻴﺳ : 8V ﺔﺻﺭﻮﺑ",
                "og:description": "ﺕﺎﻣﻮﻠﻌﻤﻟا ﻦﻣﺃﻭ ﺔﻴﺻﻮﺼﺨﻟا ﺭﺎﻌﺷﺇ",
                "og:image:alt": "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : 8V ﺔﺻﺭﻮﺑ",
              },
            },
            "contract-exchange": {
              title:
                "ﺮﺜﻴﺗ /coinName ﺔﻤﺋاﺪﻟا ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟا | 8V ﺔﺻﺭﻮﺑ ﻰﻠﻋ ﺔﻠﺟﻵاcoinFullName ﺩﻮﻘﻋ ﻝﻭاﺪﺗ",
              meta_name: {
                description:
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻘﻄﻨﻣ ﻊﻣ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻰﻟﺇ ﻢﻀﻧا | 200X ﻩﺭﺪﻗ ﺶﻣﺎﻫ ﻰﻟﺇ ﻞﺼﻳ ﺎﻤﺑ ﺮﺜﻴﺗﻭ coinFullName ـﻝ ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟا ﻝﻭاﺪﺗ",
                keywords:
                  "8V ﺔﺻﺭﻮﺑ, ﺔﻤﺋاﺪﻟا ﺔﻠﺟﻵا coinName ﺩﻮﻘﻋﻭ, ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻠﻟ ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟاﻭ, ﺔﻠﺟﻵا USDT coinFullName ﺩﻮﻘﻋ",
                "twitter:title":
                  "ﺮﺜﻴﺗ / coinName ﺔﻤﺋاﺪﻟا ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟا : 8V ﺔﺻﺭﻮﺑ ﻰﻠﻋ ﺔﻠﺟﻵا coinFullName ﺩﻮﻘﻋ ﻝﻭاﺪﺗ",
                "twitter:description":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻘﻄﻨﻣ ﻊﻣ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻰﻟﺇ ﻢﻀﻧا : 200X ﻩﺭﺪﻗ ﺶﻣﺎﻫ ﻰﻟﺇ ﻞﺼﻳ ﺎﻤﺑ ﺮﺜﻴﺗﻭ coinFullName ـﻝ ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟا ﻝﻭاﺪﺗ",
              },
              meta_property: {
                "twitter:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : 8V ﺔﺻﺭﻮﺑ",
                "og:title":
                  "ﺮﺜﻴﺗ / coinName ﺔﻤﺋاﺪﻟا ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟا : 8V ﺔﺻﺭﻮﺑ ﻰﻠﻋ ﺔﻠﺟﻵا coinFullName ﺩﻮﻘﻋ ﻝﻭاﺪﺗ",
                "og:description":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻘﻄﻨﻣ ﻊﻣ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻰﻟﺇ ﻢﻀﻧا : 200X ﻩﺭﺪﻗ ﺶﻣﺎﻫ ﻰﻟﺇ ﻞﺼﻳ ﺎﻤﺑ ﺮﺜﻴﺗﻭ coinFullName ـﻝ ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟا ﻝﻭاﺪﺗ",
                "og:image:alt": "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : 8V ﺔﺻﺭﻮﺑ",
              },
            },
            delivery: {
              title:
                "coinName ﺶﻣﺎﻬﻟا ﺕاﺫ ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟا | 8V ﺔﺻﺭﻮﺑ ﻰﻠﻋ ﺔﻠﺟﻵاcoinFullName ﺩﻮﻘﻋ ﻝﻭاﺪﺗ",
              meta_name: {
                description:
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻘﻄﻨﻣ ﻊﻣ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻰﻟﺇ ﻢﻀﻧا | 100X ﻩﺭﺪﻗ ﺶﻣﺎﻫ ﻰﻟﺇ ﻞﺼﻳ ﺎﻤﺑ coinName ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟا ﻝﻭاﺪﺗ",
                keywords:
                  "8V ﺔﺻﺭﻮﺑ, ﺔﻤﺋاﺪﻟا ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟا, ﺔﻠﺟﻵا coinFullName ﺩﻮﻘﻋ, ﺔﻠﺟﻵا USDT coinName ﺩﻮﻘﻋ",
                "twitter:title":
                  "coinName ﺶﻣﺎﻬﻟا ﺕاﺫ ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟا : 8V ﺔﺻﺭﻮﺑ ﻰﻠﻋ ﺔﻠﺟﻵا coinFullName ﺩﻮﻘﻋ ﻝﻭاﺪﺗ",
                "twitter:description":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻘﻄﻨﻣ ﻊﻣ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻰﻟﺇ ﻢﻀﻧا : 100X ﻩﺭﺪﻗ ﺶﻣﺎﻫ ﻰﻟﺇ ﻞﺼﻳ ﺎﻤﺑ coinName ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟا ﻝﻭاﺪﺗ",
              },
              meta_property: {
                "twitter:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : 8V ﺔﺻﺭﻮﺑ",
                "og:title":
                  "coinName ﺶﻣﺎﻬﻟا ﺕاﺫ ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟا : 8V ﺔﺻﺭﻮﺑ ﻰﻠﻋ ﺔﻠﺟﻵا coinFullName ﺩﻮﻘﻋ ﻝﻭاﺪﺗ",
                "og:description":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻘﻄﻨﻣ ﻊﻣ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻰﻟﺇ ﻢﻀﻧا : 100X ﻩﺭﺪﻗ ﺶﻣﺎﻫ ﻰﻟﺇ ﻞﺼﻳ ﺎﻤﺑ coinName ﺔﻠﺟﻵا ﺩﻮﻘﻌﻟا ﻝﻭاﺪﺗ",
                "og:image:alt": "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : 8V ﺔﺻﺭﻮﺑ",
              },
            },
            exchange: {
              title: "ﻱﺭﻮﻔﻟاcoinName ﻕﻮﺳ ﻂﻄﺨﻣ | 8V ﺔﺻﺭﻮﺑ ﻰﻠﻋcoinFullName ﻝﻭاﺪﺗ",
              meta_name: {
                description:
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻘﻄﻨﻣ ﻊﻣ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻰﻟﺇ ﻢﻀﻧا | ﺓﺩﻭﺪﻌﻣ ٍﻥاﻮﺛ ﻲﻓ coinName ءاﺮﺷ",
                keywords:
                  "8V ﺔﺻﺭﻮﺑ, ﺔﻴﻤﻗﺮﻟا ﺔﻠﻤﻌﻟا ﺮﻌﺳ, coinName ﻂﻄﺨﻣ, ﻱﺭﻮﻔﻟا coinFullName ﻝﻭاﺪﺗ",
                "twitter:title":
                  "ﻱﺭﻮﻔﻟا coinName ﻕﻮﺳ ﻂﻄﺨﻣ : 8V ﺔﺻﺭﻮﺑ ﻰﻠﻋ coinFullName ﻝﻭاﺪﺗ",
                "twitter:description":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻘﻄﻨﻣ ﻊﻣ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻰﻟﺇ ﻢﻀﻧا : ﺓﺩﻭﺪﻌﻣ ٍﻥاﻮﺛ ﻲﻓ coinName ءاﺮﺷ",
              },
              meta_property: {
                "twitter:image:alt":
                  "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : 8V ﺔﺻﺭﻮﺑ",
                "og:title":
                  "ﻱﺭﻮﻔﻟا coinName ﻕﻮﺳ ﻂﻄﺨﻣ : 8V ﺔﺻﺭﻮﺑ ﻰﻠﻋ coinFullName ﻝﻭاﺪﺗ",
                "og:description":
                  "ﺱﺮﻴﻓﺎﺘﻴﻤﻟا ﺔﻘﻄﻨﻣ ﻊﻣ ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺔﺻﺭﻮﺑ ﻰﻟﺇ ﻢﻀﻧا : ﺓﺩﻭﺪﻌﻣ ٍﻥاﻮﺛ ﻲﻓ coinName ءاﺮﺷ",
                "og:image:alt": "ﺓﺮﻔﺸﻤﻟا ﺕﻼﻤﻌﻟا ﺱﺮﻴﻓﺎﺘﻴﻣ ﻰﻟﺇ ﺔﺑاﻮﺑ : 8V ﺔﺻﺭﻮﺑ",
              },
            },
          },
        },
        {
          name: "中文简体",
          value: "zh_CN",
          valueFull: "zh_CN",
          seoMsg: {
            index: {
              title: "8V加密货币交易所 | 全球首家元宇宙生态交易所",
              meta_name: {
                description:
                  "8V支持200种加密货币及元宇宙币，如比特币、以太币、DOGE、USDT、Binance Coin（币安币）| 现货、理财产品、合约、杠杆｜元宇宙入口就在8V，提供稳定安全、高收益率的顶级服务。",
                keywords: "8V交易所,加密货币交易,元宇宙币交易,数字货币交易",
                "twitter:title": "8V加密货币交易所｜全球首家元宇宙生态交易所",
                "twitter:description":
                  "8V是专为元宇宙构建的加密货币交易所，能够支持交易200种数位货币",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V加密货币交易所｜全球首家元宇宙生态交易所",
                "og:title": "8V加密货币交易所｜全球首家元宇宙生态交易所",
                "og:description":
                  "8V是专为元宇宙构建的加密货币交易所，能够支持交易200种数字货币",
                "og:image:alt": "8V加密货币交易所｜全球首家元宇宙生态交易所",
              },
            },
            metaverse: {
              title: "8V元宇宙生态区 | 专为元宇宙建构的加密货币媒体圈",
              meta_name: {
                description:
                  "8V元宇宙生态区提供加密货币市场最实时、最齐全、最有价值的内容 | 让你快速了解 Metaverse 数字货币及元宇宙币的新闻和市场价格。",
                keywords: "8V加密货币交易所,元宇宙生态,元宇宙市场,元宇宙新闻",
                "twitter:title":
                  "8V元宇宙生态区 | 专为元宇宙建构的加密货币媒体圈",
                "twitter:description":
                  "8V元宇宙生态区提供加密货币市场最实时、最齐全、最有价值的内容。",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V元宇宙生态区 | 专为元宇宙建构的加密货币媒体圈",
                "og:title": "8V元宇宙生态区 | 专为元宇宙建构的加密货币媒体圈",
                "og:description":
                  "8V元宇宙生态区提供加密货币市场最实时、最齐全、最有价值的内容。",
                "og:image:alt":
                  "8V元宇宙生态区 | 专为元宇宙建构的加密货币媒体圈",
              },
            },
            finances: {
              title: "8V理财产品 | 每日速赚高年化率的定存、活存选择",
              meta_name: {
                description:
                  "小资族、保守型投资人的最爱！8V理财产品提供多样化的投资选择，如BTC、ETH、DOGE、USDT及更多加密货币的定活存产品，每日领取高额利息，年化收益率最高 22.5%！质押、DeFi和云挖矿都适用。",
                keywords:
                  "8V理财产品,加密货币质押,元宇宙币交易,定期储蓄存款,活存储蓄存款",
                "twitter:title":
                  "8V理财产品 | 每日速赚高年化率的定存、活存选择",
                "twitter:description":
                  "8V理财产品年化率最高22.5%！小资族、保守型投资人的最爱！",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V理财产品 | 每日速赚高年化率的定存、活存选择",
                "og:title": "8V理财产品 | 每日速赚高年化率的定存、活存选择",
                "og:description":
                  "8V理财产品年化率最高22.5%！小资族、保守型投资人的最爱！",
                "og:image:alt": "8V理财产品 | 每日速赚高年化率的定存、活存选择",
              },
            },
            aboutus: {
              title: "8V加密货币交易所 | 关于我们",
              meta_name: {
                description:
                  "自从比特币挖矿诞生以来，加密货币市场就一直在改变世界——而 8V 正在领导这场竞赛。8V致力于建构元宇宙生态，我们所新增的数字货币币种和金融产品选择都必须经过严格的审查，在8V交易所裡用户可以安心投资！",
                keywords: "8V加密货币交易所,8V理念,元宇宙币交易,数字货币交易",
                "twitter:title": "8V加密货币交易所 | 关于我们",
                "twitter:description":
                  "8V爲元宇宙交易所的领导品牌！我们致力于建构元宇宙生态，实时新增数字货币和金融产品，并必须经过严格的审查，在8V交易所裡用户可以安心投资！",
              },
              meta_property: {
                "twitter:image:alt": "8V加密货币交易所 | 关于我们",
                "og:title": "8V加密货币交易所 | 关于我们",
                "og:description":
                  "8V爲元宇宙交易所的领导品牌！我们致力于建构元宇宙生态，实时新增数字货币和金融产品，并必须经过严格的审查，在8V交易所裡用户可以安心投资！",
                "og:image:alt": "8V加密货币交易所 | 关于我们",
              },
            },
            "service-agreement": {
              title: "8V加密货币交易所 | 用户协议",
              meta_name: {
                description: "8V详细服务条款与条件",
                keywords: "8V加密货币交易所,用户协议,服务条款,数位货币交易",
                "twitter:title": "8V加密货币交易所 | 用户协议",
                "twitter:description": "8V详细服务条款与条件",
              },
              meta_property: {
                "twitter:image:alt": "8V加密货币交易所 | 用户协议",
                "og:title": "8V加密货币交易所 | 用户协议",
                "og:description": "8V详细服务条款与条件",
                "og:image:alt": "8V加密货币交易所 | 用户协议",
              },
            },
            register: {
              title: "8V加密货币交易所 | 註册",
              meta_name: {
                description:
                  "立即註册8V加密货币交易所｜8V是专为元宇宙构建的加密货币交易所，能够支持交易200种数字货币与元宇宙币，以及一键信用卡充值的服务。",
                keywords: "8V加密货币交易所,註册,KYC,双重认证",
                "twitter:title": "8V加密货币交易所 | 註册",
                "twitter:description":
                  "立即註册8V加密货币交易所｜8V是专为元宇宙构建的加密货币交易所，能够支持交易200种数字货币与元宇宙币，以及一键信用卡充值的服务。",
              },
              meta_property: {
                "twitter:image:alt": "8V加密货币交易所 | 註册",
                "og:title": "8V加密货币交易所 | 註册",
                "og:description":
                  "立即註册8V加密货币交易所｜8V是专为元宇宙构建的加密货币交易所，能够支持交易200种数字货币与元宇宙币，以及一键信用卡充值的服务。",
                "og:image:alt": "8V加密货币交易所 | 註册",
              },
            },
            login: {
              title: "8V加密货币交易所 | 登入",
              meta_name: {
                description:
                  "8V是专为元宇宙构建的加密货币交易所，能够支持交易200种数字货币与元宇宙币，以及一键信用卡充值的服务。",
                keywords: "8V加密货币交易所,登入,登入Email,登入密码",
                "twitter:title": "8V加密货币交易所 | 登入",
                "twitter:description":
                  "8V是专为元宇宙构建的加密货币交易所，能够支持交易200种数字货币与元宇宙币，以及一键信用卡充值的服务。",
              },
              meta_property: {
                "twitter:image:alt": "8V加密货币交易所 | 登入",
                "og:title": "8V加密货币交易所 | 登入",
                "og:description":
                  "8V是专为元宇宙构建的加密货币交易所，能够支持交易200种数字货币与元宇宙币，以及一键信用卡充值的服务。",
                "og:image:alt": "8V加密货币交易所 | 登入",
              },
            },
            forgetPwd: {
              title: "8V加密货币交易所 | 重置登入密码",
              meta_name: {
                description: "重置登入密码后，24小时内禁止充值提现。",
                keywords: "8V加密货币交易所,重置密码,忘记密码,数字货币交易",
                "twitter:title": "8V加密货币交易所 | 重置登入密码",
                "twitter:description": "重置登入密码后，24小时内禁止充值提现。",
              },
              meta_property: {
                "twitter:image:alt": "8V加密货币交易所 | 重置登入密码",
                "og:title": "8V加密货币交易所 | 重置登入密码",
                "og:description": "重置登入密码后，24小时内禁止充值提现。",
                "og:image:alt": "8V加密货币交易所 | 重置登入密码",
              },
            },
            "privacy-policy": {
              title: "8V加密货币交易所 | 隐私协议",
              meta_name: {
                description: "8V加密货币交易所的隐私协议及用户数据、信息安全。",
                keywords: "8V加密货币交易所,隐私协议,用户数据安全,数字货币交易",
                "twitter:title": "8V加密货币交易所 | 隐私协议",
                "twitter:description": "用户数据安全和隐私声明",
              },
              meta_property: {
                "twitter:image:alt": "8V加密货币交易所 | 隐私协议",
                "og:title": "8V加密货币交易所 | 隐私协议",
                "og:description": "用户数据安全和隐私声明",
                "og:image:alt": "8V加密货币交易所 | 隐私协议",
              },
            },
            //coinFullName coinName（大写） 需替换
            "contract-exchange": {
              title:
                "8V加密货币交易所｜coinFullName U本位合约交易 | coinName/USDT永续合约",
              meta_name: {
                description:
                  "8V加密货币交易所｜U本位交易 coinName/USDT合约高达 200倍保证金 | 快加入8V元宇宙生态区，实时交易200种以上数字货币与元宇宙币。",
                keywords:
                  "8V加密货币交易所, coinFullName/USDT合约交易,加密货币合约交易, coinName永续合约",
                "twitter:title":
                  "8V加密货币交易所｜coinFullName U本位合约交易 | coinName/USDT永续合约",
                "twitter:description":
                  "8V加密货币交易所｜U本位交易 coinName/USDT合约高达 200倍保证金 | 快加入8V元宇宙生态区，实时交易200种以上数字货币与元宇宙币。",
              },
              meta_property: {
                "twitter:image:alt": "8V加密货币交易所 | U本位合约交易",
                "og:title":
                  "8V加密货币交易所｜coinFullName U本位合约交易 | coinName/USDT永续合约",
                "og:description":
                  "8V加密货币交易所｜U本位交易 coinName/USDT合约高达 200倍保证金 | 快加入8V元宇宙生态区，实时交易200种以上数字货币与元宇宙币。",
                "og:image:alt": "8V加密货币交易所 | U本位合约交易",
              },
            },
            //coinFullName coinName（大写） 需替换
            delivery: {
              title:
                "8V加密货币交易所｜coinFullName币本位合约交易：coinName期货合约保证金",
              meta_name: {
                description:
                  "8V加密货币交易所｜coinName币本位合约交易高达100倍保证金 | 快加入8V元宇宙生态区，实时交易200种以上数字货币与元宇宙币。",
                keywords:
                  "8V加密货币交易所, coinName/USDT合约交易, coinFullName期货合约,永续合约",
                "twitter:title":
                  "8V加密货币交易所｜coinFullName币本位合约交易： coinName期货合约保证金",
                "twitter:description":
                  "8V加密货币交易所｜coinName币本位合约交易高达100倍保证金 | 快加入8V元宇宙生态区，实时交易200种以上数字货币与元宇宙币。",
              },
              meta_property: {
                "twitter:image:alt": "8V加密货币交易所 | 币本位合约交易",
                "og:title":
                  "8V加密货币交易所｜coinFullName币本位合约交易：coinName 期货合约保证金",
                "og:description":
                  "8V加密货币交易所｜coinName币本位合约交易高达100倍保证金 | 快加入8V元宇宙生态区，实时交易200种以上数字货币与元宇宙币。",
                "og:image:alt": "8V加密货币交易所 | 币本位合约交易",
              },
            },
            //coinFullName coinName（大写） 需替换
            exchange: {
              title:
                "coinFullName/USDT 现货市场走势 | 买入coinName | 8V加密货币交易所",
              meta_name: {
                description:
                  "买入coinName | 快加入8V元宇宙生态区，实时交易200种以上数字货币与元宇宙币。",
                keywords:
                  "8V加密货币交易所, coinFullName现货, coinName走势图, coinName深度图, coinName-K线图",
                "twitter:title":
                  "coinFullName/USDT 现货市场走势 | 买入coinName | 8V加密货币交易所",
                "twitter:description":
                  "买入coinName | 快加入8V元宇宙生态区，实时交易200种以上数字货币与元宇宙币。",
              },
              meta_property: {
                "twitter:image:alt": "8V加密货币交易所｜现货市场走势图",
                "og:title":
                  "coinFullName/USDT 现货市场走势 | 买入coinName | 8V加密货币交易所",
                "og:description":
                  "买入coinName | 快加入8V元宇宙生态区，实时交易200种以上数字货币与元宇宙币。",
                "og:image:alt": "8V加密货币交易所｜现货市场走势图",
              },
            },
          },
        },
        {
          name: "中文繁體",
          value: "zh_HK",
          valueFull: "zh_HK",
          seoMsg: {
            index: {
              title: "8V加密貨幣交易所 | 全球首家元宇宙生態交易所",
              meta_name: {
                description:
                  "8V支援200種加密貨幣及元宇宙幣，如比特幣、以太幣、DOGE、USDT、Binance Coin（幣安幣）| 現貨、理財產品、合約、槓桿｜元宇宙入口就在8V，提供穩定安全、高收益率的頂級服務。",
                keywords: "8V交易所,加密貨幣交易,元宇宙幣交易,數位貨幣交易",
                "twitter:title": "8V加密貨幣交易所｜全球首家元宇宙生態交易所",
                "twitter:description":
                  "8V是專為元宇宙構建的加密貨幣交易所，能夠支援交易200種數位貨幣",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V加密貨幣交易所｜全球首家元宇宙生態交易所",
                "og:title": "8V加密貨幣交易所｜全球首家元宇宙生態交易所",
                "og:description":
                  "8V是專為元宇宙構建的加密貨幣交易所，能夠支援交易200種數位貨幣",
                "og:image:alt": "8V加密貨幣交易所｜全球首家元宇宙生態交易所",
              },
            },
            metaverse: {
              title: "8V元宇宙生態區 | 專為元宇宙建構的加密貨幣媒體圈",
              meta_name: {
                description:
                  "8V元宇宙生態區提供加密貨幣市場最新、最齊全、最有價值的內容 | 讓你快速了解 Metaverse 數位貨幣及元宇宙幣的新聞和市場價格。",
                keywords: "8V加密貨幣交易所,元宇宙生態,元宇宙市場,元宇宙新聞",
                "twitter:title":
                  "8V元宇宙生態區 | 專為元宇宙建構的加密貨幣媒體圈",
                "twitter:description":
                  "8V元宇宙生態區提供加密貨幣市場最新、最齊全、最有價值的內容。",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V元宇宙生態區 | 專為元宇宙建構的加密貨幣媒體圈",
                "og:title": "8V元宇宙生態區 | 專為元宇宙建構的加密貨幣媒體圈",
                "og:description":
                  "8V元宇宙生態區提供加密貨幣市場最新、最齊全、最有價值的內容。",
                "og:image:alt":
                  "8V元宇宙生態區 | 專為元宇宙建構的加密貨幣媒體圈",
              },
            },
            finances: {
              title: "8V理財產品 | 每日速賺高年化率的定存、活存選擇",
              meta_name: {
                description:
                  "小資族、保守型投資人的最愛！8V理財產品提供多樣化的投資選擇，如BTC、ETH、DOGE、USDT及更多加密貨幣的定活存產品，每日領取高額利息，年化率最高 22.5%！不只質押、DeFi和雲端挖礦都適用。",
                keywords:
                  "8V理財產品,加密貨幣質押,元宇宙幣交易,定期儲蓄存款,活存儲蓄存款",
                "twitter:title":
                  "8V理財產品 | 每日速賺高年化率的定存、活存選擇",
                "twitter:description":
                  "8V理財產品年化率最高22.5%！小資族、保守型投資人的最愛！",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V理財產品 | 每日速賺高年化率的定存、活存選擇",
                "og:title": "8V理財產品 | 每日速賺高年化率的定存、活存選擇",
                "og:description":
                  "8V理財產品年化率最高22.5%！小資族、保守型投資人的最愛！",
                "og:image:alt": "8V理財產品 | 每日速賺高年化率的定存、活存選擇",
              },
            },
            aboutus: {
              title: "8V加密貨幣交易所 | 關於我們",
              meta_name: {
                description:
                  "自從比特幣挖礦誕生以來，加密貨幣市場就一直在改變世界——而 8V 正在領導這場競賽。8V致力於建構元宇宙生態，我們所新增的數位貨幣幣種和金融產品選擇都必須經過嚴格的審查，在8V交易所裡用戶可以安心投資！",
                keywords: "8V加密貨幣交易所,8V理念,元宇宙幣交易,數位貨幣交易",
                "twitter:title": "8V加密貨幣交易所 | 關於我們",
                "twitter:description":
                  "8V爲元宇宙交易所的領導品牌！我們致力於建構元宇宙生態，隨時新增數位貨幣和金融產品，並必須經過嚴格的審查，在8V交易所裡用戶可以安心投資！",
              },
              meta_property: {
                "twitter:image:alt":
                  "8V加密貨幣交易所 | 全球首家元宇宙生態交易所",
                "og:title": "8V加密貨幣交易所 | 關於我們",
                "og:description":
                  "8V爲元宇宙交易所的領導品牌！我們致力於建構元宇宙生態，隨時新增數位貨幣和金融產品，並必須經過嚴格的審查，在8V交易所裡用戶可以安心投資！",
                "og:image:alt": "8V加密貨幣交易所 | 全球首家元宇宙生態交易所",
              },
            },
            "service-agreement": {
              title: "8V加密貨幣交易所 | 用戶協議",
              meta_name: {
                description: "8V詳細服務條款與條件",
                keywords: "8V加密貨幣交易所,用戶協議,服務條款,數位貨幣交易",
                "twitter:title": "8V加密貨幣交易所 | 用戶協議",
                "twitter:description": "8V詳細服務條款與條",
              },
              meta_property: {
                "twitter:image:alt": "8V加密貨幣交易所 | 用戶協議",
                "og:title": "8V加密貨幣交易所 | 用戶協議",
                "og:description": "8V詳細服務條款與條",
                "og:image:alt": "8V加密貨幣交易所 | 用戶協議",
              },
            },
            register: {
              title: "8V加密貨幣交易所 | 註冊",
              meta_name: {
                description:
                  "立即註冊8V加密貨幣交易所｜8V是專為元宇宙構建的加密貨幣交易所，能夠支援交易200種數位貨幣與元宇宙幣，以及一鍵信用卡入金的服務。",
                keywords: "8V加密貨幣交易所,註冊,KYC,雙重驗證",
                "twitter:title": "8V加密貨幣交易所 | 註冊",
                "twitter:description":
                  "8V是專為元宇宙構建的加密貨幣交易所，能夠支援交易200種數位貨幣與元宇宙幣，以及一鍵信用卡入金的服務。",
              },
              meta_property: {
                "twitter:image:alt": "8V加密貨幣交易所 | 註冊",
                "og:title": "8V加密貨幣交易所 | 註冊",
                "og:description":
                  "8V是專為元宇宙構建的加密貨幣交易所，能夠支援交易200種數位貨幣與元宇宙幣，以及一鍵信用卡入金的服務。",
                "og:image:alt": "8V加密貨幣交易所 | 註冊",
              },
            },
            login: {
              title: "8V加密貨幣交易所 | 登入",
              meta_name: {
                description:
                  "8V是專為元宇宙構建的加密貨幣交易所，能夠支援交易200種數位貨幣與元宇宙幣，以及一鍵信用卡入金的服務。",
                keywords: "8V加密貨幣交易所,登入,登入Email,登入密碼",
                "twitter:title": "8V加密貨幣交易所 | 登入",
                "twitter:description":
                  "8V是專為元宇宙構建的加密貨幣交易所，能夠支援交易200種數位貨幣與元宇宙幣，以及一鍵信用卡入金的服務。",
              },
              meta_property: {
                "twitter:image:alt": "8V加密貨幣交易所 | 登入",
                "og:title": "8V加密貨幣交易所 | 登入",
                "og:description":
                  "8V是專為元宇宙構建的加密貨幣交易所，能夠支援交易200種數位貨幣與元宇宙幣，以及一鍵信用卡入金的服務。",
                "og:image:alt": "8V加密貨幣交易所 | 登入",
              },
            },
            forgetPwd: {
              title: "8V加密貨幣交易所 | 重置登入密碼",
              meta_name: {
                description: "重置登入密碼後，24小時內禁止出入金。",
                keywords: "8V加密貨幣交易所,重置密碼,忘記密碼,數位貨幣交易",
                "twitter:title": "8V加密貨幣交易所 | 重置登入密碼",
                "twitter:description": "重置登入密碼後，24小時內禁止出入金。",
              },
              meta_property: {
                "twitter:image:alt": "8V加密貨幣交易所 | 重置登入密碼",
                "og:title": "8V加密貨幣交易所 | 重置登入密碼",
                "og:description": "重置登入密碼後，24小時內禁止出入金。",
                "og:image:alt": "8V加密貨幣交易所 | 重置登入密碼",
              },
            },
            "privacy-policy": {
              title: "8V加密貨幣交易所 | 隱私權政策",
              meta_name: {
                description: "8V加密貨幣交易所的隱私權政策及個資安全資訊。",
                keywords:
                  "8V加密貨幣交易所,隱私權政策,個資安全資訊,數位貨幣交易",
                "twitter:title": "8V加密貨幣交易所 | 隱私權政策",
                "twitter:description": "隱私權政策及個資安全資訊",
              },
              meta_property: {
                "twitter:image:alt": "8V加密貨幣交易所 | 隱私權政策",
                "og:title": "8V加密貨幣交易所 | 隱私權政策",
                "og:description": "隱私權政策及個資安全資訊",
                "og:image:alt": "8V加密貨幣交易所 | 隱私權政策",
              },
            },
            //coinFullName coinName（大写） 需替换
            "contract-exchange": {
              title:
                "8V加密貨幣交易所｜ coinFullName U本位合約交易 | coinName/USDT永續合約",
              meta_name: {
                description:
                  "8V加密貨幣交易所｜U本位交易 coinName/USDT合約高達 200倍保證金 | 快加入8V元宇宙生態區，即時交易200種以上數位貨幣與元宇宙幣。",
                keywords:
                  "8V加密貨幣交易所, coinFullName/USDT合約交易,加密貨幣合約交易, coinName永續合約",
                "twitter:title":
                  "8V加密貨幣交易所｜coinFullNameU本位合約交易 : coinName/USDT永續合約",
                "twitter:description":
                  "8V加密貨幣交易所｜U本位交易 coinName/USDT合約高達200倍保證金  | 快加入8V元宇宙生態區，即時交易200種以上數位貨幣與元宇宙幣。",
              },
              meta_property: {
                "twitter:image:alt": "8V加密貨幣交易所 | U本位合約交易",
                "og:title": "8V加密貨幣交易所 | 隱私權政策",
                "og:description": "隱私權政策及個資安全資訊",
                "og:image:alt": "8V加密貨幣交易所 | U本位合約交易",
              },
            },
            delivery: {
              title:
                "8V加密貨幣交易所｜coinFullName 幣本位合約交易： coinName 期貨合約保證金",
              meta_name: {
                description:
                  "8V加密貨幣交易所｜coinName 幣本位合約交易高達100倍保證金 | 快加入8V元宇宙生態區，即時交易200種以上數位貨幣與元宇宙幣。",
                keywords:
                  "8V加密貨幣交易所, coinName/USDT合約交易, coinFullName期貨合約,永續合約",
                "twitter:title":
                  "8V加密貨幣交易所｜coinFullName幣本位合約交易： coinName期貨合約保證金",
                "twitter:description":
                  "8V加密貨幣交易所｜coinName幣本位合約交易高達100倍保證金 | 快加入8V元宇宙生態區，即時交易200種以上數位貨幣與元宇宙幣。",
              },
              meta_property: {
                "twitter:image:alt": "8V加密貨幣交易所 | 幣本位合約交易",
                "og:title":
                  "8V加密貨幣交易所｜coinFullName幣本位合約交易： coinName期貨合約保證金",
                "og:description":
                  "8V加密貨幣交易所｜coinName幣本位合約交易高達100倍保證金 | 快加入8V元宇宙生態區，即時交易200種以上數位貨幣與元宇宙幣。",
                "og:image:alt": "8V加密貨幣交易所 | 幣本位合約交易",
              },
            },
            exchange: {
              title:
                "coinFullName/USDT 現貨市場走勢 | 買入coinName | 8V加密貨幣交易所",
              meta_name: {
                description:
                  "即時買入coinName | 快加入8V元宇宙生態區，即時交易200種以上數位貨幣與元宇宙幣。",
                keywords:
                  "8V加密貨幣交易所, coinFullName現貨, coinName走勢圖, coinName深度圖, coinName-K線圖",
                "twitter:title":
                  "coinFullName/USDT 現貨市場走勢 | 買入coinName | 8V加密貨幣交易所",
                "twitter:description":
                  "即時買入coinName | 快加入8V元宇宙生態區，即時交易200種以上數位貨幣與元宇宙幣。",
              },
              meta_property: {
                "twitter:image:alt": "8V加密貨幣交易所｜現貨市場走勢圖",
                "og:title":
                  "coinFullName/USDT 現貨市場走勢 | 買入coinName | 8V加密貨幣交易所",
                "og:description":
                  "即時買入coinName | 快加入8V元宇宙生態區，即時交易200種以上數位貨幣與元宇宙幣。",
                "og:image:alt": "8V加密貨幣交易所｜現貨市場走勢圖",
              },
            },
          },
        },
      ],
      coinInfoMap: [],
    };
  },
  computed: {
    ...mapGetters({
      language: "user/language",
    }),
    coinMsg() {
      let coinFullName = "";
      let coinName = "";

      let pathArray = this.$route.path.split("/");
      if (pathArray[1] == "contract-exchange") {
        coinFullName = "Bitcoin";
        coinName = "BTC";
        if (pathArray.length >= 3) {
          let tempCoin = pathArray[2].split("usdt");
          this.coinInfoMap.forEach(item => {
            if (item.name.toLowerCase() == tempCoin[0].toLowerCase()) {
              coinFullName = item.language.display_en;
              coinName = tempCoin[0].toUpperCase();
            }
          });
        }
      } else if (pathArray[1] == "delivery") {
        coinFullName = "Ethereum";
        coinName = "ETH";
        if (pathArray.length >= 3) {
          let tempCoin = pathArray[2].split("usd");
          this.coinInfoMap.forEach(item => {
            if (item.name.toLowerCase() == tempCoin[0].toLowerCase()) {
              coinFullName = item.language.display_en;
              coinName = tempCoin[0].toUpperCase();
            }
          });
        }
      } else if (pathArray[1] == "exchange") {
        coinFullName = "Bitcoin";
        coinName = "BTC";
        if (pathArray.length >= 3) {
          let tempCoin = pathArray[2].split("_");
          this.coinInfoMap.forEach(item => {
            if (item.name.toLowerCase() == tempCoin[0].toLowerCase()) {
              coinFullName = item.language.display_en;
              coinName = tempCoin[0].toUpperCase();
            }
          });
        }
      }
      if (
        window.location.href == "https://8v.com" ||
        window.location.href == "https://8v.com/"
      ) {
        let script = document.createElement("script");
        script.type = "application/ld+json";
        script.text = `
        	{
            "@context": "https://schema.org",
            "@graph": [{
                "@type": "Organization",
                "@id": "https://www.8v.com",
                "name": "8V Exchange",
                "alternateName": [
                  "8V交易所",
                  "8V 거래소"
                ],
                "url": "https://www.8v.com",
                "legalName": "EIGHT V TECHNOLOGY LTD.",
                "foundingDate": "2021",
                "sameAs": [
                  "https://twitter.com/8vGlobal",
                  "https://www.facebook.com/8V.Global/",
                  "https://www.instagram.com/8v.global"
                ],
                "logo": {
                  "@type": "ImageObject",
                  "@id": "https://www.8v.com",
                  "url": "https://8v.com/logo_8V.png",
                  "caption": "8V Exchange"
                },
                "image": {
                  "@id": "https://www.8v.com"
                }
              },
              {
                "@type": "WebSite",
                "@id": "https://www.8v.com/",
                "url": "https://www.8v.com/",
                "name": "8V Exchange",
                "publisher": {
                  "@id": "https://www.8v.com/"
                }
              },
              {
                "@type": "ImageObject",
                "@id": " https://www.8V.com/",
                "url": "https://8v.com/logo_8V.png",
                "width": 1024,
                "height": 1024
              },
              {
                "@type": "WebPage",
                "@id": "https://www.8v.com",
                "url": "https://www.8v.com",
                "inLanguage": "en",
                "name": "8V Exchange",
                "description": "8V Exchange - Trade hundreds of crypto coins on the exchange built for the Metaverse",
                "isPartOf": {
                  "@id": "https://www.8v.com"
                },
                "about": {
                  "@id": "https://8v.com/aboutus"
                },
                "primaryImageOfPage": {
                  "@id": "https://www.8v.com/"
                }
              },
              {
                "@type": "Table",
                "about": "Cryptocurrency Market Info"
              }
              ]
            }
        `;
        document.getElementsByTagName("head")[0].appendChild(script);
      }
      return { coinFullName, coinName };
    },
  },
  metaInfo() {
    if (VUE_UNION_STATION != "8V") {
      return {};
    }
    document.getElementsByTagName("html")[0].lang =
      this.language == "zh_CN"
        ? "zh-Hans"
        : this.language == "zh_HK"
        ? "zh-Hant"
        : this.language;
    let pageTitle = "";
    let linkArray = [];
    let metaArray = [];
    let newUrl = "";
    // let urlArray = this.$route.path.split("/");
    let realArray = this.$route.path.split("/");
    let lastIsLangReal = false;
    this.seoList.forEach(ele => {
      if (ele.value == realArray[realArray.length - 1]) {
        lastIsLangReal = true;
      }
    });
    if (lastIsLangReal) {
      realArray[realArray.length - 1] = this.language;
    } else {
      if (!realArray[realArray.length - 1]) {
        realArray[realArray.length - 1] = this.language;
      } else {
        realArray[realArray.length] = this.language;
      }
    }
    let urlArray = JSON.parse(JSON.stringify(realArray));
    this.seoList.forEach(item => {
      //alternate/canonical
      let linkTemp = {};
      let metaTemp = {};
      linkTemp["rel"] = "alternate";
      // linkTemp["rel"] = item.value;

      urlArray[urlArray.length - 1] = item.value;

      // if (urlArray[urlArray.length - 1] === "en") {
      //   urlArray.pop();
      // }
      newUrl = urlArray.join("/");
      if (newUrl.endsWith("/en")) {
        newUrl = newUrl.slice(0, -3);
      }
      linkTemp["href"] =
        document.location.protocol + "//" + window.location.host + newUrl;
      // .replace("en/", "")
      // .replace("en", "");

      // linkTemp["hreflang"] =
      //   item.value == this.language
      //     ? "x-default"
      //     : item.value == "zh_CN"
      //     ? "zh-Hans"
      //     : item.value == "zh_HK"
      //     ? "zh-Hant"
      //     : item.value;
      if (item.value == "en") {
        let queryArray = newUrl.split("/");
        if (queryArray[queryArray.length - 1] == "en") {
          queryArray.pop();
        }
        let tempLinkTemp = {};
        tempLinkTemp["rel"] = "alternate";
        tempLinkTemp["href"] =
          document.location.protocol +
          "//" +
          window.location.host +
          queryArray.join("/");
        // newUrl.replace("en/", "").replace("en", "");
        tempLinkTemp["hreflang"] = "x-default";
        linkArray.push(tempLinkTemp);
      }
      linkTemp["hreflang"] =
        item.value == "zh_CN"
          ? "zh-Hans"
          : item.value == "zh_HK"
          ? "zh-Hant"
          : item.value;
      linkArray.push(linkTemp);
      //opengraph
      if (this.language == item.value) {
        let imageUrl =
          document.location.protocol +
          "//" +
          window.location.host +
          `/previewimage${
            item.value == "en"
              ? "EN"
              : item.value == "zh_CN"
              ? "ZH-CN"
              : item.value == "zh_HK"
              ? "ZH-HK"
              : item.value.toUpperCase() + "-" + item.value.toUpperCase()
          }.png`;
        metaArray.push({
          property: "og:image",
          content: imageUrl,
        });
        metaArray.push({
          property: "twitter:image",
          content: imageUrl,
        });
        metaArray.push({
          property: "twitter:image:src",
          content: imageUrl,
        });
        // if (urlArray[1] == "contract-exchange") {
        // }
        metaTemp["property"] = "og:locale";
        metaTemp["content"] = item.valueFull;
        let isEnd = true;
        item.seoMsg &&
          Object.keys(item.seoMsg).every(ele => {
            if (this.$route.path.includes(ele)) {
              //title
              pageTitle = item.seoMsg[ele].title
                .replace("coinFullName", this.coinMsg["coinFullName"])
                .replace("coinName", this.coinMsg["coinName"]);
              //meta name
              Object.keys(item.seoMsg[ele].meta_name).forEach(elem => {
                metaArray.push({
                  name: elem,
                  content: item.seoMsg[ele].meta_name[elem]
                    .replace("coinFullName", this.coinMsg["coinFullName"])
                    .replace("coinName", this.coinMsg["coinName"]),
                });
              });
              //meta property
              Object.keys(item.seoMsg[ele].meta_property).forEach(elep => {
                metaArray.push({
                  property: elep,
                  content: item.seoMsg[ele].meta_property[elep]
                    .replace("coinFullName", this.coinMsg["coinFullName"])
                    .replace("coinName", this.coinMsg["coinName"]),
                });
              });
              isEnd = false;
              return isEnd;
            }
            return true;
          });
        if (isEnd && item.seoMsg) {
          //title
          pageTitle = item.seoMsg["index"].title
            .replace("coinFullName", this.coinMsg["coinFullName"])
            .replace("coinName", this.coinMsg["coinName"]);
          //meta name
          Object.keys(item.seoMsg["index"].meta_name).forEach(elem => {
            metaArray.push({
              name: elem,
              content: item.seoMsg["index"].meta_name[elem]
                .replace("coinFullName", this.coinMsg["coinFullName"])
                .replace("coinName", this.coinMsg["coinName"]),
            });
          });
          //meta property
          Object.keys(item.seoMsg["index"].meta_property).forEach(elep => {
            metaArray.push({
              property: elep,
              content: item.seoMsg["index"].meta_property[elep]
                .replace("coinFullName", this.coinMsg["coinFullName"])
                .replace("coinName", this.coinMsg["coinName"]),
            });
          });
        }
      } else {
        metaTemp["property"] = "og:locale:alternate";
        metaTemp["content"] = item.valueFull;
      }
      metaArray.push(metaTemp);
    });
    metaArray.push({
      property: "og:url",
      content:
        document.location.protocol +
        "//" +
        window.location.host +
        realArray.join("/").replace("en/", "").replace("en", ""),
    });
    metaArray.push({ property: "og:type", content: "website" });
    metaArray.push({ name: "og:image:width", content: "1200" });
    metaArray.push({ name: "og:image:height", content: "630" });

    metaArray.push({ name: "Resource-type", content: "Document" });
    metaArray.push({ name: "google", content: "notranslate" });

    metaArray.push({ name: "twitter:site", content: "@8vGlobal" });
    metaArray.push({ name: "twitter:card", content: "summary_large_image" });
    //icon//
    linkArray.push({
      rel: "apple-touch-icon",
      type: "image/png",
      href: "/favicon.png",
    });
    linkArray.push({
      rel: "apple-touch-icon",
      type: "image/png",
      sizes: "72x72",
      href: "/favicon.png",
    });
    linkArray.push({
      rel: "apple-touch-icon",
      type: "image/png",
      sizes: "114x114",
      href: "/favicon.png",
      // test: this.$route.path.split("/"),
      // test2: JSON.stringify(this.coinMsg),
    });
    linkArray.push({ rel: "icon", type: "image/png", href: "/favicon.png" });
    if (realArray[realArray.length - 1] == "en") {
      realArray.pop();
    }

    let isAdd301Meta = false;
    let meta301 = [];
    let locationHref = "";
    let isHaveLangTag = false;
    let currHref = window.location.href;
    this.seoList.forEach(item => {
      if (currHref.endsWith("/" + item.value)) {
        isHaveLangTag = item.value;
      }
    });
    if (isHaveLangTag) {
      if (currHref.endsWith("/en")) {
        isAdd301Meta = true;
      } else {
        let hrefArray = currHref.split("/" + isHaveLangTag);
        hrefArray[hrefArray.length - 1] = "";
        let href = hrefArray.join("");
        if (/[A-Z]/.test(href)) {
          isAdd301Meta = true;
        }
      }
    } else if (
      (currHref.endsWith("/") &&
        currHref !=
          document.location.protocol + "//" + window.location.host + "/") ||
      /[A-Z]/.test(currHref)
    ) {
      isAdd301Meta = true;
    }

    if (isAdd301Meta) {
      if (isHaveLangTag) {
        let hrefArray = currHref.split("/" + isHaveLangTag);
        hrefArray[hrefArray.length - 1] = "";
        let href = hrefArray.join("").toLowerCase();
        if (isHaveLangTag == "en") {
          locationHref = href;
        } else {
          locationHref = href + "/" + isHaveLangTag;
        }
      } else {
        if (currHref.endsWith("/")) {
          if (
            currHref ==
            document.location.protocol + "//" + window.location.host + "/"
          ) {
            locationHref = currHref.toLowerCase();
          } else {
            locationHref = currHref.slice(0, -1).toLowerCase();
          }
        } else {
          locationHref = currHref.toLowerCase();
        }
      }
      meta301.push({ name: "prerender-status-code", content: "301" });
      meta301.push({
        name: "prerender-header",
        content: "Location:" + locationHref,
      });
    }
    return {
      title: pageTitle,
      meta: [...meta301, ...metaArray],
      link: [
        ...linkArray,
        // {
        //   rel: "canonical",
        //   href:
        //     document.location.protocol +
        //     "//" +
        //     window.location.host +
        //     this.$route.path,
        // },
        {
          rel: "canonical",
          href:
            document.location.protocol +
            "//" +
            window.location.host +
            realArray.join("/"),
          // realArray.join("/").replace("en/", "").replace("en", ""),
        },
      ],
    };
    // } else {
    //   return {};
    // }
  },
  created() {
    this.getCoinInfoMap();
  },
  methods: {
    async getCoinInfoMap() {
      const { status, data } = await apiGetStaticTableInfo(1);
      if (status == 200) {
        this.coinInfoMap = Object.values(data);
      }
    },
  },
};
</script>
