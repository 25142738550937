<template>
  <div
    :class="
      language == 'zh_CN'
        ? 'page-content-cn'
        : language == 'zh_HK'
        ? 'page-content-hk'
        : 'page-content-en'
    "
  >
    <div class="bg1">
      <div
        class="curr-lang-area"
        @click="
          () => {
            isShowLangChoose = true;
          }
        "
      >
        <img
          :src="
            language == 'zh_CN'
              ? zh_CN_flag
              : language == 'zh_HK'
              ? zh_HK_flag
              : en_flag
          "
          class="curr-flag"
        />
        <span class="curr-lang-name">{{
          language == "zh_CN"
            ? "简体"
            : language == "zh_HK"
            ? "繁體"
            : "English"
        }}</span>
        <img
          :src="arrow"
          :class="isShowLangChoose ? 'lang-state-expand' : 'lang-state-normal'"
        />
      </div>

      <div class="all-lang-area" v-show="isShowLangChoose">
        <div
          class="lang-item item-first"
          @click="changeLang('zh_CN')"
          :class="language == 'zh_CN' ? 'lang-item-curr' : ''"
        >
          <img :src="zh_CN_flag" class="curr-flag" />
          <span class="curr-lang-name">{{ "简体" }}</span>
        </div>
        <div
          class="lang-item"
          @click="changeLang('zh_HK')"
          :class="language == 'zh_HK' ? 'lang-item-curr' : ''"
        >
          <img :src="zh_HK_flag" class="curr-flag" />
          <span class="curr-lang-name">{{ "繁體" }}</span>
        </div>
        <div
          class="lang-item item-last"
          @click="changeLang('en')"
          :class="language == 'en' ? 'lang-item-curr' : ''"
        >
          <img :src="en_flag" class="curr-flag" />
          <span class="curr-lang-name">{{ "English" }}</span>
        </div>
      </div>
      <div
        class="bg1-button"
        @click="
          () => {
            isIos
              ? download4url('https://get.8v.com/', 'defult_super_sign_url')
              : download4url(
                  download_app_info.defult_apk_url,
                  'defult_apk_url'
                );
          }
        "
      >
        {{
          language == "zh_CN"
            ? "立即下载APP 获取新人福利"
            : language == "zh_HK"
            ? "立即下載APP 獲取新人福利"
            : "Get the app, snag new user perks!"
        }}
      </div>
    </div>
    <div class="bg2"></div>
    <div class="bg3"></div>
    <div class="bg4">
      <div class="bg4-content"></div>
    </div>
    <div class="bg5">
      <span class="bg5-title1">{{
        language == "zh_CN"
          ? "极具竞争力的"
          : language == "zh_HK"
          ? "極具競爭力的"
          : "8V Exchange:"
      }}</span>
      <span class="bg5-title2">{{
        language == "zh_CN"
          ? "8V加密货币交易所"
          : language == "zh_HK"
          ? "8V加密貨幣交易所"
          : "A Strong Contender"
      }}</span>
      <span class="bg5-title3">{{
        language == "zh_CN"
          ? "用户遍布全球100多个国家和地区"
          : language == "zh_HK"
          ? "用戶遍佈全球100多個國家和地區"
          : "Users across more than 100 countries"
      }}</span>
      <div class="data data1">
        <div class="data-half">
          <span class="dh-title">{{
            language == "zh_CN"
              ? "每日活跃用户"
              : language == "zh_HK"
              ? "每日活躍用戶"
              : "Daily Active Users"
          }}</span>
          <span class="dh-data"
            ><countTo
              :startVal="0"
              :endVal="5000"
              :duration="30000"
              separator=""
            ></countTo
            >+</span
          >
          <span class="dh-unit">{{
            language == "zh_CN" ? "名" : language == "zh_HK" ? "名" : ""
          }}</span>
        </div>
        <div class="data-half">
          <span class="dh-title">{{
            language == "zh_CN"
              ? "注册总人数"
              : language == "zh_HK"
              ? "註冊總人數"
              : "Registered Users"
          }}</span>
          <span class="dh-data"
            ><countTo
              :startVal="0"
              :endVal="300"
              :duration="30000"
              separator=""
            ></countTo
            >K+</span
          >
          <span class="dh-unit">{{
            language == "zh_CN" ? "名" : language == "zh_HK" ? "名" : ""
          }}</span>
        </div>
      </div>
      <div class="data">
        <div class="data-half">
          <span class="dh-title">{{
            language == "zh_CN"
              ? "每日交易量"
              : language == "zh_HK"
              ? "每日交易量"
              : "Daily Volume"
          }}</span>
          <span class="dh-data"
            ><countTo
              :startVal="0"
              :endVal="2100"
              :duration="30000"
              separator=""
            ></countTo
            >M+</span
          >
          <span class="dh-unit">USD</span>
        </div>
        <div class="data-half">
          <span class="dh-title">{{
            language == "zh_CN"
              ? "平台储备金"
              : language == "zh_HK"
              ? "平台儲備金"
              : "Platform Reserve"
          }}</span>
          <span class="dh-data"
            ><countTo
              :startVal="0"
              :endVal="2000"
              :duration="30000"
              separator=""
            ></countTo
            >M+</span
          >
          <span class="dh-unit">USD</span>
        </div>
      </div>
    </div>
    <div class="bg6">
      <span class="bg6-title1">{{
        language == "zh_CN"
          ? "关于8V"
          : language == "zh_HK"
          ? "關於8V"
          : "About 8V"
      }}</span>
      <span class="bg6-title2">{{
        language == "zh_CN"
          ? "结合WEB2.0及WEB3.0生态宇宙"
          : language == "zh_HK"
          ? "結合WEB2.0及WEB3.0生態宇宙"
          : "Bridging Web2.0 and Web3.0"
      }}</span>
      <div class="bg6-content">
        {{
          language == "zh_CN"
            ? "我们专注于顶尖设计、互联互通开发技术，提供最齐全的虚拟货币币种、最低交易手续费、最高交易深度，以及信用卡买币、加密货币支付卡等功能，不仅体现WEB 3.0去中心化的高度自由，也让WEB 2.0 的投资者能安心规划数位资产，助力打造引领全球实现真正的虚拟世界生态系统。"
            : language == "zh_HK"
            ? "我們專注於頂尖設計、互聯互通開發技術，提供最齊全的虛擬貨幣幣種、最低交易手續費、最高交易深度，以及信用卡買幣、加密貨幣支付卡等功能，不僅體現WEB 3.0去中心化的高度自由，也讓WEB 2.0 的投資者能安心規劃數位資產，助力打造引領全球實現真正的虛擬世界生態系統。"
            : "We prioritize top-tier design and interconnectivity in tech development, offering an extensive range of virtual currencies, the lowest transaction fees, and the highest trading depth. Our platform includes features like credit card purchasing and crypto payment cards, merging Web 3.0 freedom with Web 2.0 investor confidence. This approach is key to building our leading, global virtual world ecosystem."
        }}
      </div>
      <span class="bg6-title-team">{{
        language == "zh_CN"
          ? "我们的团队"
          : language == "zh_HK"
          ? "我們的團隊"
          : "Our Team"
      }}</span>
      <div class="bg6-team1">
        <div class="item">
          <img class="item-avater" src="~@/assets/h5/zh_CN/bg6-1.png" />
          <span class="item-name">Matt</span>
          <span class="item-job">Chief Architect</span>
        </div>
        <div class="item">
          <img class="item-avater" src="~@/assets/h5/zh_CN/bg6-2.png" />
          <span class="item-name">Jasper</span>
          <span class="item-job">Team Advocate</span>
        </div>
        <div class="item">
          <img class="item-avater" src="~@/assets/h5/zh_CN/bg6-3.png" />
          <span class="item-name">Jack</span>
          <span class="item-job">Platform Alchemist</span>
        </div>
      </div>
      <div class="bg6-team2">
        <div class="item">
          <img class="item-avater" src="~@/assets/h5/zh_CN/bg6-4.png" />
          <span class="item-name">Ann</span>
          <span class="item-job">Marketing Wiz</span>
        </div>
        <div class="item">
          <img class="item-avater" src="~@/assets/h5/zh_CN/bg6-5.png" />
          <span class="item-name">David</span>
          <span class="item-job">OPS Ninja</span>
        </div>
      </div>
    </div>
    <div class="bg7">
      <span class="bg7-title1">{{
        language == "zh_CN"
          ? "合作伙伴"
          : language == "zh_HK"
          ? "合作夥伴"
          : "Our Partners"
      }}</span>
      <span class="bg7-title2">{{
        language == "zh_CN"
          ? "8V广受区块链领先合作伙伴认可"
          : language == "zh_HK"
          ? "8V廣受區塊鏈領先合作夥伴認可"
          : "8V is recognized by leading blockchain partners."
      }}</span>
      <div class="bg7-items">
        <img class="item" src="~@/assets/h5/zh_CN/bg7-1.png" />
        <img class="item" src="~@/assets/h5/zh_CN/bg7-2.png" />
        <img class="item" src="~@/assets/h5/zh_CN/bg7-3.png" />
        <img class="item" src="~@/assets/h5/zh_CN/bg7-4.png" />
        <img class="item" src="~@/assets/h5/zh_CN/bg7-5.png" />
        <img class="item" src="~@/assets/h5/zh_CN/bg7-6.png" />
        <img class="item" src="~@/assets/h5/zh_CN/bg7-7.png" />
        <img class="item" src="~@/assets/h5/zh_CN/bg7-8.png" />
        <img class="item" src="~@/assets/h5/zh_CN/bg7-9.png" />
        <img class="item" src="~@/assets/h5/zh_CN/bg7-10.png" />
        <img class="item" src="~@/assets/h5/zh_CN/bg7-11.png" />
        <img class="item" src="~@/assets/h5/zh_CN/bg7-12.png" />
      </div>
    </div>
    <div class="bg8">
      <span class="bg8-title1">{{
        language == "zh_CN"
          ? "随时随地在"
          : language == "zh_HK"
          ? "隨時隨地在"
          : "Trade anytime, from"
      }}</span>
      <span class="bg8-title2">{{
        language == "zh_CN"
          ? "任何平台交易"
          : language == "zh_HK"
          ? "任何平台交易"
          : "anywhere, on any device"
      }}</span>
      <span class="bg8-title3">{{
        language == "zh_CN"
          ? "相容于多种装置，现在开始安全可靠的交易"
          : language == "zh_HK"
          ? "相容於多種裝置，現在開始安全可靠的交易"
          : "Meeting the needs of our global user community 24/7"
      }}</span>
      <div class="qr-code">
        <qrcode-vue
          class="qr_code_canvas"
          :value="
            isIos
              ? 'https://get.8v.com/'.replace('^^', '&')
              : download_app_info.defult_apk_url.replace('^^', '&')
          "
          :size="140"
          level="H"
        ></qrcode-vue>
      </div>
      <div class="down">
        <div
          class="d-button"
          @click="download4url('https://get.8v.com/', 'defult_super_sign_url')"
        >
          <img class="db-img ap" src="~@/assets/h5/zh_CN/ap.png" />
          <span class="db-text">{{
            language == "zh_CN"
              ? "下载iOS版"
              : language == "zh_HK"
              ? "下載iOS版"
              : "Download iOS"
          }}</span>
        </div>
        <div
          class="d-button"
          @click="
            download4url(download_app_info.defult_apk_url, 'defult_apk_url')
          "
        >
          <img class="db-img an" src="~@/assets/h5/zh_CN/an.png" />
          <span class="db-text">{{
            language == "zh_CN"
              ? "下载Android版"
              : language == "zh_HK"
              ? "下載Android版"
              : "Download Android"
          }}</span>
          <!-- <div class="db-text2">
            <span>{{ language == 'zh_CN' || language == 'zh_HK' ? '下載Android版' : 'page-content-en'}}</span>
            <span>{{ language == 'zh_CN' || language == 'zh_HK' ? 'page-content-cn' : 'page-content-en'}}</span>
          </div> -->
        </div>
      </div>
      <div class="desk">
        <img class="desk-img" src="~@/assets/h5/zh_CN/desk.png" />
        <span class="desk-text" @click="goDeskWeb()">{{
          language == "zh_CN"
            ? "点击桌面版网站查看更多功能"
            : language == "zh_HK"
            ? "點擊桌面版網站查看更多功能"
            : "Click to Explore More Features on Desktop Version"
        }}</span>
      </div>
    </div>
    <div class="bg9">
      <span class="bg9-title">{{
        language == "zh_CN"
          ? "社群"
          : language == "zh_HK"
          ? "社群"
          : "8V Community"
      }}</span>
      <div class="bg9-items">
        <div
          class="item"
          @click="
            () => {
              language == 'zh_CN' || language == 'zh_HK'
                ? download4url('https://t.me/official8V_group')
                : download4url('https://t.me/global8vchat');
            }
          "
        >
          <img class="item-img" src="~@/assets/h5/zh_CN/te.png" />
          <span class="item-text">Telegram</span>
        </div>
        <div
          class="item"
          @click="
            () => {
              language == 'zh_CN' || language == 'zh_HK'
                ? download4url('https://twitter.com/8vGlobal')
                : download4url('https://twitter.com/8vGlobal');
            }
          "
        >
          <img class="item-img" src="~@/assets/h5/zh_CN/tw.png" />
          <span class="item-text">Twitter</span>
        </div>
        <div
          class="item"
          @click="
            () => {
              language == 'zh_CN' || language == 'zh_HK'
                ? download4url('https://www.reddit.com/r/8Voffical_group/')
                : download4url('https://www.reddit.com/r/8Voffical_group/');
            }
          "
        >
          <img class="item-img" src="~@/assets/h5/zh_CN/re.png" />
          <span class="item-text">Reddit</span>
        </div>
        <div
          class="item"
          @click="
            () => {
              language == 'zh_CN' || language == 'zh_HK'
                ? download4url('https://www.instagram.com/8v.asia/')
                : download4url('https://www.instagram.com/8v.global/');
            }
          "
        >
          <img class="item-img" src="~@/assets/h5/zh_CN/in.png" />
          <span class="item-text">Instagram</span>
        </div>
        <div
          class="item"
          @click="
            () => {
              language == 'zh_CN' || language == 'zh_HK'
                ? download4url('https://www.facebook.com/8V.Asia')
                : download4url('https://www.facebook.com/8V.Global/');
            }
          "
        >
          <img class="item-img" src="~@/assets/h5/zh_CN/fa.png" />
          <span class="item-text">Facebook</span>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div
        class="d-item b-i"
        v-if="'https://get.8v.com/'"
        @click="download4url('https://get.8v.com/', 'defult_super_sign_url')"
      ></div>
      <div
        class="d-item b-gp"
        v-if="download_app_info.defult_google_play"
        @click="
          download4url(
            download_app_info.defult_google_play,
            'defult_google_play'
          )
        "
      ></div>
      <div
        class="d-item b-a"
        v-if="download_app_info.defult_apk_url"
        @click="
          download4url(download_app_info.defult_apk_url, 'defult_apk_url')
        "
      ></div>
      <div class="chat"></div>
    </div>
  </div>
</template>
<script>
import {
  VUE_CHANNEL_ID,
  VUE_UNION_STATION,
  VUE_APP_UCENTER,
} from "@/assets/js/stationSetting";
import globalFun from "@/assets/js/globalFun.js";
import { mapActions, mapGetters } from "vuex";
import countTo from "vue-count-to";
import QrcodeVue from "qrcode.vue";
import en_flag from "@/assets/h5/common/en.png";
import zh_CN_flag from "@/assets/h5/common/zh_CN.png";
import zh_HK_flag from "@/assets/h5/common/zh_HK.png";
import arrow from "@/assets/h5/common/arrow.png";
export default {
  mixins: [],
  components: { countTo, QrcodeVue },

  data() {
    return {
      VUE_CHANNEL_ID,
      VUE_APP_UCENTER,
      download_app_info: {},
      en_flag,
      zh_CN_flag,
      zh_HK_flag,
      arrow,
      isShowLangChoose: false,
    };
  },
  async created() {
    this.download_app_info = JSON.parse(
      sessionStorage.getItem("download_app_info")
    );
    this.updateAccess();
    // console.log("download_app_info", this.download_app_info);
  },
  destroyed() {},
  beforeCreate() {
    document
      .querySelector("html")
      .setAttribute(
        "style",
        "width: 100vw !important;min-width:100vw !important"
      );
    document
      .querySelector(".vab-theme-8V")
      .setAttribute(
        "style",
        "width: 100vw !important;min-width:100vw !important"
      );
  },

  beforeDestroy() {
    document.querySelector("html").removeAttribute("style");
    document.querySelector(".vab-theme-8V").removeAttribute("style");
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency",
    }),
  },

  methods: {
    ...mapActions({
      changeLanguage: "user/changeLanguage",
    }),
    changeLang(lang) {
      this.changeLanguage(lang);
      this.isShowLangChoose = false;
    },
    download4url(url, click_type = "") {
      let targetUrl = url.replace("^^", "&");
      if (click_type) {
        this.appendDownload(targetUrl, click_type);
      }
      window.open(targetUrl);
    },
    isIos() {
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isiOS) {
        return true;
      } else {
        return false;
      }
    },
    goDeskWeb() {
      sessionStorage.setItem("isWeb", "1");
      window.location.reload();
    },
    updateAccess() {
      $.post(
        VUE_APP_UCENTER + "/Point/access",
        {
          channel_id: this.VUE_CHANNEL_ID,
          location_url: window.location.href,
        },
        function (result) {}
      );
    },

    appendDownload(downLoadUrl, click_type) {
      // if (click_type != "common_openinstall") {
      //   window.open(downLoadUrl);
      // }
      $.post(
        VUE_APP_UCENTER + "/Point/web",
        {
          channel_id: this.VUE_CHANNEL_ID,
          click_type: click_type,
          location_url: window.location.href,
          click_url: downLoadUrl,
        },
        function (result) {}
      );
    },
  },
};
</script>
<style lang="scss">
* {
  box-sizing: border-box;
}
body {
  margin: 0;
}
.bg1 {
  position: relative;
  .curr-lang-area {
    top: 4vw;
    right: 4vw;
    position: absolute;
    width: 34.67vw;
    height: 10.67vw;
    padding: 0 3.6vw;
    border-radius: 6px;
    background-color: #fff;
    display: flex;
    align-items: center;
    .curr-flag {
      width: 6.4vw;
      height: 6.4vw;
      margin-right: 2.13vw;
    }
    .curr-lang-name {
      font-size: 14px;
      color: #182230;
    }
    .lang-state-expand {
      margin-left: auto;
      width: 5.33vw;
      height: 5.33vw;
    }
    .lang-state-normal {
      margin-left: auto;
      width: 5.33vw;
      height: 5.33vw;
      transform: rotate(180deg);
    }
  }
  .all-lang-area {
    top: 16vw;
    right: 4vw;
    position: absolute;
    width: 34.67vw;
    height: 40vw;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    .lang-item {
      display: flex;
      align-items: center;
      width: 34.67vw;
      height: 13.33vw;
      padding: 0 5.33vw;
      .curr-flag {
        width: 6.4vw;
        height: 6.4vw;
        margin-right: 2.13vw;
      }
      .curr-lang-name {
        font-size: 14px;
        color: #182230;
      }
    }
    .item-first {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    .item-last {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    .lang-item-curr {
      background-color: #eeeef1;
    }
  }
}
.page-content-cn {
  width: 100%;
}
.page-content-cn .bg1 {
  width: 100vw;
  height: 159.5vw;
  background-image: url("~@/assets/h5/zh_CN/bg1.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.page-content-cn .bg1 .bg1-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84vw;
  height: 12vw;
  font-size: 18px;
  font-weight: 600;
  color: #112132;
  border-radius: 29px;
  background-color: #f7b239;
  margin-bottom: 6.4vw;
}
.page-content-cn .bg2 {
  width: 100vw;
  height: 114vw;
  background-image: url("~@/assets/h5/zh_CN/bg2.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.page-content-cn .bg3 {
  width: 100vw;
  height: 194vw;
  background-image: url("~@/assets/h5/zh_CN/bg3.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.page-content-cn .bg4 {
  padding: 6.27vw 0 5.33vw;
  display: flex;
  justify-content: center;
  background-color: #112132;
}
.page-content-cn .bg4 .bg4-content {
  width: 90vw;
  height: 234.67vw;
  background-image: url("~@/assets/h5/zh_CN/bg4.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.page-content-cn .bg5 {
  width: 100vw;
  height: 130vw;
  background-image: url("~@/assets/h5/zh_CN/bg5.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.page-content-cn .bg5 .bg5-title1 {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
}
.page-content-cn .bg5 .bg5-title2 {
  margin-bottom: 2.67vw;
  font-size: 30px;
  font-weight: 600;
  color: #fff;
}
.page-content-cn .bg5 .bg5-title3 {
  margin-bottom: 14.13vw;
  font-size: 15px;
  font-weight: 500;
  color: #d8d8d8;
}
.page-content-cn .bg5 .data {
  width: 100%;
  display: flex;
}
.page-content-cn .bg5 .data1 {
  margin-bottom: 11.33vw;
}
.page-content-cn .bg5 .data .data-half {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-content-cn .bg5 .data .data-half .dh-title {
  /* margin-bottom: 2.67vw; */
  font-size: 15px;
  font-weight: 500;
  color: #d8d8d8;
}
.page-content-cn .bg5 .data .data-half .dh-data {
  font-size: 40px;
  font-weight: bold;
  color: #fff;
}
.page-content-cn .bg5 .data .data-half .dh-unit {
  font-size: 15px;
  font-weight: 500;
  color: #d8d8d8;
}
.page-content-cn .bg6 {
  width: 100vw;
  height: 198.4vw;
  background-color: #13182e;
  background-image: url("~@/assets/h5/zh_CN/bg6.png");
  background-size: 100vw 177.6vw;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.page-content-cn .bg6 .bg6-title1 {
  font-size: 27px;
  font-weight: 600;
  color: #f7b239;
}
.page-content-cn .bg6 .bg6-title2 {
  font-size: 15px;
  font-weight: 600;
  color: #f7b239;
  margin-bottom: 5.87vw;
}
.page-content-cn .bg6 .bg6-content {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  margin: 0 5.33vw 24.67vw;
}
.page-content-cn .bg6 .bg6-title-team {
  font-size: 27px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 5.07vw;
}
.page-content-cn .bg6 .bg6-team1 {
  margin-bottom: 6.67vw;
  width: 100%;
  padding: 0 8vw;
  display: flex;
  justify-content: space-between;
}
.page-content-cn .bg6 .bg6-team1 .item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-content-cn .bg6 .bg6-team1 .item .item-avater {
  width: 22.8vw;
  height: 28vw;
  margin-bottom: 1.73vw;
}
.page-content-cn .bg6 .bg6-team1 .item .item-name {
  font-size: 13px;
  color: #fff;
}
.page-content-cn .bg6 .bg6-team1 .item .item-job {
  font-size: 9px;
  color: #879fb9;
}
.page-content-cn .bg6 .bg6-team2 {
  width: 100%;
  padding: 0 24.4vw;
  display: flex;
  justify-content: space-between;
}
.page-content-cn .bg6 .bg6-team2 .item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-content-cn .bg6 .bg6-team2 .item .item-avater {
  width: 22.8vw;
  height: 28vw;
  margin-bottom: 1.73vw;
}
.page-content-cn .bg6 .bg6-team2 .item .item-name {
  font-size: 13px;
  color: #fff;
}
.page-content-cn .bg6 .bg6-team2 .item .item-job {
  font-size: 9px;
  color: #879fb9;
}
.page-content-cn .bg7 {
  width: 100vw;
  height: 121.33vw;
  background-image: url("~@/assets/h5/zh_CN/bg7.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.page-content-cn .bg7 .bg7-title1 {
  font-size: 27px;
  font-weight: 600;
  color: #112132;
  margin-bottom: 0.8vw;
}
.page-content-cn .bg7 .bg7-title2 {
  font-size: 15px;
  font-weight: 600;
  color: #112132;
  margin-bottom: 6.8vw;
}
.page-content-cn .bg7 .bg7-items {
  width: 100%;
  padding: 0 5.33vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.page-content-cn .bg7 .bg7-items .item {
  width: 28.27vw;
  height: 16.96vw;
  margin-bottom: 3.96vw;
}
.page-content-cn .bg8 {
  width: 100vw;
  height: 133.33vw;
  background-image: url("~@/assets/h5/zh_CN/bg8.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.page-content-cn .bg8 .bg8-title1 {
  font-size: 27px;
  font-weight: 600;
  color: #fff;
}
.page-content-cn .bg8 .bg8-title2 {
  font-size: 27px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 3.2vw;
}
.page-content-cn .bg8 .bg8-title3 {
  font-size: 15px;
  font-weight: 600;
  color: #d8d8d8;
  margin-bottom: 4.93vw;
}
.page-content-cn .bg8 .qr-code {
  width: 40vw;
  height: 40vw;
  background-color: #fff;
  margin-bottom: 8.4vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-content-cn .bg8 .qr_code_canvas {
  display: flex;
}
.page-content-cn .bg8 .down {
  padding: 0 7.6vw;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4.53vw;
}

.page-content-cn .bg8 .down .d-button {
  width: 40.8vw;
  height: 14.53vw;
  border-radius: 15px;
  border: solid 4px #f7b239;
  background-color: rgba(24, 44, 60, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-content-cn .bg8 .down .d-button .db-img.ap {
  width: 6.93vw;
  height: 8.4vw;
  margin-right: 1.5vw;
}
.page-content-cn .bg8 .down .d-button .db-img.an {
  width: 8.26vw;
  height: 9.33vw;
  margin-right: 1.5vw;
}
.page-content-cn .bg8 .down .d-button .db-text {
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}
.page-content-cn .bg8 .down .d-button .db-text2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page-content-cn .bg8 .down .d-button .db-text2 span {
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}
.page-content-cn .bg8 .desk {
  margin: 0 7.6vw;
  width: 84.93vw;
  height: 17vw;
  border-radius: 13px;
  background-color: #f7b239;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-content-cn .bg8 .desk .desk-img {
  margin-right: 3.6vw;
  width: 12.93vw;
  height: 12.93vw;
}
.page-content-cn .bg8 .desk .desk-text {
  font-size: 15px;
  font-weight: 600;
  color: #112132;
}
.page-content-cn .bg9 {
  width: 100%;
  height: 51.87vw;
  background-color: #112132;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 15.2vw;
}
.page-content-cn .bg9 .bg9-title {
  font-size: 27px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 8vw;
}
.page-content-cn .bg9 .bg9-items {
  width: 100%;
  padding: 0 7.33vw 0 9.33vw;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}
.page-content-cn .bg9 .bg9-items .item {
  flex-shrink: 0;
  width: 33.33%;
  margin-bottom: 4.93vw;
  display: flex;
  align-items: center;
}
.page-content-cn .bg9 .bg9-items .item .item-img {
  width: 6.4vw;
  height: 6.4vw;
  margin-right: 1.6vw;
}
.page-content-cn .bg9 .bg9-items .item .item-text {
  font-size: 13px;
  color: #fff;
}
.page-content-cn .bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 15.2vw;
  background-color: #fdb23c;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.page-content-cn .bottom .d-item {
  width: 26.13vw;
  height: 8.53vw;
}
.page-content-cn .bottom .b-i {
  background-image: url("~@/assets/h5/zh_CN/b-i.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.page-content-cn .bottom .b-gp {
  background-image: url("~@/assets/h5/zh_CN/b-gp.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.page-content-cn .bottom .b-a {
  background-image: url("~@/assets/h5/zh_CN/b-a.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.page-content-cn .bottom .chat {
  width: 8.53vw;
  height: 8.53vw;
  // background-image: url("~@/assets/h5/zh_CN/chat.png");
  // background-size: cover;
  // background-repeat: no-repeat;
}
.page-content-hk {
  width: 100%;
}
.page-content-hk .bg1 {
  width: 100vw;
  height: 159.5vw;
  background-image: url("~@/assets/h5/zh_HK/bg1.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.page-content-hk .bg1 .bg1-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84vw;
  height: 12vw;
  font-size: 18px;
  font-weight: 600;
  color: #112132;
  border-radius: 29px;
  background-color: #f7b239;
  margin-bottom: 6.4vw;
}
.page-content-hk .bg2 {
  width: 100vw;
  height: 114vw;
  background-image: url("~@/assets/h5/zh_HK/bg2.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.page-content-hk .bg3 {
  width: 100vw;
  height: 194vw;
  background-image: url("~@/assets/h5/zh_HK/bg3.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.page-content-hk .bg4 {
  padding: 6.27vw 0 5.33vw;
  display: flex;
  justify-content: center;
  background-color: #112132;
}
.page-content-hk .bg4 .bg4-content {
  width: 90vw;
  height: 234.67vw;
  background-image: url("~@/assets/h5/zh_HK/bg4.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.page-content-hk .bg5 {
  width: 100vw;
  height: 130vw;
  background-image: url("~@/assets/h5/zh_HK/bg5.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.page-content-hk .bg5 .bg5-title1 {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
}
.page-content-hk .bg5 .bg5-title2 {
  margin-bottom: 2.67vw;
  font-size: 30px;
  font-weight: 600;
  color: #fff;
}
.page-content-hk .bg5 .bg5-title3 {
  margin-bottom: 14.13vw;
  font-size: 15px;
  font-weight: 500;
  color: #d8d8d8;
}
.page-content-hk .bg5 .data {
  width: 100%;
  display: flex;
}
.page-content-hk .bg5 .data1 {
  margin-bottom: 11.33vw;
}
.page-content-hk .bg5 .data .data-half {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-content-hk .bg5 .data .data-half .dh-title {
  /* margin-bottom: 2.67vw; */
  font-size: 15px;
  font-weight: 500;
  color: #d8d8d8;
}
.page-content-hk .bg5 .data .data-half .dh-data {
  font-size: 40px;
  font-weight: bold;
  color: #fff;
}
.page-content-hk .bg5 .data .data-half .dh-unit {
  font-size: 15px;
  font-weight: 500;
  color: #d8d8d8;
}
.page-content-hk .bg6 {
  width: 100vw;
  height: 198.4vw;
  background-color: #13182e;
  background-image: url("~@/assets/h5/zh_HK/bg6.png");
  background-size: 100vw 177.6vw;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.page-content-hk .bg6 .bg6-title1 {
  font-size: 27px;
  font-weight: 600;
  color: #f7b239;
}
.page-content-hk .bg6 .bg6-title2 {
  font-size: 15px;
  font-weight: 600;
  color: #f7b239;
  margin-bottom: 5.87vw;
}
.page-content-hk .bg6 .bg6-content {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  margin: 0 5.33vw 24.67vw;
}
.page-content-hk .bg6 .bg6-title-team {
  font-size: 27px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 5.07vw;
}
.page-content-hk .bg6 .bg6-team1 {
  margin-bottom: 6.67vw;
  width: 100%;
  padding: 0 8vw;
  display: flex;
  justify-content: space-between;
}
.page-content-hk .bg6 .bg6-team1 .item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-content-hk .bg6 .bg6-team1 .item .item-avater {
  width: 22.8vw;
  height: 28vw;
  margin-bottom: 1.73vw;
}
.page-content-hk .bg6 .bg6-team1 .item .item-name {
  font-size: 13px;
  color: #fff;
}
.page-content-hk .bg6 .bg6-team1 .item .item-job {
  font-size: 9px;
  color: #879fb9;
}
.page-content-hk .bg6 .bg6-team2 {
  width: 100%;
  padding: 0 24.4vw;
  display: flex;
  justify-content: space-between;
}
.page-content-hk .bg6 .bg6-team2 .item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-content-hk .bg6 .bg6-team2 .item .item-avater {
  width: 22.8vw;
  height: 28vw;
  margin-bottom: 1.73vw;
}
.page-content-hk .bg6 .bg6-team2 .item .item-name {
  font-size: 13px;
  color: #fff;
}
.page-content-hk .bg6 .bg6-team2 .item .item-job {
  font-size: 9px;
  color: #879fb9;
}
.page-content-hk .bg7 {
  width: 100vw;
  height: 121.33vw;
  background-image: url("~@/assets/h5/zh_HK/bg7.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.page-content-hk .bg7 .bg7-title1 {
  font-size: 27px;
  font-weight: 600;
  color: #112132;
  margin-bottom: 0.8vw;
}
.page-content-hk .bg7 .bg7-title2 {
  font-size: 15px;
  font-weight: 600;
  color: #112132;
  margin-bottom: 6.8vw;
}
.page-content-hk .bg7 .bg7-items {
  width: 100%;
  padding: 0 5.33vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.page-content-hk .bg7 .bg7-items .item {
  width: 28.27vw;
  height: 16.96vw;
  margin-bottom: 3.96vw;
}
.page-content-hk .bg8 {
  width: 100vw;
  height: 133.33vw;
  background-image: url("~@/assets/h5/zh_HK/bg8.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.page-content-hk .bg8 .bg8-title1 {
  font-size: 27px;
  font-weight: 600;
  color: #fff;
}
.page-content-hk .bg8 .bg8-title2 {
  font-size: 27px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 3.2vw;
}
.page-content-hk .bg8 .bg8-title3 {
  font-size: 15px;
  font-weight: 600;
  color: #d8d8d8;
  margin-bottom: 4.93vw;
}
.page-content-hk .bg8 .qr-code {
  width: 40vw;
  height: 40vw;
  background-color: #fff;
  margin-bottom: 8.4vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-content-hk .bg8 .qr_code_canvas {
  display: flex;
}
.page-content-hk .bg8 .down {
  padding: 0 7.6vw;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4.53vw;
}

.page-content-hk .bg8 .down .d-button {
  width: 40.8vw;
  height: 14.53vw;
  border-radius: 15px;
  border: solid 4px #f7b239;
  background-color: rgba(24, 44, 60, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-content-hk .bg8 .down .d-button .db-img.ap {
  width: 6.93vw;
  height: 8.4vw;
  margin-right: 1.5vw;
}
.page-content-hk .bg8 .down .d-button .db-img.an {
  width: 8.26vw;
  height: 9.33vw;
  margin-right: 1.5vw;
}
.page-content-hk .bg8 .down .d-button .db-text {
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}
.page-content-hk .bg8 .down .d-button .db-text2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page-content-hk .bg8 .down .d-button .db-text2 span {
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}
.page-content-hk .bg8 .desk {
  margin: 0 7.6vw;
  width: 84.93vw;
  height: 17vw;
  border-radius: 13px;
  background-color: #f7b239;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-content-hk .bg8 .desk .desk-img {
  margin-right: 3.6vw;
  width: 12.93vw;
  height: 12.93vw;
}
.page-content-hk .bg8 .desk .desk-text {
  font-size: 15px;
  font-weight: 600;
  color: #112132;
}
.page-content-hk .bg9 {
  width: 100%;
  height: 51.87vw;
  background-color: #112132;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 15.2vw;
}
.page-content-hk .bg9 .bg9-title {
  font-size: 27px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 8vw;
}
.page-content-hk .bg9 .bg9-items {
  width: 100%;
  padding: 0 7.33vw 0 9.33vw;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}
.page-content-hk .bg9 .bg9-items .item {
  flex-shrink: 0;
  width: 33.33%;
  margin-bottom: 4.93vw;
  display: flex;
  align-items: center;
}
.page-content-hk .bg9 .bg9-items .item .item-img {
  width: 6.4vw;
  height: 6.4vw;
  margin-right: 1.6vw;
}
.page-content-hk .bg9 .bg9-items .item .item-text {
  font-size: 13px;
  color: #fff;
}
.page-content-hk .bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 15.2vw;
  background-color: #fdb23c;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.page-content-hk .bottom .d-item {
  width: 26.13vw;
  height: 8.53vw;
}
.page-content-hk .bottom .b-i {
  background-image: url("~@/assets/h5/zh_HK/b-i.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.page-content-hk .bottom .b-gp {
  background-image: url("~@/assets/h5/zh_HK/b-gp.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.page-content-hk .bottom .b-a {
  background-image: url("~@/assets/h5/zh_HK/b-a.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.page-content-hk .bottom .chat {
  width: 8.53vw;
  height: 8.53vw;
  // background-image: url("~@/assets/h5/zh_HK/chat.png");
  // background-size: cover;
  // background-repeat: no-repeat;
}

.page-content-en {
  width: 100%;
}
.page-content-en .bg1 {
  width: 100vw;
  height: 159.5vw;
  background-image: url("~@/assets/h5/en/bg1.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.page-content-en .bg1 .bg1-button {
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 84vw;
  padding: 0 18.25px;
  height: 12vw;
  font-size: 18px;
  font-weight: 600;
  color: #112132;
  border-radius: 29px;
  background-color: #f7b239;
  margin-bottom: 6.4vw;
}
.page-content-en .bg2 {
  width: 100vw;
  height: 114vw;
  background-image: url("~@/assets/h5/en/bg2.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.page-content-en .bg3 {
  width: 100vw;
  height: 194vw;
  background-image: url("~@/assets/h5/en/bg3.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.page-content-en .bg4 {
  padding: 6.27vw 0 5.33vw;
  display: flex;
  justify-content: center;
  background-color: #112132;
}
.page-content-en .bg4 .bg4-content {
  width: 90vw;
  height: 234.67vw;
  background-image: url("~@/assets/h5/en/bg4.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.page-content-en .bg5 {
  width: 100vw;
  height: 130vw;
  background-image: url("~@/assets/h5/en/bg5.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.page-content-en .bg5 .bg5-title1 {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
}
.page-content-en .bg5 .bg5-title2 {
  margin-bottom: 2.67vw;
  font-size: 30px;
  font-weight: 600;
  color: #fff;
}
.page-content-en .bg5 .bg5-title3 {
  margin-bottom: 14.13vw;
  font-size: 15px;
  font-weight: 500;
  color: #d8d8d8;
}
.page-content-en .bg5 .data {
  width: 100%;
  display: flex;
}
.page-content-en .bg5 .data1 {
  margin-bottom: 11.33vw;
}
.page-content-en .bg5 .data .data-half {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-content-en .bg5 .data .data-half .dh-title {
  /* margin-bottom: 2.67vw; */
  font-size: 15px;
  font-weight: 500;
  color: #d8d8d8;
}
.page-content-en .bg5 .data .data-half .dh-data {
  font-size: 40px;
  font-weight: bold;
  color: #fff;
}
.page-content-en .bg5 .data .data-half .dh-unit {
  font-size: 15px;
  font-weight: 500;
  color: #d8d8d8;
}
.page-content-en .bg6 {
  width: 100vw;
  height: 198.4vw;
  background-color: #13182e;
  background-image: url("~@/assets/h5/en/bg6.png");
  background-size: 100vw 177.6vw;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.page-content-en .bg6 .bg6-title1 {
  font-size: 27px;
  font-weight: 600;
  color: #f7b239;
}
.page-content-en .bg6 .bg6-title2 {
  font-size: 15px;
  font-weight: 600;
  color: #f7b239;
  margin-bottom: 5.87vw;
}
.page-content-en .bg6 .bg6-content {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  margin: 0 5.33vw 10vw;
}
.page-content-en .bg6 .bg6-title-team {
  font-size: 27px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 5.07vw;
}
.page-content-en .bg6 .bg6-team1 {
  margin-bottom: 6.67vw;
  width: 100%;
  padding: 0 8vw;
  display: flex;
  justify-content: space-between;
}
.page-content-en .bg6 .bg6-team1 .item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-content-en .bg6 .bg6-team1 .item .item-avater {
  width: 22.8vw;
  height: 28vw;
  margin-bottom: 1.73vw;
}
.page-content-en .bg6 .bg6-team1 .item .item-name {
  font-size: 13px;
  color: #fff;
}
.page-content-en .bg6 .bg6-team1 .item .item-job {
  font-size: 9px;
  color: #879fb9;
}
.page-content-en .bg6 .bg6-team2 {
  width: 100%;
  padding: 0 24.4vw;
  display: flex;
  justify-content: space-between;
}
.page-content-en .bg6 .bg6-team2 .item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-content-en .bg6 .bg6-team2 .item .item-avater {
  width: 22.8vw;
  height: 28vw;
  margin-bottom: 1.73vw;
}
.page-content-en .bg6 .bg6-team2 .item .item-name {
  font-size: 13px;
  color: #fff;
}
.page-content-en .bg6 .bg6-team2 .item .item-job {
  font-size: 9px;
  color: #879fb9;
}
.page-content-en .bg7 {
  width: 100vw;
  height: 121.33vw;
  background-image: url("~@/assets/h5/en/bg7.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.page-content-en .bg7 .bg7-title1 {
  font-size: 27px;
  font-weight: 600;
  color: #112132;
  margin-bottom: 0.8vw;
}
.page-content-en .bg7 .bg7-title2 {
  font-size: 15px;
  font-weight: 600;
  color: #112132;
  margin-bottom: 6.8vw;
}
.page-content-en .bg7 .bg7-items {
  width: 100%;
  padding: 0 5.33vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.page-content-en .bg7 .bg7-items .item {
  width: 28.27vw;
  height: 16.96vw;
  margin-bottom: 3.96vw;
}
.page-content-en .bg8 {
  width: 100vw;
  height: 133.33vw;
  background-image: url("~@/assets/h5/en/bg8.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.page-content-en .bg8 .bg8-title1 {
  font-size: 27px;
  font-weight: 600;
  color: #fff;
}
.page-content-en .bg8 .bg8-title2 {
  font-size: 27px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 3.2vw;
}
.page-content-en .bg8 .bg8-title3 {
  font-size: 15px;
  font-weight: 600;
  color: #d8d8d8;
  margin-bottom: 4.93vw;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 74vw;
}
.page-content-en .bg8 .qr-code {
  width: 40vw;
  height: 40vw;
  background-color: #fff;
  margin-bottom: 8.4vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-content-en .bg8 .qr_code_canvas {
  display: flex;
}
.page-content-en .bg8 .down {
  padding: 0 7.6vw;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4.53vw;
}

.page-content-en .bg8 .down .d-button {
  width: 40.8vw;
  height: 14.53vw;
  border-radius: 15px;
  border: solid 4px #f7b239;
  background-color: rgba(24, 44, 60, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2vw;
}
.page-content-en .bg8 .down .d-button .db-img.ap {
  width: 6.93vw;
  height: 8.4vw;
  margin-right: 1.5vw;
}
.page-content-en .bg8 .down .d-button .db-img.an {
  width: 8.26vw;
  height: 9.33vw;
  margin-right: 1.5vw;
}
.page-content-en .bg8 .down .d-button .db-text {
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}
.page-content-en .bg8 .down .d-button .db-text2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page-content-en .bg8 .down .d-button .db-text2 span {
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}
.page-content-en .bg8 .desk {
  margin: 0 7.6vw;
  width: 84.93vw;
  height: 17vw;
  border-radius: 13px;
  background-color: #f7b239;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5.33vw;
}
.page-content-en .bg8 .desk .desk-img {
  margin-right: 3.6vw;
  width: 12.93vw;
  height: 12.93vw;
}
.page-content-en .bg8 .desk .desk-text {
  font-size: 15px;
  font-weight: 600;
  color: #112132;
}
.page-content-en .bg9 {
  width: 100%;
  height: 51.87vw;
  background-color: #112132;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 15.2vw;
}
.page-content-en .bg9 .bg9-title {
  font-size: 27px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 8vw;
}
.page-content-en .bg9 .bg9-items {
  width: 100%;
  padding: 0 7.33vw 0 9.33vw;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}
.page-content-en .bg9 .bg9-items .item {
  flex-shrink: 0;
  width: 33.33%;
  margin-bottom: 4.93vw;
  display: flex;
  align-items: center;
}
.page-content-en .bg9 .bg9-items .item .item-img {
  width: 6.4vw;
  height: 6.4vw;
  margin-right: 1.6vw;
}
.page-content-en .bg9 .bg9-items .item .item-text {
  font-size: 13px;
  color: #fff;
}
.page-content-en .bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 15.2vw;
  background-color: #fdb23c;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.page-content-en .bottom .d-item {
  width: 26.13vw;
  height: 8.53vw;
}
.page-content-en .bottom .b-i {
  background-image: url("~@/assets/h5/en/b-i.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.page-content-en .bottom .b-gp {
  background-image: url("~@/assets/h5/en/b-gp.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.page-content-en .bottom .b-a {
  background-image: url("~@/assets/h5/en/b-a.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.page-content-en .bottom .chat {
  width: 8.53vw;
  height: 8.53vw;
  // background-image: url("~@/assets/h5/en/chat.png");
  // background-size: cover;
  // background-repeat: no-repeat;
}
</style>
